import React, { useState } from "react";
import { Dropdown, Menu, Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";

import {
  imagesend,
  pdficon,
  pdficon3,
  plusicon,
  refreshicon,
  pdficon5,
} from "../imagepath";
import dayjs from "dayjs";

import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import {
  generateCsv,
  handleDownloadPdfAdminUser,
  handleShowJson,
} from "../../utils/constant/constant";
import { toast } from "react-toastify";
import { getAdminUsersList } from "../../connections/getAdminUsersList";
import { deleteAdminUser } from "../../connections/deleteUserAdmin";

const AdminUsersList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteId, setDeleteId] = useState(null);

  const navigate = useNavigate();
  const {
    user: { user },
  } = useSelector((store) => store);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const {
    data: getAllAdminUser,
    isLoading: getAllAdminUserLoading,
    refetch: getAllAdminUserRefetch,
  } = useQuery({
    queryKey: ["getAdminUsersList", page, pageSize],
    queryFn: () => getAdminUsersList(user?.token, null, page, pageSize),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Current Sign In at",
      dataIndex: "current_sign_in_at",
    },
    {
      title: "Sign In Count",
      dataIndex: "sign_in_count",
    },

    //
    {
      title: "Created At",
      dataIndex: "created_at",
      //   sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,
      render: (date) => (
        <>
          {" "}
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      //   sorter: (a, b) => a.Treatment.length - b.Treatment.length,
      render: (date) => (
        <>
          {" "}
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "FIELD8",
    //   render: (_, record) => (
    //     <>
    //       <div className="text-end">
    //         <div className="dropdown dropdown-action">
    //           <Link
    //             to="#"
    //             className="action-icon dropdown-toggle"
    //             data-bs-toggle="dropdown"
    //             aria-expanded="false"
    //           >
    //             <i className="fas fa-ellipsis-v" />
    //           </Link>
    //           <div className="dropdown-menu dropdown-menu-end">
    //             <Link
    //               className="dropdown-item"
    //               to={`/admin/admin-user/${record?.id}`}
    //             >
    //               <i className="fa-regular fa-eye m-r-5" />
    //               View
    //             </Link>
    //             <button
    //               className="dropdown-item"
    //               onClick={() =>
    //                 navigate("/admin/update_admin_user", { state: record })
    //               }
    //             >
    //               <i className="far fa-edit me-2" />
    //               Edit
    //             </button>
    //             <Link
    //               className="dropdown-item"
    //               onClick={() => setDeleteId(record.id)}
    //               data-bs-toggle="modal"
    //               data-bs-target="#delete_patient"
    //             >
    //               <i className="fa fa-trash-alt m-r-5"></i> Delete
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="view">
                <Link to={`/admin/admin-user/${record?.id}`}>
                  <i className="fa-regular fa-eye m-r-5" /> View
                </Link>
              </Menu.Item>
              <Menu.Item
                key="edit"
                onClick={() =>
                  navigate("/admin/update_admin_user", { state: record })
                }
              >
                <i className="far fa-edit me-2" /> Edit
              </Menu.Item>
              <Menu.Item
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete_patient"
                key="delete"
                onClick={() => setDeleteId(record.id)}
              >
                <i className="fa fa-trash-alt m-r-5" /> Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <Link
            to="#"
            style={{ marginRight: 5 }}
            className="action-icon"
            // data-bs-toggle="dropdown"
            // aria-expanded="false"
          >
            <i className="fas fa-ellipsis-v" />
          </Link>
        </Dropdown>
      ),
    },
  ];
  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
    // refetchSuppliersList();
  };
  const handleDeleteUserAdmin = async (e) => {
    e.preventDefault();

    try {
      const data = await deleteAdminUser(user?.token, deleteId);
      if (data.status.status === "SUCCESS") {
        toast.success("Admin User Deleted Successfully");
        getAllAdminUserRefetch();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="admin-user-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Admin Users </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">Admin Users List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Admin Users List</h3>
                          <div className="doctor-search-blk">
                            <div className="add-group">
                              <Link
                                to="/admin/add-admin-user"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                onClick={getAllAdminUserRefetch}
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        {/* <Link id="download-pdf" to="#" className=" me-2"> */}
                        <img
                          className=" me-2"
                          onClick={() =>
                            handleDownloadPdfAdminUser(
                              getAllAdminUser?.payload?.admin_users
                            )
                          }
                          src={pdficon}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                        {/* <Link
                           
                            id="download-csv"
                            className=" me-2"
                          > */}
                        <img
                          onClick={() =>
                            generateCsv(getAllAdminUser?.payload?.admin_users)
                          }
                          className=" me-2"
                          src={pdficon3}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                        {/* <Link id="show-json" to="#"> */}
                        <img
                          onClick={() =>
                            handleShowJson(
                              getAllAdminUser?.payload?.admin_users
                            )
                          }
                          width={"25px"}
                          height={"26px"}
                          src={pdficon5}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      loading={getAllAdminUserLoading}
                      pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: getAllAdminUser?.meta?.total_count,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        onChange: handlePageChange,
                      }}
                      columns={columns}
                      dataSource={getAllAdminUser?.payload?.admin_users}
                      rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                      scroll={{ x: "max-content" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="notification-box">
          <div className="msg-sidebar notifications msg-noti">
            <div className="topnav-dropdown-header">
              <span>Messages</span>
            </div>
            <div className="drop-scroll msg-list-scroll" id="msg_list">
              <ul className="list-box">
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">R</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Richard Miles </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item new-message">
                      <div className="list-left">
                        <span className="avatar">J</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">John Doe</span>
                        <span className="message-time">1 Aug</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">T</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Tarah Shropshire{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">M</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Mike Litorus</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">C</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Catherine Manseau{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">D</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Domenic Houston{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">B</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Buster Wigton </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">R</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Rolland Webber </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">C</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Claire Mapes </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">M</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Melita Faucher</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">J</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Jeffery Lalor</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">L</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Loren Gatlin</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">T</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Tarah Shropshire</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="topnav-dropdown-footer">
              <Link to="#">See all messages</Link>
            </div>
          </div>
        </div>
      </div>
      <div
        id="delete_patient"
        className="modal fade delete-modal"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src={imagesend} alt="#" width={50} height={46} />
              <h3>Are you sure want to delete this ?</h3>
              <div className="m-t-20">
                {" "}
                <Link
                  to="#"
                  className="btn btn-white me-2"
                  data-bs-dismiss="modal"
                >
                  Close
                </Link>
                <button
                  onClick={handleDeleteUserAdmin}
                  type="submit"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUsersList;
