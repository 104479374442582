import React, { useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";

import {
  imagesend,
  pdficon,
  pdficon3,
  plusicon,
  refreshicon,
  pdficon5,
  searchnormal,
} from "../imagepath";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getPatientsList } from "../../connections/getPatientsList";
import {
  generateCsv,
  handleDownloadPdfPatient,
  handleShowJson,
} from "../../utils/constant/constant";
import { deletePatient } from "../../connections/deletePatient";
import { toast } from "react-toastify";
import useDebounce from "../../utils/hooks";

const PatientsList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteId, setDeleteId] = useState(null);

  const navigate = useNavigate();
  const {
    user: { user },
  } = useSelector((store) => store);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [patientInput, setPatientInput] = useState("");
  const debouncedInputMedicine = useDebounce(patientInput, 1000);

  const {
    data: getAllPatientsListData,
    isLoading: getAllPatientsListLoading,
    refetch: getAllPatientsListRefetch,
  } = useQuery({
    queryKey: ["getPatientsList", page, pageSize, debouncedInputMedicine],
    queryFn: () =>
      getPatientsList(
        user?.token,
        debouncedInputMedicine,
        page,
        pageSize,
        debouncedInputMedicine
      ),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const handlePatientSearch = (e) => {
    e.preventDefault();
    setPatientInput(e?.target?.value);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Uid",
      dataIndex: "uid",
    },
    {
      title: "Full Name",
      dataIndex: "full_name",
    },
    //
    {
      title: "Mobile",
      dataIndex: "mobile",
    },
    {
      title: "Marital Status",
      dataIndex: "marital_status",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Dob",
      dataIndex: "dob",
    },

    {
      title: "Age",
      dataIndex: "age",
    },
    {
      title: "Occupation",
      dataIndex: "occupation",
      render: (_, record) => (
        <Link to={`/admin/occupation/${record?.occupation_id}`}>
          {record?.occupation}
        </Link>
      ),
    },
    {
      title: "Education",
      dataIndex: "education",
      render: (_, record) => (
        <Link to={`/admin/education/${record?.education_id}`}>
          {record?.education}
        </Link>
      ),
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (_, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  className="dropdown-item"
                  to={`/admin/patient/${record?.id}`}
                >
                  <i className="fa-regular fa-eye m-r-5" />
                  View
                </Link>
                <button
                  className="dropdown-item"
                  onClick={() => navigate("/editpatients", { state: record })}
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </button>
                <Link
                  className="dropdown-item"
                  onClick={() => setDeleteId(record.id)}
                  data-bs-toggle="modal"
                  data-bs-target="#delete_patient"
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];
  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
    // refetchSuppliersList();
  };
  const handleDeletePatient = async (e) => {
    e.preventDefault();

    try {
      const data = await deletePatient(user?.token, deleteId);
      if (data.status.status === "SUCCESS") {
        toast.success("Patient Deleted Successfully");
        getAllPatientsListRefetch();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Patients </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">Patients List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Patients List</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <span
                                style={{
                                  width: "100%",
                                  position: "relative",
                                }}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                  value={patientInput}
                                  onChange={handlePatientSearch}
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </span>
                            </div>
                            <div className="add-group">
                              <Link
                                to="/addpatients"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                onClick={getAllPatientsListRefetch}
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        {/* <Link id="download-pdf" to="#" className=" me-2"> */}
                        <img
                          className=" me-2"
                          onClick={() =>
                            handleDownloadPdfPatient(
                              getAllPatientsListData?.payload?.patients
                            )
                          }
                          src={pdficon}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                        {/* <Link
                           
                            id="download-csv"
                            className=" me-2"
                          > */}
                        <img
                          onClick={() =>
                            generateCsv(
                              getAllPatientsListData?.payload?.patients
                            )
                          }
                          className=" me-2"
                          src={pdficon3}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                        {/* <Link id="show-json" to="#"> */}
                        <img
                          onClick={() =>
                            handleShowJson(
                              getAllPatientsListData?.payload?.patients
                            )
                          }
                          width={"25px"}
                          height={"26px"}
                          src={pdficon5}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      loading={getAllPatientsListLoading}
                      pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: getAllPatientsListData?.meta?.total_count,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        onChange: handlePageChange,
                      }}
                      columns={columns}
                      dataSource={getAllPatientsListData?.payload?.patients}
                      rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                      scroll={{ x: "max-content" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="notification-box">
          <div className="msg-sidebar notifications msg-noti">
            <div className="topnav-dropdown-header">
              <span>Messages</span>
            </div>
            <div className="drop-scroll msg-list-scroll" id="msg_list">
              <ul className="list-box">
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">R</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Richard Miles </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item new-message">
                      <div className="list-left">
                        <span className="avatar">J</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">John Doe</span>
                        <span className="message-time">1 Aug</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">T</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Tarah Shropshire{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">M</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Mike Litorus</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">C</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Catherine Manseau{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">D</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Domenic Houston{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">B</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Buster Wigton </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">R</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Rolland Webber </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">C</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Claire Mapes </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">M</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Melita Faucher</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">J</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Jeffery Lalor</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">L</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Loren Gatlin</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">T</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Tarah Shropshire</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="topnav-dropdown-footer">
              <Link to="#">See all messages</Link>
            </div>
          </div>
        </div>
      </div>
      <div
        id="delete_patient"
        className="modal fade delete-modal"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src={imagesend} alt="#" width={50} height={46} />
              <h3>Are you sure want to delete this ?</h3>
              <div className="m-t-20">
                {" "}
                <Link
                  to="#"
                  className="btn btn-white me-2"
                  data-bs-dismiss="modal"
                >
                  Close
                </Link>
                <button
                  onClick={handleDeletePatient}
                  type="submit"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientsList;
