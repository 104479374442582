import { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getPack } from "../../connections/getPack";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { putUpdateBatch } from "../../connections/putUpdateBatch";
import { toast } from "react-toastify";
import { getAllFormType } from "../../connections/getAllFormType";

const UpdateBatch = () => {
  const [batchData, setBatchData] = useState({
    batch_no: "",
    expiry_date: "",
    amount: "",
    purchase_cost: "",
    form_type: "",
    packing: "",
    stock_left: "",
  });
  const [pack, setPack] = useState([]);
  const location = useLocation();
  const MedicineBatch = location.state || null;
  const navigate = useNavigate();
  const {
    user: { user },
  } = useSelector((store) => store);
  const { data: getPackData } = useQuery({
    queryKey: ["getPack"],
    queryFn: () => getPack(user?.token),
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const handleChange = (e) => {
    const { name, value } = e.target;

    setBatchData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleChangeBatch = (name, value) => {
    setBatchData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleInputChange = (inputValue) => {
    const optionExists = pack.some((item) => item.value === inputValue);

    if (!optionExists && inputValue) {
      const newOption = {
        id: Math.floor(Math.random() * 1000),
        value: inputValue,
      };
      setPack((prevPack) => [...prevPack, newOption]);
    }
  };
  const { data: getAllFormTypeData, isLoading } = useQuery({
    queryKey: ["getAllFormType"],
    queryFn: () => getAllFormType(user?.token),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const formTypeOptions = getAllFormTypeData?.map((item) => ({
    value: item,
    label: item,
  }));
  const options = pack?.map((item) => ({
    value: item.value,
    label: item.value,
  }));
  const handleUpdateBatch = async (e) => {
    e.preventDefault();
    const payload = {
      medicine_batch: {
        batch_no: batchData.batch_no,
        expiry_date: batchData.expiry_date,
        amount: batchData.amount,
        purchase_cost: batchData.purchase_cost,
        form_type: batchData.form_type,
        packing: batchData.packing,
        stock_left: batchData.stock_left,
      },
    };
    try {
      const data = await putUpdateBatch(
        user?.token,
        payload,
        MedicineBatch?.id
      );
      if (data.status.status === "SUCCESS") {
        toast.success("Batch Updated Successfully");
        setBatchData({});
        navigate(-1);
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (MedicineBatch) {
      setBatchData(MedicineBatch);
    }

    if (getPackData?.packing?.length > 0) {
      const newPack = getPackData?.packing
        ?.filter((el) => el !== null)
        ?.map((el) => ({
          id: el,
          value: el,
        }));
      setPack((prev) => [...prev, ...newPack]);
    }
  }, [MedicineBatch, getPackData]);

  return (
    <div>
      <Header />
      <Sidebar activeClassName="pharmacy-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Medicine Batch </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Update Medicine Batch
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Update Medicine Batch</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Batch No
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={batchData?.batch_no}
                              name="batch_no"
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Expiry Date
                              <span className="login-danger">*</span>
                            </label>
                            <DatePicker
                              value={
                                batchData?.expiry_date
                                  ? dayjs(batchData?.expiry_date)
                                  : null
                              }
                              className="form-control datetimepicker"
                              onChange={(_, dateString) =>
                                handleChangeBatch("expiry_date", dateString)
                              }
                              suffixIcon={null}
                              style={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor:
                                    "2px solid rgba(46, 55, 164, 0.1)",
                                  "&:hover": {
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "none",
                                  },
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Amount
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={batchData?.amount}
                              name="amount"
                              onChange={handleChange}
                              className="form-control"
                              type="number"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Purchase Cost
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={batchData?.purchase_cost}
                              name="purchase_cost"
                              onChange={handleChange}
                              className="form-control"
                              type="number"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Form Type <span className="login-danger">*</span>
                            </label>
                            <Select
                              isLoading={isLoading}
                              value={
                                formTypeOptions?.find(
                                  (option) =>
                                    option.value === batchData?.form_type
                                ) || null
                              }
                              onChange={(e) =>
                                handleChangeBatch("form_type", e?.value)
                              }
                              options={formTypeOptions}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>Packing</label>
                            <Select
                              value={
                                options.find(
                                  (option) =>
                                    option.value === batchData?.packing
                                ) || null
                              }
                              onChange={(e) => {
                                handleChangeBatch(
                                  "packing",
                                  e?.value // Access the value directly from the selected option
                                );
                              }}
                              onInputChange={handleInputChange}
                              options={options}
                              placeholder="select pack"
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-12">
                          <div className="form-group local-forms">
                            <label>
                              Stock Left
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={batchData?.stock_left}
                              name="stock_left"
                              onChange={handleChange}
                              className="form-control"
                              type="number"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleUpdateBatch}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Update Batch
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(-1);
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default UpdateBatch;
