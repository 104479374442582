import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";
import { GetDetailPurchaseOrderById } from "../../connections/GetDetailPurchaseOrderById";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Skeleton } from "@mui/material";
import { Table } from "antd";
import { onShowSizeChange, itemRender } from "../Pagination";

const ViewSinglePurchaseOrder = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { data: getPurchaseOrderDetail, isLoading } = useQuery({
    queryKey: ["GetDetailPurchaseOrderById", id],
    queryFn: () => GetDetailPurchaseOrderById(user?.token, id),
    enabled: !!id,
    // staleTime: Infinity, // Keep the data fresh indefinitely
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false, // Don't refetch on component mount
  });

  // const purchaseListData = getPurchaseOrderDetail
  //   ? [getPurchaseOrderDetail]
  //   : [];

  // const purchaseOrderColumns = [
  //   {
  //     title: "Id",
  //     dataIndex: "id",
  //     // sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,/
  //   },
  //   {
  //     title: "Supplier",
  //     dataIndex: ["supplier", "name"],
  //     render: (text, record) => (
  //       <>
  //         <h2 style={{ textDecoration: "underline" }} className="profile-image">
  //           <Link to={`/admin/suppliers/${record?.supplier?.id}`}>{text}</Link>
  //         </h2>
  //       </>
  //     ),
  //   },

  //   {
  //     title: "Created At",
  //     dataIndex: "created_at",
  //     render: (date) => (
  //       <>
  //         {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
  //       </>
  //     ),
  //   },
  //   {
  //     title: "Updated At",
  //     dataIndex: "updated_at",
  //     render: (date) => (
  //       <>
  //         {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
  //       </>
  //     ),
  //   },
  //   {
  //     title: "Grand Total",
  //     dataIndex: "grand_total",
  //     //   sorter: (a, b) => a.Email.length - b.Email.length,
  //     //   render: (text, record) => (
  //     //     <>
  //     //       <Link to="#">{record.Mobile}</Link>
  //     //     </>
  //     //   ),
  //   },
  //   {
  //     title: "Bill Type",
  //     dataIndex: "bill_type",
  //     //   sorter: (a, b) => a.Date.length - b.Date.length,
  //   },
  //   {
  //     title: "Department",
  //     dataIndex: ["department", "name"],
  //     //   sorter: (a, b) => a.Time.length - b.Time.length,
  //     render: (text, record) =>
  //       user?.role === "admin" ? (
  //         <Link to={`/department_details/${record?.department?.id}`}>
  //           {text}
  //         </Link>
  //       ) : (
  //         <>{text}</>
  //       ),
  //   },
  //   {
  //     title: "Bill Number",
  //     dataIndex: "bill_number",
  //     //   sorter: (a, b) => a.Time.length - b.Time.length,
  //   },
  //   {
  //     title: "Paid",
  //     dataIndex: "paid",
  //     // sorter: (a, b) => a.Time.length - b.Time.length,
  //   },
  // ];

  const columns = [
    {
      title: "Sno",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1, // Display row index
    },
    {
      title: "Item",
      dataIndex: "hsn_code",
      key: "item",
      render: (text, record) => (
        <Link
          to={`/admin/medicines/${record?.medicine_id}`}
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            maxWidth: "150px",
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textDecoration: "underline",
          }}
        >
          {`${record?.medicine_name ? record?.medicine_name : ""} ${
            record?.lab_inventory_name ? record?.lab_inventory_name : ""
          }`}
        </Link>
      ),
    },
    {
      title: "Pack",
      dataIndex: "pack",
      key: "pack",
    },
    {
      title: "Batch",
      dataIndex: "batch",
      key: "batch",
    },
    {
      title: "Expiry",
      dataIndex: "expiry_date",
      key: "expiry",
      render: (expiry_date) => dayjs(expiry_date).format("YYYY-MM-DD"), // Format expiry date
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "CGST/SGST",
      dataIndex: "cgst",
      key: "cgst_sgst",
      render: (cgst, record) => `${cgst}/${record.sgst}`, // Concatenate CGST and SGST values
    },
    {
      title: "MRP",
      dataIndex: "mrp",
      key: "mrp",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Amount",
      dataIndex: "rate",
      key: "amount",
    },
    {
      title: "Department",
      dataIndex: "department_id",
      key: "department",
      render: (department_id) => `Department ${department_id}`, // Placeholder for department name
    },
  ];
  // const onSelectChange = (newSelectedRowKeys) => {
  //   console.log("selectedRowKeys changed: ", selectedRowKeys);
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="purchase-order-list"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Pharmacy</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      <Link to={-1}> Purchase Order</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Purchase Order Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">Id:</span>
                                  <span className="text">
                                    {getPurchaseOrderDetail?.id || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Supplier:</span>
                                  <span className="text">
                                    {getPurchaseOrderDetail?.supplier?.name ? (
                                      <h2
                                        style={{ textDecoration: "underline" }}
                                        className="profile-image"
                                      >
                                        <Link
                                          to={`/admin/suppliers/${getPurchaseOrderDetail?.supplier?.id}`}
                                        >
                                          {getPurchaseOrderDetail.supplier.name}
                                        </Link>
                                      </h2>
                                    ) : (
                                      "N/A"
                                    )}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>
                                  <span className="text">
                                    {getPurchaseOrderDetail?.created_at
                                      ? dayjs(
                                          getPurchaseOrderDetail.created_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Updated At:</span>
                                  <span className="text">
                                    {getPurchaseOrderDetail?.updated_at
                                      ? dayjs(
                                          getPurchaseOrderDetail.updated_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Grand Total:</span>
                                  <span className="text">
                                    {getPurchaseOrderDetail?.grand_total ||
                                      "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Bill Type:</span>
                                  <span className="text">
                                    {getPurchaseOrderDetail?.bill_type || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Department:</span>
                                  <span className="text">
                                    {getPurchaseOrderDetail?.department
                                      ?.name ? (
                                      user?.role === "admin" ? (
                                        <Link
                                          to={`/department_details/${getPurchaseOrderDetail.department.id}`}
                                        >
                                          {
                                            getPurchaseOrderDetail.department
                                              .name
                                          }
                                        </Link>
                                      ) : (
                                        getPurchaseOrderDetail.department.name
                                      )
                                    ) : (
                                      "N/A"
                                    )}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Bill Number:</span>
                                  <span className="text">
                                    {getPurchaseOrderDetail?.bill_number ||
                                      "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Paid:</span>
                                  <span className="text">
                                    {getPurchaseOrderDetail?.paid || "N/A"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="col-md-12 col-12 mb-2 p-2">
                            <h3 style={{ textDecoration: "underline" }}>
                              Items
                            </h3>
                          </div>
                          <div className="table-responsive doctor-list">
                            <Table
                              id="table-to-print"
                              pagination={{
                                total:
                                  getPurchaseOrderDetail?.purchase_items
                                    ?.length,
                                showTotal: (total, range) =>
                                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                //showSizeChanger: true,
                                onShowSizeChange: onShowSizeChange,
                                itemRender: itemRender,
                              }}
                              columns={columns}
                              dataSource={
                                getPurchaseOrderDetail?.purchase_items
                              }
                              // rowSelection={rowSelection}
                              scroll={{ x: "max-content" }}
                              rowKey={(record) => record.id}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ViewSinglePurchaseOrder;
