import { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Select from "react-select";
import { putMedicalTest } from "../../connections/putMedicalTest";
import { useQuery } from "@tanstack/react-query";
import { getLabsList } from "../../connections/getLabsList";
import { getMedicalTestCat } from "../../connections/getMedicalTestCat";
import { getMedicalTest } from "../../connections/getMedicalTest";

const UpdateMedicalTest = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const [formState, setFormState] = useState({});
  // const [selectedTestOptions, setSelectedTestOption] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const medicalTestData = location.state || null;
  const handleChange = (name, value) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // lab options \\\]
  const { data: getAllLabs, isLoading } = useQuery({
    queryKey: ["getLabsList", "all"],
    queryFn: () => getLabsList(user?.token, "all", null, null),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const AllLabOptions = getAllLabs?.payload?.labs || [];
  const formattedLabOptions = AllLabOptions?.map((el) => ({
    value: el?.id,
    label: el?.name,
  }));

  //medical test cat option
  const { data: getAllMedicalTestCat, isLoading: categoryLoader } = useQuery({
    queryKey: ["getMedicalTestCat", "all"],
    queryFn: () => getMedicalTestCat(user?.token, "all", null, null),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const AllMedicalTestCatOptions =
    getAllMedicalTestCat?.payload?.medical_test_categories || [];
  const formattedMedicalTestCatOptions = AllMedicalTestCatOptions?.map(
    (el) => ({
      value: el?.id,
      label: el?.name,
    })
  );

  //medical test option for composite filed [this doubt]
  const { data: medicalTestDataList, isLoading: compositeLoader } = useQuery({
    queryKey: ["getMedicalTest", "all"],
    queryFn: () => getMedicalTest(user?.token, "all", null, null, {}),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const AllMedicalTestOptions =
    medicalTestDataList?.payload?.medical_tests || [];
  const formattedMedicalTestOptions = AllMedicalTestOptions?.map((el) => ({
    value: el?.id?.toString(),
    label: el?.name,
  }));
  const handleAddMedicalTest = async (e) => {
    if (!formState.code) {
      toast.error("Code is required");
      return;
    }
    e.preventDefault();
    const payload = {
      medical_test: formState,
    };

    try {
      const data = await putMedicalTest(
        user?.token,
        payload,
        medicalTestData?.id
      );
      if (data.status.status === "SUCCESS") {
        toast.success("Medical Test Updated Successfully");
        setFormState({});
        navigate("/admin/medical_tests");
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error.message);
    }
  };
  useEffect(() => {
    if (medicalTestData) {
      console.log(medicalTestData);
      setFormState((prev) => ({
        ...prev,
        name: medicalTestData?.name,
        code: medicalTestData?.code,
        lab_id: medicalTestData?.lab?.id,
        medical_test_category_id: medicalTestData?.medical_test_category?.id,
        female_min: medicalTestData?.female_min,
        male_min: medicalTestData?.male_min,
        female_max: medicalTestData?.female_max,
        male_max: medicalTestData?.male_max,
        unit: medicalTestData?.unit,
        critical: medicalTestData?.critical,
        sorting_order: medicalTestData?.sorting_order,
        amount: medicalTestData?.amount,
        discount: medicalTestData?.discount,
        cost_to_lab: medicalTestData?.cost_to_lab,
        number_of_films: medicalTestData?.number_of_films,
        composite: medicalTestData?.composite,
        composite_fields: medicalTestData?.composite_fields?.map((el) =>
          el?.toString()
        ),
      }));
      // setSelectedTestOption(medicalTestData?.composite_fields || []);
    }
  }, [medicalTestData]);
  return (
    <>
      <Header />
      <Sidebar activeClassName="medical-test-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Medical Test </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Update Medical Test
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Update Medical Test</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Name
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.name}
                              name="name"
                              onChange={(e) =>
                                handleChange("name", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Code
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.code}
                              name="code"
                              onChange={(e) =>
                                handleChange("code", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Lab
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              isLoading={isLoading}
                              options={formattedLabOptions}
                              value={
                                formattedLabOptions.find(
                                  (option) => option.value === formState?.lab_id
                                ) || null
                              }
                              onChange={(selectedOptions) => {
                                handleChange("lab_id", selectedOptions?.value);
                              }}
                              styles={{
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 999999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              isSearchable
                              placeholder="Choose lab"
                              className="react-select"
                              classNamePrefix="select"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Medical test category
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              isLoading={categoryLoader}
                              options={formattedMedicalTestCatOptions}
                              value={
                                formattedMedicalTestCatOptions.find(
                                  (option) =>
                                    option.value ===
                                    formState?.medical_test_category_id
                                ) || null
                              }
                              onChange={(selectedOptions) => {
                                handleChange(
                                  "medical_test_category_id",
                                  selectedOptions?.value
                                );
                              }}
                              styles={{
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 999999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              isSearchable
                              placeholder="Choose option"
                              className="react-select"
                              classNamePrefix="select"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Female Min
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.female_min}
                              name="female_min"
                              onChange={(e) =>
                                handleChange("female_min", e.target.value)
                              }
                              className="form-control"
                              type="number"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Male Min
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.male_min}
                              name="male_min"
                              onChange={(e) =>
                                handleChange("male_min", e.target.value)
                              }
                              className="form-control"
                              type="number"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Female Max
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.female_max}
                              name="female_max"
                              onChange={(e) =>
                                handleChange("female_max", e.target.value)
                              }
                              className="form-control"
                              type="number"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Male Max
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.male_max}
                              name="male_max"
                              onChange={(e) =>
                                handleChange("male_max", e.target.value)
                              }
                              className="form-control"
                              type="number"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Unit
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.unit}
                              name="unit"
                              onChange={(e) =>
                                handleChange("unit", e.target.value)
                              }
                              className="form-control"
                              type="number"
                              // required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Critical
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.critical}
                              name="critical"
                              onChange={(e) =>
                                handleChange("critical", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              // required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Sorting order
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.sorting_order}
                              name="sorting_order"
                              onChange={(e) =>
                                handleChange("sorting_order", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              // required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Amount
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.amount}
                              name="amount"
                              onChange={(e) =>
                                handleChange("amount", e.target.value)
                              }
                              className="form-control"
                              type="number"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Discount
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.discount}
                              name="discount"
                              onChange={(e) =>
                                handleChange("discount", e.target.value)
                              }
                              className="form-control"
                              type="number"
                              // required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Cost to lab
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.cost_to_lab}
                              name="cost_to_lab"
                              onChange={(e) =>
                                handleChange("cost_to_lab", e.target.value)
                              }
                              className="form-control"
                              type="number"
                              // required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Number of films
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.number_of_films}
                              name="number_of_films"
                              onChange={(e) =>
                                handleChange("number_of_films", e.target.value)
                              }
                              className="form-control"
                              type="number"
                              // required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Composite Fields
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              isLoading={compositeLoader}
                              options={formattedMedicalTestOptions}
                              value={formState?.composite_fields?.map(
                                (test) => ({
                                  value: test,
                                  label: formattedMedicalTestOptions.find(
                                    (option) => option.value === test
                                  )?.label, // Find the label that matches the value
                                })
                              )}
                              onChange={(selectedOptions) => {
                                // Convert selectedOptions to an array of values
                                const selectedValues = Array.isArray(
                                  selectedOptions
                                )
                                  ? selectedOptions.map(
                                      (option) => option.value
                                    )
                                  : [];

                                handleChange(
                                  "composite_fields",
                                  selectedValues
                                ); // Update formState with values only
                              }}
                              styles={{
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 999999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              isMulti
                              isSearchable
                              placeholder="Choose lab"
                              className="react-select"
                              classNamePrefix="select"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <label>
                              Composite
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              checked={formState?.composite || false}
                              name="composite"
                              onChange={(e) =>
                                handleChange("composite", e.target.checked)
                              }
                              type="checkbox"
                              // required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 mt-4">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleAddMedicalTest}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/medical_tests");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
export default UpdateMedicalTest;
