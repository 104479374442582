import React, { useState } from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import DonutChart from "./DonutChart";
import Sidebar from "../../Sidebar";
import Header from "../../Header";
// import PatientChart from "./PaitentChart";
// import Select from "react-select";
import {
  // Avatar2,
  // Avatar3,
  // Avatar4,
  // Avatar5,
  calendar,
  // dep_icon1,
  // dep_icon2,
  // dep_icon3,
  // dep_icon4,
  // dep_icon5,
  empty_wallet,
  imagesend,
  morning_img_01,
  profile_add,
  scissor,
  // user001,
} from "../../imagepath";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import {
  getCurrentMonthStartEndIST,
  getGreeting,
} from "../../../utils/constant/constant";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getAllDashboardSummaryCount } from "../../../connections/getAllDashboardSummaryCount";
import { getMedicineSales } from "../../../connections/getMedicineSales";
import MedicineChart from "./MedicineChart";
import { IconButton, Modal, Skeleton, TextField } from "@mui/material";
import { getTopTenMedicineByImpact } from "../../../connections/getTopTenMedicineByImpact";
import Calendar from "react-calendar";
import { getPurchaseOrder } from "../../../connections/getPurchaseOrder";
import { getTopMedicineBySale } from "../../../connections/getTopMedicineBySale";
import { CloseIcon } from "yet-another-react-lightbox";

const Admin_Dashboard = () => {
  // const [selectedOption, setSelectedOption] = useState(null);
  const { startDateOfMonth, endDateOfMonth } = getCurrentMonthStartEndIST();
  const [startDate, setStartDate] = useState(startDateOfMonth);
  const [endDate, setEndDate] = useState(endDateOfMonth);
  const [calValue, onChangeCal] = useState(new Date());
  const [openPurchaseOrder, setOpenPurchaseOrder] = useState(false);
  const [medChartLoader, setMedChartLoader] = useState(false);
  const handleOpenPurchaseOrder = () => setOpenPurchaseOrder(true);
  const handleClosePurchaseOrder = () => setOpenPurchaseOrder(false);
  const {
    user: { user },
  } = useSelector((store) => store);
  const { data: getSummaryCount, isLoading: countLoader } = useQuery({
    queryKey: ["getAllDashboardSummaryCount"],
    queryFn: async () => {
      setMedChartLoader(true); // Start loader
      try {
        const result = await getAllDashboardSummaryCount(
          user?.token,
          startDateOfMonth,
          endDateOfMonth
        );
        return result; // Return the result from the API
      } finally {
        setMedChartLoader(false); // Stop loader even if there is an error
      }
    },
    // enabled:
    // staleTime: Infinity, // Keep the data fresh indefinitely
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false, // Don't refetch on component mount
  });
  const {
    data: getMedicineSalesData,
    isLoading,
    refetch: refetchMedicineSales,
  } = useQuery({
    queryKey: ["getMedicineSales"],
    queryFn: () => getMedicineSales(user?.token, startDate, endDate),
    // enabled:
    // staleTime: Infinity, // Keep the data fresh indefinitely
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false, // Don't refetch on component mount
  });
  const { data: getTopTenImpactMedicineData } = useQuery({
    queryKey: ["getTopTenMedicineByImpact", startDateOfMonth, endDateOfMonth],
    queryFn: () =>
      getTopTenMedicineByImpact(user?.token, startDateOfMonth, endDateOfMonth),
    // enabled:
    // staleTime: Infinity,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
  const { data: getPurchaseOrderData, isLoading: purchaseOrderLoad } = useQuery(
    {
      queryKey: ["getPurchaseOrder", calValue.toLocaleDateString("en-CA")],
      queryFn: () =>
        getPurchaseOrder(user?.token, calValue.toLocaleDateString("en-CA")),
      enabled: !!calValue.toLocaleDateString("en-CA"),
      // staleTime: Infinity,
      cacheTime: 1000 * 60 * 60,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    }
  );
  const { data: getTopTenSalesMedicineData } = useQuery({
    queryKey: ["getTopMedicineBySale", startDateOfMonth, endDateOfMonth],
    queryFn: () =>
      getTopMedicineBySale(user?.token, startDateOfMonth, endDateOfMonth),
    // enabled:
    // staleTime: Infinity,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
  // eslint-disable-next-line no-unused-vars
  const [year, setyear] = useState([
    { value: 1, label: "2022" },
    { value: 2, label: "2021" },
    { value: 3, label: "2020" },
    { value: 4, label: "2019" },
  ]);
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };
  const handleSubmitDateFilter = async () => {
    setMedChartLoader(true); // Start the loader before refetching
    try {
      await refetchMedicineSales(user?.token, startDate, endDate); // Wait for the refetch to complete
    } finally {
      setMedChartLoader(false); // Stop the loader regardless of success or error
    }
  };

  console.log(medChartLoader, isLoading);
  return (
    <>
      <Header />

      <Sidebar
        id="menu-item"
        id1="menu-items"
        activeClassName="admin-dashboard"
      />

      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Dashboard </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Admin Dashboard</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="good-morning-blk">
              <div className="row">
                <div className="col-md-6">
                  <div className="morning-user">
                    <h2>
                      {getGreeting()},{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {user?.email ? user?.email?.split("@")[0] : null}
                      </span>
                    </h2>
                    <p>Have a nice day at work</p>
                  </div>
                </div>
                <div className="col-md-6 position-blk">
                  <div className="morning-img">
                    <img src={morning_img_01} alt="#" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="dash-widget">
                  <div className="dash-boxs comman-flex-center">
                    <img src={calendar} alt="#" />
                  </div>
                  <div className="dash-content dash-count flex-grow-1">
                    <h4>Appointments</h4>
                    <h2>
                      {" "}
                      <CountUp
                        delay={0.4}
                        end={getSummaryCount?.total_appointments}
                        duration={0.6}
                      />
                    </h2>
                    {/* <p>
                      <span className="passive-view">
                        <i className="feather-arrow-up-right me-1">
                          <FeatherIcon icon="arrow-up-right" />
                        </i>
                        40%
                      </span>{" "}
                      vs last month
                    </p> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="dash-widget">
                  <div className="dash-boxs comman-flex-center">
                    <img src={profile_add} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>New Patients</h4>
                    <h2>
                      <CountUp
                        delay={0.4}
                        end={getSummaryCount?.new_patients}
                        duration={0.6}
                      />
                    </h2>
                    {/* <p>
                      <span className="passive-view">
                        <i className="feather-arrow-up-right me-1">
                          <FeatherIcon icon="arrow-up-right" />
                        </i>
                        20%
                      </span>{" "}
                      vs last month
                    </p> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="dash-widget">
                  <div className="dash-boxs comman-flex-center">
                    <img src={scissor} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    {/* <h4>Operations</h4> */}
                    <h4>Total Invoices</h4>

                    <h2>
                      <CountUp
                        delay={0.4}
                        end={getSummaryCount?.total_invoices}
                        duration={0.6}
                      />
                    </h2>
                    {/* <p>
                      <span className="negative-view">
                        <i className="feather-arrow-down-right me-1">
                          <FeatherIcon icon="arrow-down-right" />
                        </i>
                        15%
                      </span>{" "}
                      vs last month
                    </p> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="dash-widget">
                  <div className="dash-boxs comman-flex-center">
                    <img src={empty_wallet} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Total Revenue</h4>
                    <h2>
                      INR
                      <CountUp
                        delay={0.4}
                        end={getSummaryCount?.total_revenue}
                        duration={0.6}
                      />
                    </h2>
                    {/* <p>
                      <span className="passive-view">
                        <i className="feather-arrow-up-right me-1">
                          <FeatherIcon icon="arrow-up-right" />
                        </i>
                        30%
                      </span>{" "}
                      vs last month
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6 col-xl-9">
                <div className="card">
                  <div className="card-body">
                    <div className="chart-title patient-visit">
                      <h4>Medicine Sales</h4>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                          flexWrap: "wrap",
                        }}
                        className="form-group mt-3"
                      >
                        <TextField
                          label="Start Date"
                          type="date"
                          value={startDate}
                          onChange={handleStartDateChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          // fullWidth
                          size="small"
                        />

                        <TextField
                          label="End Date"
                          type="date"
                          value={endDate}
                          onChange={handleEndDateChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          // fullWidth
                          size="small"
                        />
                        <button
                          onClick={handleSubmitDateFilter}
                          className="btn btn-primary submit-form me-2"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                    <div id="patient-chart" />
                    {medChartLoader ? (
                      // <PatientChart />
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={230} // Same height as your chart
                      />
                    ) : (
                      <MedicineChart
                        getMedicineSalesData={getMedicineSalesData}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-6 col-xl-3 d-flex">
                <div className="card">
                  <div className="card-body">
                    <div className="chart-title">
                      <h4>Patient by Department</h4>
                    </div>
                    <div id="donut-chart-dash" className="chart-user-icon">
                      {countLoader ? (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={230} // Same height as your chart
                        />
                      ) : (
                        <DonutChart getSummaryCount={getSummaryCount} />
                      )}

                      {/* <img src={user001} alt="#" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12  col-xl-4">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title mb-0">
                      Top 10 Medicine (By Sales)
                    </h4>
                  </div>

                  {getTopTenSalesMedicineData?.top_medicines?.map((el) => (
                    <div
                      key={el?.medicine_id}
                      style={{ paddingTop: "10px", paddingBottom: "12px" }}
                      className="card-body"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <h5>{el?.medicine_name}</h5>
                        <p style={{ margin: 0 }}>
                          {el?.total_revenue?.toFixed(0)}
                        </p>
                      </div>

                      {/* <div className="activity-top">
                      <div className="departments-list">
                        <h4>Dentist</h4>
                        <p>24%</p>
                      </div>
                    </div>
                    <div className="activity-top">
                      <div className="departments-list">
                        <h4>ENT</h4>
                        <p>10%</p>
                      </div>
                    </div>
                    <div className="activity-top">
                      <div className="departments-list">
                        <h4>Cardiologist</h4>
                        <p>15%</p>
                      </div>
                    </div>
                    <div className="activity-top mb-0">
                      <div className="departments-list">
                        <h4>Opthomology</h4>
                        <p>20%</p>
                      </div> */}
                      {/* </div> */}
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-12 col-md-12  col-xl-4">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title mb-0">
                      Top 10 Medicine (By Impact)
                    </h4>
                  </div>

                  {getTopTenImpactMedicineData?.top_medicines?.map((el) => (
                    <div
                      key={el?.medicine_id}
                      style={{ paddingTop: "10px", paddingBottom: "12px" }}
                      className="card-body"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <h5>{el?.medicine_name}</h5>
                        <p style={{ margin: 0 }}>
                          {el?.total_revenue?.toFixed(0)}
                        </p>
                      </div>

                      {/* <div className="activity-top">
                      <div className="departments-list">
                        <h4>Dentist</h4>
                        <p>24%</p>
                      </div>
                    </div>
                    <div className="activity-top">
                      <div className="departments-list">
                        <h4>ENT</h4>
                        <p>10%</p>
                      </div>
                    </div>
                    <div className="activity-top">
                      <div className="departments-list">
                        <h4>Cardiologist</h4>
                        <p>15%</p>
                      </div>
                    </div>
                    <div className="activity-top mb-0">
                      <div className="departments-list">
                        <h4>Opthomology</h4>
                        <p>20%</p>
                      </div> */}
                      {/* </div> */}
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-12 col-md-12  col-xl-4">
                <div
                  // style={{ minHeight: 290 }}
                  className="card top-departments"
                >
                  <div className="card-header">
                    <h4 className="card-title mb-0">Purchase Orders</h4>
                  </div>

                  <Calendar
                    style={{ widht: "100%" }}
                    onChange={(e) => {
                      onChangeCal(e);
                      handleOpenPurchaseOrder();
                    }}
                    value={calValue}
                  />
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12 col-md-12  col-xl-4">
                <div
                  // style={{ minHeight: 290 }}
                  className="card top-departments"
                >
                  <div className="card-header">
                    <h4 className="card-title mb-0">Purchase Orders</h4>
                  </div>

                  <Calendar
                    style={{ widht: "100%" }}
                    onChange={(e) => {
                      onChangeCal(e);
                      handleOpenPurchaseOrder();
                    }}
                    value={calValue}
                  />
                </div>
              </div>
              <div className="col-12 col-md-12  col-xl-8">
                <div className="card">
                  <div className="card-header pb-0">
                    <h4 className="card-title d-inline-block">
                      Recent Patients{" "}
                    </h4>{" "}
                    <Link
                      to="/editpatients"
                      className="float-end patient-views"
                    >
                      Show all
                    </Link>
                  </div>
                  <div className="card-block table-dash">
                    <div className="table-responsive">
                      <table className="table mb-0 border-0 datatable custom-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="form-check check-tables">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue="something"
                                />
                              </div>
                            </th>
                            <th>No</th>
                            <th>Patient name</th>
                            <th>Age</th>
                            <th>Date of Birth</th>
                            <th>Diagnosis</th>
                            <th>Triage</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="form-check check-tables">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue="something"
                                />
                              </div>
                            </td>
                            <td>R00001</td>
                            <td className="table-image">
                              <img
                                width={28}
                                height={28}
                                className="rounded-circle"
                                src={Avatar2}
                                alt="#"
                              />
                              <h2>Andrea Lalema</h2>
                            </td>
                            <td>21</td>
                            <td>07 January 2002</td>
                            <td>Heart attack</td>
                            <td>
                              <button className="custom-badge status-green ">
                                Non Urgent
                              </button>
                            </td>
                            <td className="text-end">
                              <div className="dropdown dropdown-action">
                                <Link
                                  to="#"
                                  className="action-icon dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa fa-ellipsis-v" />
                                </Link>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <Link
                                    className="dropdown-item"
                                    to="/editpatients"
                                  >
                                    <i className="fa-solid fa-pen-to-square m-r-5" />{" "}
                                    Edit
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete_patient"
                                  >
                                    <i className="fa fa-trash-alt m-r-5"></i>{" "}
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="form-check check-tables">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue="something"
                                />
                              </div>
                            </td>
                            <td>R00002</td>
                            <td className="table-image">
                              <img
                                width={28}
                                height={28}
                                className="rounded-circle"
                                src={Avatar3}
                                alt="#"
                              />
                              <h2>Mark Hay Smith</h2>
                            </td>
                            <td>23</td>
                            <td>06 January 2002</td>
                            <td>Jaundice</td>
                            <td>
                              <button className="custom-badge status-pink">
                                Emergency
                              </button>
                            </td>
                            <td className="text-end">
                              <div className="dropdown dropdown-action">
                                <Link
                                  to="#"
                                  className="action-icon dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa fa-ellipsis-v" />
                                </Link>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <Link
                                    className="dropdown-item"
                                    to="/editpatients"
                                  >
                                    <i className="fa-solid fa-pen-to-square m-r-5" />{" "}
                                    Edit
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete_patient"
                                  >
                                    <i className="fa fa-trash-alt m-r-5"></i>{" "}
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="form-check check-tables">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue="something"
                                />
                              </div>
                            </td>
                            <td>R00003</td>
                            <td className="table-image">
                              <img
                                width={28}
                                height={28}
                                className="rounded-circle"
                                src={Avatar4}
                                alt="#"
                              />
                              <h2>Cristina Groves</h2>
                            </td>
                            <td>25</td>
                            <td>10 January 2002</td>
                            <td>Malaria</td>
                            <td>
                              <button className="custom-badge status-gray">
                                Out Patient
                              </button>
                            </td>
                            <td className="text-end">
                              <div className="dropdown dropdown-action">
                                <Link
                                  to="#"
                                  className="action-icon dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa fa-ellipsis-v" />
                                </Link>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <Link
                                    className="dropdown-item"
                                    to="/editpatients"
                                  >
                                    <i className="fa-solid fa-pen-to-square m-r-5" />{" "}
                                    Edit
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete_patient"
                                  >
                                    <i className="fa fa-trash-alt m-r-5"></i>{" "}
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="form-check check-tables">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue="something"
                                />
                              </div>
                            </td>
                            <td>R00004</td>
                            <td className="table-image">
                              <img
                                width={28}
                                height={28}
                                className="rounded-circle"
                                src={Avatar5}
                                alt="#"
                              />
                              <h2>Galaviz Lalema</h2>
                            </td>
                            <td>21</td>
                            <td>09 January 2002</td>
                            <td>Typhoid</td>
                            <td>
                              <button className="custom-badge status-orange">
                                Non Urgent
                              </button>
                            </td>
                            <td className="text-end">
                              <div className="dropdown dropdown-action">
                                <Link
                                  to="#"
                                  className="action-icon dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa fa-ellipsis-v" />
                                </Link>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <Link
                                    className="dropdown-item"
                                    to="/editpatients"
                                  >
                                    <i className="fa-solid fa-pen-to-square m-r-5" />{" "}
                                    Edit
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete_patient"
                                  >
                                    <i className="fa fa-trash-alt m-r-5"></i>{" "}
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button type="submit" className="btn btn-danger">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="delete_patient"
              className="modal fade delete-modal"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <img src={imagesend} alt="#" width={50} height={46} />
                    <h3>Are you sure want to delete this ?</h3>
                    <div className="m-t-20">
                      {" "}
                      <Link
                        to="#"
                        className="btn btn-white me-2"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </Link>
                      <button type="submit" className="btn btn-danger">
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <Modal
        sx={{
          width: "80vw",
          margin: "auto",
          mt: 10,
          // display: "flex",
          // alignItems: "center",
        }}
        open={openPurchaseOrder}
        onClose={handleClosePurchaseOrder}
        aria-labelledby="purchase-order"
      >
        <div className="card">
          <div className="card-header">
            <h4 className="card-title d-inline-block mb-2">
              Purchase Orders Details
            </h4>{" "}
            {/* <Link to="/appoinmentlist" className="patient-views float-end"> */}
            <IconButton
              onClick={handleClosePurchaseOrder}
              style={{ float: "right" }}
            >
              <CloseIcon />
            </IconButton>
            {/* </Link> */}
          </div>
          <div className="card-body p-0 table-dash">
            <div style={{ minHeight: "260px" }} className="table-responsive">
              {purchaseOrderLoad ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={230} // Same height as your chart
                />
              ) : (
                <table className="table mb-0 border-0 datatable custom-table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>HSN Code</th>
                      <th>Batch</th>
                      <th>Expiry Date</th>
                      <th>Quantity</th>
                      <th>CGST (%)</th>
                      <th>SGST (%)</th>
                      <th>Rate</th>
                      <th>MRP</th>
                      <th>Amount</th>
                      <th>Pack</th>
                      <th>Discount</th>
                      <th>Purchase Order ID</th>
                      <th>Department ID</th>
                      <th>Medicine</th>
                      {/* <th /> */}
                    </tr>
                  </thead>

                  <tbody>
                    {getPurchaseOrderData?.purchase_items?.length > 0 ? (
                      getPurchaseOrderData?.purchase_items?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td>{item?.id}</td>
                            <td>{item?.hsn_code}</td>
                            <td>{item?.batch}</td>
                            <td>{item?.expiry_date}</td>
                            <td>{item?.quantity}</td>
                            <td>{item?.cgst}</td>
                            <td>{item?.sgst}</td>
                            <td>{item?.rate}</td>
                            <td>{item?.mrp}</td>
                            <td>{item?.amount}</td>
                            <td>{item?.pack}</td>
                            <td>{item?.discount}</td>
                            <td>{item?.purchase_order_id}</td>
                            <td>{item?.department_id}</td>
                            <td>
                              {item?.medicine
                                ? item?.medicine?.name
                                : "No medicine"}
                            </td>
                            {/* <td className="text-end">
                              <div className="dropdown dropdown-action">
                                <Link
                                  to="#"
                                  className="action-icon dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa fa-ellipsis-v" />
                                </Link>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <Link
                                    className="dropdown-item"
                                    to="/appoinmentlist"
                                  >
                                    <i className="fa-solid fa-pen-to-square m-r-5" />{" "}
                                    Edit
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete_patient"
                                  >
                                    <i className="fa fa-trash-alt m-r-5"></i>{" "}
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </td> */}
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan="18" style={{ textAlign: "center" }}>
                          No Data Available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Admin_Dashboard;
