/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FixedSizeList as List } from "react-window";
import { useSelector } from "react-redux";
import Select from "react-select";
import { postTreatment } from "../../../connections/postTreatment";
import useDebounce from "../../../utils/hooks";
import { useQuery } from "@tanstack/react-query";
// import { getMedicineDescription } from "../../../connections/getMedicineDescription";
import { getAllMedicine } from "../../../connections/getAllMedicine";
import { getAllDiagnoses } from "../../../connections/getAllDiagnoses";
import { getSpecialInstruction } from "../../../connections/getSpecialInstruction";
import { getMedicalTest } from "../../../connections/getMedicalTest";
import { getMedicineDescription } from "../../../connections/getMedicineDescription";
import { getMedicineRemarks } from "../../../connections/getMedicineRemarks";

const AddTreatMent = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const [formState, setFormState] = useState({});
  const [diagnosisOptions, setDiagnosisOptions] = useState([]);
  // const [debounceInputs, setDebounceInputs] = useState({
  //   medicine: "",
  // });
  const [inputValue, setInputValue] = useState("");
  const [medicineInput, setMedicineInput] = useState("");
  const [medicinesAttributes, setMedicinesAttributes] = useState([]);
  const handleAddMedicineTreatment = (e) => {
    e.preventDefault();
    setMedicinesAttributes((prev) => [
      ...prev,
      {
        medicine_id: [],
        medicine_description: [],
        medicine_remark: [],
      },
    ]);
  };
  const handleRemoveMedicine = (e, idx) => {
    e.preventDefault();

    const newRow = [...medicinesAttributes];
    const updatedRow = newRow?.filter((_, index) => index !== idx);
    setMedicinesAttributes(updatedRow);
  };
  const navigate = useNavigate();
  const { data: diagnosesData, isLoading } = useQuery({
    queryKey: ["getAllDiagnoses", "all"],
    queryFn: () => getAllDiagnoses(user?.token, "all", null, null),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const handleChange = (name, value) => {
    if (
      name === "medicine_id" ||
      name === "medicine_description" ||
      name === "medicine_remark"
    ) {
      setFormState((prev) => ({
        ...prev,
        treatment_medicines_attributes: [
          ...(prev.treatment_medicines_attributes || []), // existing items
          { [name]: value }, // add new object with dynamic key
        ],
      }));
    } else {
      setFormState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleChangeMedicine = (index, field, value) => {
    const newMedicines = [...medicinesAttributes];
    newMedicines[index] = { ...newMedicines[index], [field]: value };
    setMedicinesAttributes(newMedicines);
  };
  // diagnosis options

  // special instructions options
  const { data: getSpeInsData, isLoading: specialInstLoader } = useQuery({
    queryKey: ["getSpecialInstruction", "all"],
    queryFn: () => getSpecialInstruction(user?.token, "all", null, null),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const allSpecialInstructions =
    getSpeInsData?.payload?.special_instructions || [];
  const formattedSpecialInstructions = allSpecialInstructions?.map((el) => ({
    value: el.value,
    label: el.value,
  }));
  // medical test options
  const { data: getAllMedicalTestData, isLoading: medicalTestLoader } =
    useQuery({
      queryKey: ["getMedicalTest", "all"],
      queryFn: () => getMedicalTest(user?.token, "all", null, null, {}),
      refetchOnWindowFocus: false, // Don't refetch when window regains focus
      refetchOnReconnect: false, // Don't refetch when reconnecting
    });
  const allMedicalTest = getAllMedicalTestData?.payload?.medical_tests || [];
  const formattedMedicalTest = allMedicalTest?.map((el) => ({
    label: el.name,
    value: el.name,
  }));

  // medicine Options
  const debouncedInputMedicine = useDebounce(medicineInput, 1000);
  const { data: getAllMedicineData } = useQuery({
    queryKey: ["getAllMedicine", debouncedInputMedicine],
    queryFn: () =>
      getAllMedicine(user?.token, "q", 1, 10, debouncedInputMedicine),
    enabled: !!debouncedInputMedicine,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const allMedicine = getAllMedicineData?.payload?.medicines || [];
  const formattedMedicineOptions = allMedicine?.map((el) => ({
    value: el?.id,
    label: el.name,
  }));
  const MenuList = (props) => {
    const { options, children, maxHeight, getValue } = props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * 35;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={35}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };
  // const medicineOptions = useMemo(() => formattedMedicineOptions, []);
  //medicine description
  const { data: getMedicineDescData, isLoading: medDescLoader } = useQuery({
    queryKey: ["getMedicineDescription", "all"],
    queryFn: () => getMedicineDescription(user?.token, "all", null, null),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const allMedicineDescription =
    getMedicineDescData?.payload?.medicine_descriptions || [];
  const formattedMedicineDescription = allMedicineDescription?.map((el) => ({
    value: el?.value,
    label: el.value,
  }));
  //medicine remarks options
  const { data: medicineRemarkData, isLoading: remarksLoader } = useQuery({
    queryKey: ["getMedicineRemarks", "all"],
    queryFn: () => getMedicineRemarks(user?.token, "all", null, null),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const allMedicineRemarks =
    medicineRemarkData?.payload?.medicine_remarks || [];
  const formattedMedicineRemarks = allMedicineRemarks?.map((el) => ({
    value: el?.value,
    label: el.value,
  }));
  const medicineRemarkOptions = useMemo(() => formattedMedicineRemarks, []);
  const handleAddTreatment = async (e) => {
    e.preventDefault();
    const payload = {
      treatment: {
        name: formState?.name,
        self_notes: formState?.self_notes,
        diagnosis: formState?.diagnosis?.map((option) => option.value) || [],
        special_instructions: formState?.special_instructions?.map(
          (el) => el.value
        ),
        // selected_medical_tests_order: "",
        medical_tests: formState?.medical_tests?.map((el) => el.value),
        ...(medicinesAttributes?.length > 0 && {
          treatment_medicines_attributes: medicinesAttributes,
        }),
      },
    };
    if (!formState?.name) {
      toast.error("Please provide a name");
      return;
    }
    // Check if any object in the array has empty fields
    const hasInvalidMedicine = medicinesAttributes.some(
      (item) =>
        !item.medicine_id ||
        !item.medicine_description?.length > 0 ||
        !item.medicine_remark?.length > 0
    );
    // console.log(hasInvalidMedicine, "hasInvalidMedicine", medicinesAttributes);
    if (hasInvalidMedicine) {
      toast.error(
        "Each medicine must have Medicine ID, Description, and Remark filled"
      );
      return;
    }

    try {
      const data = await postTreatment(user?.token, payload);
      if (data.status.status === "SUCCESS") {
        toast.success("Treatment Added Successfully");
        setFormState({});
        navigate("/admin/prescription/treatments");
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error);
    }
  };
  const debouncedInput = useDebounce(inputValue, 700);

  const handleMedicineChange = (value) => {
    setMedicineInput(value);
  };
  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };

  const options = diagnosisOptions?.map((item) => ({
    value: item.value,
    label: item.value,
  }));
  useEffect(() => {
    let allDiagnosis = diagnosesData?.payload?.diagnoses || [];

    const formattedDiagnosis = allDiagnosis?.map((el) => ({
      label: el,
      value: el,
    }));
    setDiagnosisOptions(formattedDiagnosis);
  }, [diagnosesData]);
  useEffect(() => {
    if (debouncedInput) {
      const optionExists = diagnosisOptions.some(
        (item) => item.value === debouncedInput
      );

      if (!optionExists) {
        const newOption = {
          value: debouncedInput,
          label: debouncedInput,
        };
        setDiagnosisOptions((prevPack) => [...prevPack, newOption]);
      }
    }
  }, [debouncedInput, diagnosisOptions]);
  // console.log(getAllMedicineData, "getAllMedicineData");
  return (
    <>
      <Header />
      <Sidebar activeClassName="treatments-dashboard" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Treatment</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Treatment</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Add Treatment</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Name
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.name}
                              name="name"
                              onChange={(e) =>
                                handleChange("name", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Self notes
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.self_notes}
                              name="self_notes"
                              onChange={(e) =>
                                handleChange("self_notes", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              // required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Diagnosis
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              isLoading={isLoading}
                              options={options}
                              value={formState?.diagnosis?.map((test) => ({
                                value: test.value,
                                label: test.label,
                              }))}
                              onChange={(selectedOptions) =>
                                handleChange("diagnosis", selectedOptions)
                              }
                              onInputChange={handleInputChange}
                              styles={{
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 9999, // Set your desired z-index here
                                }),
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 999999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              isMulti
                              isSearchable
                              placeholder="Choose diagnosis"
                              className="react-select"
                              classNamePrefix="select"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Special instructions
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              isLoading={specialInstLoader}
                              options={formattedSpecialInstructions}
                              value={formState?.special_instructions?.map(
                                (test) => ({
                                  value: test.value,
                                  label: test.label,
                                })
                              )}
                              onChange={(selectedOptions) =>
                                handleChange(
                                  "special_instructions",
                                  selectedOptions
                                )
                              }
                              styles={{
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 9999, // Set your desired z-index here
                                }),
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 999999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              isMulti
                              isSearchable
                              placeholder="Choose special instructions"
                              className="react-select"
                              classNamePrefix="select"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Medical Tests
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              isLoading={medicalTestLoader}
                              options={formattedMedicalTest}
                              value={formState?.medical_tests?.map((test) => ({
                                value: test.value,
                                label: test.label,
                              }))}
                              onChange={(selectedOptions) =>
                                handleChange("medical_tests", selectedOptions)
                              }
                              styles={{
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 9999, // Set your desired z-index here
                                }),
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 999999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              isMulti
                              isSearchable
                              placeholder="Choose medical test"
                              className="react-select"
                              classNamePrefix="select"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Add Treatment Medicine</h4>
                          </div>
                        </div>

                        {medicinesAttributes?.length > 0 &&
                          medicinesAttributes?.map((el, index) => (
                            <React.Fragment key={index}>
                              <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group local-forms">
                                  <label>
                                    Medicine
                                    <span className="login-danger">*</span>
                                  </label>
                                  <Select
                                    options={formattedMedicineOptions}
                                    value={formState?.medicine_id?.map(
                                      (test) => ({
                                        value: test.value,
                                        label: test.label,
                                      })
                                    )}
                                    onInputChange={handleMedicineChange}
                                    onChange={(selectedOptions) => {
                                      handleChangeMedicine(
                                        index,
                                        "medicine_id",
                                        selectedOptions?.value
                                      ); // Pass the array of values to handleChangeMedicine
                                    }}
                                    styles={{
                                      menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                      }),
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 999999,
                                      }),
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused
                                          ? "#2e37a4"
                                          : "rgba(46, 55, 164, 0.1)",
                                        boxShadow: state.isFocused
                                          ? "0 0 0 1px #2e37a4"
                                          : "none",
                                        "&:hover": {
                                          borderColor: "rgba(46, 55, 164, 0.1)",
                                        },
                                        borderRadius: "10px",
                                        fontSize: "14px",
                                        minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen
                                          ? "rotate(-180deg)"
                                          : "rotate(0)",
                                        transition: "250ms",
                                        width: "35px",
                                        height: "35px",
                                      }),
                                      option: (provided) => ({
                                        ...provided,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "100%",
                                        fontSize: "14px",
                                      }),
                                    }}
                                    // isMulti
                                    isSearchable
                                    placeholder="Choose medicine"
                                    className="react-select"
                                    classNamePrefix="select"
                                    // components={{ MenuList }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group local-forms">
                                  <label>
                                    Medicine Description
                                    <span className="login-danger">*</span>
                                  </label>
                                  <Select
                                    isLoading={medDescLoader}
                                    options={formattedMedicineDescription}
                                    value={formState?.medicine_description?.map(
                                      (test) => ({
                                        value: test.value,
                                        label: test.label,
                                      })
                                    )}
                                    onChange={(selectedOptions) => {
                                      // Ensure `selectedOptions` is always an array of `value` fields only
                                      const selectedValues = (
                                        Array.isArray(selectedOptions)
                                          ? selectedOptions
                                          : selectedOptions
                                          ? [selectedOptions]
                                          : []
                                      ).map((option) => option.value);

                                      handleChangeMedicine(
                                        index,
                                        "medicine_description",
                                        selectedValues
                                      ); // Pass the array of values to handleChangeMedicine
                                    }}
                                    styles={{
                                      menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999, // Set your desired z-index here
                                      }),
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 999999,
                                      }),
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused
                                          ? "#2e37a4"
                                          : "rgba(46, 55, 164, 0.1)",
                                        boxShadow: state.isFocused
                                          ? "0 0 0 1px #2e37a4"
                                          : "none",
                                        "&:hover": {
                                          borderColor: "rgba(46, 55, 164, 0.1)",
                                        },
                                        borderRadius: "10px",
                                        fontSize: "14px",
                                        minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen
                                          ? "rotate(-180deg)"
                                          : "rotate(0)",
                                        transition: "250ms",
                                        width: "35px",
                                        height: "35px",
                                      }),
                                    }}
                                    isMulti
                                    isSearchable
                                    placeholder="Choose medicine description"
                                    className="react-select"
                                    classNamePrefix="select"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-6">
                                <div className="form-group local-forms">
                                  <label>
                                    Medicine Remarks
                                    <span className="login-danger">*</span>
                                  </label>
                                  <Select
                                    isLoading={remarksLoader}
                                    options={medicineRemarkOptions}
                                    value={formState?.medicine_remark?.map(
                                      (test) => ({
                                        value: test.value,
                                        label: test.label,
                                      })
                                    )}
                                    onChange={(selectedOptions) => {
                                      // Ensure `selectedOptions` is always an array of `value` fields only
                                      const selectedValues = (
                                        Array.isArray(selectedOptions)
                                          ? selectedOptions
                                          : selectedOptions
                                          ? [selectedOptions]
                                          : []
                                      ).map((option) => option.value);

                                      handleChangeMedicine(
                                        index,
                                        "medicine_remark",
                                        selectedValues
                                      ); // Pass the array of values to handleChangeMedicine
                                    }}
                                    components={{ MenuList }}
                                    styles={{
                                      menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999, // Set your desired z-index here
                                      }),
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 999999,
                                      }),
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused
                                          ? "#2e37a4"
                                          : "rgba(46, 55, 164, 0.1)",
                                        boxShadow: state.isFocused
                                          ? "0 0 0 1px #2e37a4"
                                          : "none",
                                        "&:hover": {
                                          borderColor: "rgba(46, 55, 164, 0.1)",
                                        },
                                        borderRadius: "10px",
                                        fontSize: "14px",
                                        minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen
                                          ? "rotate(-180deg)"
                                          : "rotate(0)",
                                        transition: "250ms",
                                        width: "35px",
                                        height: "35px",
                                      }),
                                    }}
                                    isMulti
                                    isSearchable
                                    placeholder="Choose medicine description"
                                    className="react-select"
                                    classNamePrefix="select"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-6">
                                <button
                                  style={{ maxWidth: "150px" }}
                                  onClick={(e) =>
                                    handleRemoveMedicine(e, index)
                                  }
                                  className="btn btn-primary submit-form me-2 form-control mb-4"
                                >
                                  Remove
                                </button>
                              </div>
                            </React.Fragment>
                          ))}
                        <div className="col-12 col-md-6 col-xl-2">
                          <button
                            // style={{ width: "50%" }}
                            className="btn btn-primary submit-form me-2 form-control mb-4"
                            onClick={handleAddMedicineTreatment}
                          >
                            Add New Item
                          </button>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleAddTreatment}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/prescription/treatments");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
export default AddTreatMent;
