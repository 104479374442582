import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";

import { useSelector } from "react-redux";

import { Skeleton } from "@mui/material";

import { getAppointmentDetail } from "../../connections/getAppointmentDetail";
import dayjs from "dayjs";
// import { Table } from "antd";

const AppointmentDetail = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();
  const { data: getAppointmentDetailData, isLoading } = useQuery({
    queryKey: ["getAppointmentDetail", id],
    queryFn: () => getAppointmentDetail(user?.token, id),
    enabled: !!id,
    // staleTime: Infinity, // Keep the data fresh indefinitely
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false, // Don't refetch on component mount
  });
  // const columns = [
  //   {
  //     title: "ID",
  //     dataIndex: "id",
  //     key: "id",
  //   },
  //   {
  //     title: "UID",
  //     dataIndex: "uid",
  //     key: "uid",
  //   },
  //   {
  //     title: "Code",
  //     dataIndex: "code",
  //     key: "code",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Visit Type",
  //     dataIndex: "visit_type",
  //     key: "visit_type",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Visit Date",
  //     dataIndex: "visit_date",
  //     key: "visit_date",
  //     render: (date) => (date ? dayjs(date).format("MMMM DD, YYYY") : "Empty"),
  //   },
  //   {
  //     title: "Duration",
  //     dataIndex: "duration",
  //     key: "duration",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Active",
  //     dataIndex: "active",
  //     key: "active",
  //     render: (active) => (
  //       <span
  //         className={
  //           active ? "custom-badge status-green" : "custom-badge status-pink"
  //         }
  //       >
  //         {active ? "True" : "False"}
  //       </span>
  //     ),
  //   },
  //   {
  //     title: "Status",
  //     dataIndex: "status",
  //     key: "status",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Description",
  //     dataIndex: "description",
  //     key: "description",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Rescheduled At",
  //     dataIndex: "rescheduled_at",
  //     key: "rescheduled_at",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Appointment Slot",
  //     dataIndex: ["appointment_slot", "id"],
  //     key: "appointment_slot",
  //     render: (id) => id || "waiting",
  //   },
  //   {
  //     title: "Department",
  //     dataIndex: "department_id",
  //     key: "department_id",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Consultant",
  //     dataIndex: ["consultant", "id"],
  //     key: "consultant",
  //     render: (id) => id || "Empty",
  //   },
  //   {
  //     title: "Patient",
  //     dataIndex: ["patient", "id"],
  //     key: "patient",
  //     render: (id) => id || "Empty",
  //   },
  //   {
  //     title: "Created At",
  //     dataIndex: ["patient", "created_at"],
  //     key: "created_at",
  //     render: (date) =>
  //       date ? dayjs(date).format("MMMM DD, YYYY HH:mm") : "Empty",
  //   },
  //   {
  //     title: "Updated At",
  //     dataIndex: ["patient", "updated_at"],
  //     key: "updated_at",
  //     render: (date) =>
  //       date ? dayjs(date).format("MMMM DD, YYYY HH:mm") : "Empty",
  //   },
  //   {
  //     title: "Comments",
  //     dataIndex: "comments",
  //     key: "comments",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Referrer",
  //     dataIndex: ["referrer", "id"],
  //     key: "referrer",
  //     render: (id) => id || "Empty",
  //   },
  //   {
  //     title: "Created By",
  //     dataIndex: "created_by_id",
  //     key: "created_by_id",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Investigations",
  //     dataIndex: "investigations",
  //     key: "investigations",
  //     render: (investigations) =>
  //       investigations ? investigations.join(", ") : "Empty",
  //   },
  //   {
  //     title: "Height",
  //     dataIndex: "height",
  //     key: "height",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Weight",
  //     dataIndex: "weight",
  //     key: "weight",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Fever",
  //     dataIndex: "fever",
  //     key: "fever",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "BP S",
  //     dataIndex: "bp_s",
  //     key: "bp_s",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "BP D",
  //     dataIndex: "bp_d",
  //     key: "bp_d",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "BMI",
  //     dataIndex: "bmi",
  //     key: "bmi",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Visited At",
  //     dataIndex: "visited_at",
  //     key: "visited_at",
  //     render: (date) =>
  //       date ? dayjs(date).format("MMMM DD, YYYY HH:mm") : "Empty",
  //   },
  //   {
  //     title: "Alcohol",
  //     dataIndex: "alcohol",
  //     key: "alcohol",
  //     render: (alcohol) => (alcohol ? "Yes" : "No"),
  //   },
  //   {
  //     title: "Tobacco",
  //     dataIndex: "tobacco",
  //     key: "tobacco",
  //     render: (tobacco) => (tobacco ? "Yes" : "No"),
  //   },
  //   {
  //     title: "Smoking",
  //     dataIndex: "smoking",
  //     key: "smoking",
  //     render: (smoking) => (smoking ? "Yes" : "No"),
  //   },
  //   {
  //     title: "State",
  //     dataIndex: "State",
  //     key: "State",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Pinned Sequence",
  //     dataIndex: "pinned_sequence",
  //     key: "pinned_sequence",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Seen By",
  //     dataIndex: "seen_by_id",
  //     key: "seen_by_id",
  //     render: (text) => text || "Empty",
  //   },
  // ];
  // const columnsPrep = [
  //   { title: "ID", dataIndex: ["prescription", "id"], key: "id" },
  //   {
  //     title: "Notes",
  //     dataIndex: ["prescription", "notes"],
  //     key: "notes",
  //     render: (text) => text || "Empty",
  //   },
  //   { title: "Phone", dataIndex: ["prescription", "phone"], key: "phone" },
  //   {
  //     title: "Follow Up",
  //     dataIndex: ["prescription", "followup"],
  //     key: "followup",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Referred By",
  //     dataIndex: ["prescription", "referred_by"],
  //     key: "referred_by",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Additional Tests",
  //     dataIndex: ["prescription", "additional_tests"],
  //     key: "additional_tests",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Diagnosis",
  //     dataIndex: ["prescription", "diagnosis"],
  //     key: "diagnosis",
  //     render: (text) => text?.join(", ") || "Empty",
  //   },
  //   {
  //     title: "Self Notes",
  //     dataIndex: ["prescription", "self_notes"],
  //     key: "self_notes",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Medical Tests",
  //     dataIndex: ["prescription", "medical_tests"],
  //     key: "medical_tests",
  //     render: (text) => text?.join(", ") || "Empty",
  //   },
  //   {
  //     title: "Special Instructions",
  //     dataIndex: ["prescription", "special_instructions"],
  //     key: "special_instructions",
  //     render: (text) => text?.join(", ") || "Empty",
  //   },

  //   {
  //     title: "Daily Exercise",
  //     dataIndex: ["prescription", "daily_exercise"],
  //     key: "daily_exercise",
  //     render: (text) => (text ? "Yes" : "No") || "Empty",
  //   },
  //   {
  //     title: "Dietitian",
  //     dataIndex: ["prescription", "dietitian"],
  //     key: "dietitian",
  //     render: (text) => (text ? "Yes" : "No"),
  //   },
  //   {
  //     title: "Physiotherapist",
  //     dataIndex: ["prescription", "physiotherapist"],
  //     key: "physiotherapist",
  //     render: (text) => (text ? "Yes" : "No") || "Empty",
  //   },
  //   {
  //     title: "Psychologist",
  //     dataIndex: ["prescription", "psychologist"],
  //     key: "psychologist",
  //     render: (text) => (text ? "Yes" : "No") || "Empty",
  //   },
  //   {
  //     title: "Appointment",
  //     dataIndex: ["prescription", "appointment_id"],
  //     key: "appointment_id",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Treatment",
  //     dataIndex: ["prescription", "treatment_id"],
  //     key: "treatment_id",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Patient",
  //     dataIndex: ["prescription", "patient_id"],
  //     key: "patient_id",
  //     render: (text) => text || "Empty",
  //   },
  //   {
  //     title: "Created At",
  //     dataIndex: ["prescription", "created_at"],
  //     key: "created_at",
  //     render: (text) =>
  //       text
  //         ? dayjs(text, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")
  //         : "Empty",
  //   },
  //   {
  //     title: "Updated At",
  //     dataIndex: ["prescription", "updated_at"],
  //     key: "updated_at",
  //     render: (text) =>
  //       text
  //         ? dayjs(text, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")
  //         : "Empty",
  //   },
  //   {
  //     title: "Videos",
  //     dataIndex: ["prescription", "videos"],
  //     key: "videos",
  //     render: (text) => text?.join(", ") || "Empty",
  //   },
  //   {
  //     title: "Medicines",
  //     key: "medicines",
  //     render: (text, record) =>
  //       record.prescription?.medicines?.list?.length > 0 ? (
  //         <div className="table-responsive">
  //           <Table
  //             style={{
  //               boxShadow: "none !important",
  //               border: "none !important",
  //               backgroundColor: "transparent !important",
  //               outline: "none !important",
  //             }}
  //             dataSource={record.prescription.medicines.list}
  //             columns={[
  //               {
  //                 title: "Name",
  //                 dataIndex: "name",
  //                 key: "name",
  //                 render: (text) => (
  //                   <span
  //                     style={{
  //                       whiteSpace: "normal",
  //                       wordBreak: "break-word",
  //                       maxWidth: "350px",
  //                       display: "block",
  //                       overflow: "hidden",
  //                       textOverflow: "ellipsis",
  //                     }}
  //                   >
  //                     {text}
  //                   </span>
  //                 ),
  //               },
  //               {
  //                 title: "Remark",
  //                 dataIndex: "remark",
  //                 key: "remark",
  //               },
  //               {
  //                 title: "Description",
  //                 dataIndex: "description",
  //                 key: "description",
  //               },
  //             ]}
  //             pagination={false} // Disable pagination for the inner table
  //             rowKey="medicine_id" // Ensure each row has a unique key
  //             size="small" // Use small table size for a compact look
  //           />
  //         </div>
  //       ) : (
  //         "Empty"
  //       ),
  //   },
  // ];

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="appoinment-list"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={-1}>Appointment Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Appointment</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">ID:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.id || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Uid:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.uid || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Code:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.code || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Visit Type:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.visit_type ||
                                      "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Visit Date:</span>{" "}
                                  <span className="text">
                                    {dayjs(
                                      getAppointmentDetailData?.visit_date,
                                      "YYYY-MM-DD"
                                    ).format("MMMM DD, YYYY") || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Duration:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.duration ||
                                      "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Active:</span>{" "}
                                  <span
                                    className={
                                      getAppointmentDetailData?.active
                                        ? "custom-badge status-green"
                                        : "custom-badge status-pink"
                                    }
                                  >
                                    {getAppointmentDetailData?.active
                                      ? "True"
                                      : "False"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Status:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.status ||
                                      "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Description:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.description ||
                                      "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Rescheduled At:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.rescheduled_at ||
                                      "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">
                                    Appointment Slot:
                                  </span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.appointment_slot
                                      ?.id || "Waiting"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Department:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.department_id ||
                                      "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Consultant:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.consultant?.id ||
                                      "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Patient:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.patient?.id ||
                                      "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.patient
                                      ?.created_at
                                      ? dayjs(
                                          getAppointmentDetailData?.patient
                                            ?.created_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Updated At:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.patient
                                      ?.updated_at
                                      ? dayjs(
                                          getAppointmentDetailData?.patient
                                            ?.updated_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Comments:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.comments ||
                                      "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Referrer:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.referrer?.id ||
                                      "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created By:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.created_by_id ||
                                      "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Investigations:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.investigations?.join(
                                      ", "
                                    ) || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Height:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.height ||
                                      "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Weight:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.weight ||
                                      "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Fever:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.fever || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Bp S:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.bp_s || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Bp D:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.bp_d || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Bmi:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.bmi || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Visited At:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.visited_at
                                      ? dayjs(
                                          getAppointmentDetailData?.visited_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Alcohol:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.alcohol
                                      ? "Yes"
                                      : "No"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Tobacco:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.tobacco
                                      ? "Yes"
                                      : "No"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Smoking:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.smoking
                                      ? "Yes"
                                      : "No"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">State:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.State || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">
                                    Pinned Sequence:
                                  </span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.pinned_sequence ||
                                      "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Seen By:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.seen_by_id ||
                                      "Empty"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-6">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Prescription</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">ID:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription
                                      ?.id || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Notes:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription
                                      ?.notes || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Phone:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription
                                      ?.phone || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Follow Up:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription
                                      ?.followup || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Referred By:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription
                                      ?.referred_by || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">
                                    Additional Tests:
                                  </span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription?.additional_tests?.join(
                                      ","
                                    ) || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Diagnosis:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription?.diagnosis?.join(
                                      ", "
                                    ) || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Self Notes:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription
                                      ?.self_notes || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Medical Tests:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription?.medical_tests?.join(
                                      ", "
                                    ) || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">
                                    Special Instructions:
                                  </span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription?.special_instructions?.join(
                                      ", "
                                    ) || "Empty"}
                                  </span>
                                </li>

                                <li className="pb-1">
                                  <span className="title">Daily Exercise:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription
                                      ?.daily_exercise
                                      ? "Yes"
                                      : "No"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Dietitian:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription
                                      ?.dietitian
                                      ? "Yes"
                                      : "No"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">
                                    Physiotherapist:
                                  </span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription
                                      ?.physiotherapist
                                      ? "Yes"
                                      : "No"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Psychologist:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription
                                      ?.psychologist
                                      ? "Yes"
                                      : "No"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Appointment:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription
                                      ?.appointment_id || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Treatment:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription
                                      ?.treatment_id || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Patient:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription
                                      ?.patient_id || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription
                                      ?.created_at
                                      ? dayjs(
                                          getAppointmentDetailData.prescription
                                            .created_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Updated At:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription
                                      ?.updated_at
                                      ? dayjs(
                                          getAppointmentDetailData.prescription
                                            .updated_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Videos:</span>{" "}
                                  <span className="text">
                                    {getAppointmentDetailData?.prescription?.videos?.join(
                                      ", "
                                    ) || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Medicines:</span>
                                  <span className="text">
                                    <ul className="personal-info">
                                      {getAppointmentDetailData?.prescription
                                        ?.medicines?.list?.length > 0
                                        ? getAppointmentDetailData.prescription.medicines.list.map(
                                            (medicine) => (
                                              <li
                                                key={medicine.medicine_id}
                                                className="pb-1"
                                              >
                                                <span className="title">
                                                  Name:
                                                </span>{" "}
                                                <span className="text">
                                                  {medicine.name || "Empty"}
                                                </span>
                                                <br />
                                                <span className="title">
                                                  Remark:
                                                </span>{" "}
                                                <span className="text">
                                                  {medicine.remark || "Empty"}
                                                </span>
                                                <br />
                                                <span className="title">
                                                  Description:
                                                </span>{" "}
                                                <span className="text">
                                                  {medicine.description ||
                                                    "Empty"}
                                                </span>
                                              </li>
                                            )
                                          )
                                        : "Empty"}
                                    </ul>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default AppointmentDetail;
