import React, { useState } from "react";
import { Dropdown, Menu, Table } from "antd";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import {
  imagesend,
  pdficon,
  pdficon3,
  plusicon,
  refreshicon,
  pdficon5,
} from "../../imagepath";
// import { onShowSizeChange, itemRender } from "../Pagination";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { generateCsv, handleShowJson } from "../../../utils/constant/constant";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import { getSpecialInstruction } from "../../../connections/getSpecialInstruction";
import { deleteSpecialInstruction } from "../../../connections/deleteSpecialInstruction";

const SpecialInstruction = () => {
  const [deleteId, setDeleteId] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const {
    user: { user },
  } = useSelector((store) => store);
  const navigate = useNavigate();
  const {
    data: getSpeInsData,
    isLoading: sepInstLoader,
    refetch: sepInstRefetch,
  } = useQuery({
    queryKey: ["getSpecialInstruction", page, pageSize],
    queryFn: () => getSpecialInstruction(user?.token, null, page, pageSize),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Value",
      dataIndex: "value",
    },
    {
      title: "Doctor",
      dataIndex: "doctor_id",
    },
    {
      title: "Treatment",
      dataIndex: "treatment",
      render: (treatment) => (
        <Link
          to={`/admin/prescription/treatments/${treatment?.id}`}
          style={{ textDecoration: "underline" }}
        >
          {treatment?.name}
        </Link>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: (date) => (
        <>
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      render: (date) => (
        <>
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "FIELD8",
    //   render: (_, record) => (
    //     <>
    //       <div className="text-end">
    //         <div className="dropdown dropdown-action">
    //           <Link
    //             to="#"
    //             className="action-icon dropdown-toggle"
    //             data-bs-toggle="dropdown"
    //             aria-expanded="false"
    //           >
    //             <i className="fas fa-ellipsis-v" />
    //           </Link>
    //           <div className="dropdown-menu dropdown-menu-end">
    //             <Link
    //               className="dropdown-item"
    //               to={`/admin/prescription/special_instructions/${record?.id}`}
    //             >
    //               <i className="fa-regular fa-eye m-r-5" />
    //               View
    //             </Link>
    //             <button
    //               className="dropdown-item"
    //               onClick={() =>
    //                 navigate(
    //                   "/admin/prescription/update-special_instructions",
    //                   {
    //                     state: record,
    //                   }
    //                 )
    //               }
    //               // to={{
    //               //   pathname: "/admin/update-suppliers",
    //               //   state: { supplier: record },
    //               //   data: "ddddddddddddd",
    //               // }}
    //             >
    //               <i className="far fa-edit me-2" />
    //               Edit
    //             </button>
    //             <Link
    //               className="dropdown-item"
    //               to="#"
    //               data-bs-toggle="modal"
    //               data-bs-target="#delete_patient"
    //               onClick={() => {
    //                 setDeleteId(record?.id);
    //               }}
    //             >
    //               <i className="fa fa-trash-alt m-r-5"></i> Delete
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="view">
                <Link
                  to={`/admin/prescription/special_instructions/${record?.id}`}
                >
                  <i className="fa-regular fa-eye m-r-5" /> View
                </Link>
              </Menu.Item>
              <Menu.Item
                key="edit"
                onClick={() =>
                  navigate("/admin/prescription/update-special_instructions", {
                    state: record,
                  })
                }
              >
                <i className="far fa-edit me-2" /> Edit
              </Menu.Item>
              <Menu.Item
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete_patient"
                key="delete"
                onClick={() => {
                  setDeleteId(record?.id);
                }}
              >
                <i className="fa fa-trash-alt m-r-5" /> Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <Link
            to="#"
            style={{ marginRight: 5 }}
            className="action-icon"
            // data-bs-toggle="dropdown"
            // aria-expanded="false"
          >
            <i className="fas fa-ellipsis-v" />
          </Link>
        </Dropdown>
      ),
    },
  ];
  const handleDeleteSpecialInstructions = async () => {
    try {
      const data = await deleteSpecialInstruction(user?.token, deleteId);
      if (data.status.status === "SUCCESS") {
        toast.success("Medicine Description Deleted Successfully", deleteId);
        sepInstRefetch();
        setDeleteId(null);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
    // sepInstLoader();
  };
  const downloadPdf = (patients) => {
    // Return if no data
    if (!patients || patients.length === 0) return;

    // Initialize PDF document
    const doc = new jsPDF({
      unit: "mm",
      format: "a4",
    });

    // Define table headers with custom widths
    const headers = [
      { header: "Id", width: 15 },
      { header: "Code", width: 30 },
      { header: "Value", width: 30 },
      { header: "Doctor", width: 10 },
      { header: "Treatment", width: 30 },
      { header: "Created At", width: 35 },
      { header: "Updated At", width: 35 },
    ];

    // Map data with formatting
    const data = patients?.map((item) => [
      item.id,
      item.code,
      item.value,
      item.doctor_id,
      item.treatment?.name,
      item.created_at,
      item.updated_at,
    ]);

    // Add title
    doc.setFontSize(16);
    doc.setFont(undefined, "bold");
    doc.text(
      "Special Instruction List",
      doc.internal.pageSize.getWidth() / 2,
      15,
      {
        align: "center",
      }
    );

    // Add timestamp
    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

    // Configure table
    doc.autoTable({
      startY: 30,
      head: [headers.map((h) => h.header)],
      body: data,
      theme: "grid",
      headStyles: {
        fillColor: [240, 240, 240],
        textColor: [0, 0, 0],
        fontStyle: "bold",
        halign: "center",
        lineWidth: 0.5,
        lineColor: [200, 200, 200],
        fontSize: 8,
      },
      bodyStyles: {
        fontSize: 6,
        lineColor: [200, 200, 200],
        lineWidth: 0.5,
      },
      alternateRowStyles: {
        fillColor: [248, 248, 248],
      },
      columnStyles: headers.reduce((acc, h, i) => {
        acc[i] = { cellWidth: h.width };
        return acc;
      }, {}),
      margin: { top: 30, right: 14, bottom: 20, left: 14 },
      didDrawPage: (data) => {
        // Add page number at the bottom
        doc.setFontSize(10);
        doc.text(
          `Page ${data.pageNumber} of ${data.pageCount}`,
          doc.internal.pageSize.getWidth() / 2,
          doc.internal.pageSize.getHeight() - 10,
          { align: "center" }
        );
      },
    });

    // Save with formatted filename including timestamp
    const filename = `Special-Instruction-List${dayjs().format(
      "YYYYMMDD_HHmmss"
    )}.pdf`;
    doc.save(filename);
  };

  return (
    <>
      <Header />
      <Sidebar activeClassName="special_instructions-dashboard" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Special Instruction</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Special Instruction List
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Special Instruction List</h3>
                            <div className="doctor-search-blk">
                              <div className="add-group">
                                <Link
                                  to="/admin/prescription/add-special_instructions"
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link>
                                <Link
                                  onClick={sepInstRefetch}
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={refreshicon} alt="#" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end float-end ms-auto download-grp">
                          {/* <Link id="download-pdf" to="#" className=" me-2"> */}
                          <img
                            className=" me-2"
                            onClick={() =>
                              downloadPdf(
                                getSpeInsData?.payload?.special_instructions
                              )
                            }
                            src={pdficon}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                          {/* <Link
                           
                            id="download-csv"
                            className=" me-2"
                          > */}
                          <img
                            onClick={() =>
                              generateCsv(
                                getSpeInsData?.payload?.special_instructions
                              )
                            }
                            className=" me-2"
                            src={pdficon3}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                          {/* <Link id="show-json" to="#"> */}
                          <img
                            onClick={() =>
                              handleShowJson(
                                getSpeInsData?.payload?.special_instructions
                              )
                            }
                            width={"25px"}
                            height={"26px"}
                            src={pdficon5}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        loading={sepInstLoader}
                        pagination={{
                          current: page,
                          pageSize: pageSize,
                          total: getSpeInsData?.meta?.total_count,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onChange: handlePageChange,
                        }}
                        columns={columns}
                        dataSource={
                          getSpeInsData?.payload?.special_instructions
                        }
                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                        scroll={{ x: "max-content" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="delete_patient"
          className="modal fade delete-modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={imagesend} alt="#" width={50} height={46} />
                <h3>Are you sure want to delete this ?</h3>
                <div className="m-t-20">
                  {" "}
                  <Link
                    to="#"
                    className="btn btn-white me-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </Link>
                  <button
                    onClick={handleDeleteSpecialInstructions}
                    type="submit"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button
                      data-bs-dismiss="modal"
                      type="submit"
                      className="btn btn-danger"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
export default SpecialInstruction;
