import React, { useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";

import {
  imagesend,
  pdficon,
  pdficon3,
  plusicon,
  refreshicon,
  pdficon5,
} from "../imagepath";
import dayjs from "dayjs";

import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { generateCsv, handleShowJson } from "../../utils/constant/constant";
import { toast } from "react-toastify";
import { getStaffList } from "../../connections/getStaffList";
import { deleteStaff } from "../../connections/deleteStaff";
import jsPDF from "jspdf";

const StaffList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteId, setDeleteId] = useState(null);

  const navigate = useNavigate();
  const {
    user: { user },
  } = useSelector((store) => store);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const {
    data: getStaffListData,
    isLoading: staffLoader,
    refetch: refetchStaff,
  } = useQuery({
    queryKey: ["getStaffList", page, pageSize],
    queryFn: () => getStaffList(user?.token, null, page, pageSize),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Uid",
      dataIndex: "uid",
      render: (text, record) => (
        <>
          {text}
          <div style={{ color: "green" }}>
            Username : {record?.account_uid ? record?.account_uid : "Empty"}
          </div>
        </>
      ),
    },
    {
      title: "First Name",
      dataIndex: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
    },
    {
      title: "Active",
      dataIndex: "active",
      render: (text) => (
        <div>
          {text === true && (
            <span className="custom-badge status-green">{"Yes"}</span>
          )}
          {text === false && (
            <span className="custom-badge status-pink">{"NO"}</span>
          )}
          {text === null && (
            <span className="custom-badge status-pink">{"NO"}</span>
          )}
        </div>
      ),
    },
    {
      title: "Roles",
      dataIndex: "roles",
      render: (role) => (
        <span
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            maxWidth: "250px",
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {role?.length > 0 &&
            role.map((el, idx) => (
              <span key={idx}>
                {el?.name}
                {idx < role.length - 1 && ","}
              </span>
            ))}
        </span>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      //   sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,
      render: (date) => (
        <>
          {" "}
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      //   sorter: (a, b) => a.Treatment.length - b.Treatment.length,
      render: (date) => (
        <>
          {" "}
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (_, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  className="dropdown-item"
                  to={`/admin/staff_list/${record?.id}`}
                >
                  <i className="fa-regular fa-eye m-r-5" />
                  View
                </Link>
                <button
                  className="dropdown-item"
                  onClick={() =>
                    navigate("/admin/edit_staff", { state: record })
                  }
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </button>
                <Link
                  className="dropdown-item"
                  onClick={() => setDeleteId(record.id)}
                  data-bs-toggle="modal"
                  data-bs-target="#delete_patient"
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];
  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
    // refetchSuppliersList();
  };
  const handleDeleteStaff = async (e) => {
    e.preventDefault();

    try {
      const data = await deleteStaff(user?.token, deleteId);
      if (data.status.status === "SUCCESS") {
        toast.success("Staff Deleted Successfully");
        refetchStaff();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const downloadPdf = (patients) => {
    // Return if no data
    if (!patients || patients.length === 0) return;

    // Initialize PDF document
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
    });

    // Define table headers with custom widths
    const headers = [
      { header: "Id", width: 15 },
      { header: "UID", width: 30 },
      { header: "First Name", width: 25 },
      { header: "Last Name", width: 25 },
      { header: "Email", width: 40 },
      { header: "Mobile", width: 30 },
      { header: "Roles", width: 70 },
      { header: "Created At", width: 40 },
      { header: "Updated At", width: 40 },
    ];

    // Map data with formatting
    const data = patients?.map((item) => [
      item.id,
      item.uid || "-",
      item.first_name || "-",
      item.last_name || "-",
      item.email || "-",
      item.mobile || "-",
      item.roles?.join(", ") || "-",
      item.created_at || "-",
      item.updated_at || "-",
    ]);

    // Add title
    doc.setFontSize(16);
    doc.setFont(undefined, "bold");
    doc.text("Staff   List", doc.internal.pageSize.getWidth() / 2, 15, {
      align: "center",
    });

    // Add timestamp
    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

    // Configure table
    doc.autoTable({
      startY: 30,
      head: [headers.map((h) => h.header)],
      body: data,
      theme: "grid",
      headStyles: {
        fillColor: [240, 240, 240],
        textColor: [0, 0, 0],
        fontStyle: "bold",
        halign: "center",
        lineWidth: 0.5,
        lineColor: [200, 200, 200],
        fontSize: 10,
      },
      bodyStyles: {
        fontSize: 9,
        lineColor: [200, 200, 200],
        lineWidth: 0.5,
      },

      alternateRowStyles: {
        fillColor: [248, 248, 248],
      },
      margin: { top: 30, right: 14, bottom: 20, left: 14 },
      didDrawPage: (data) => {
        // Add page number at the bottom
        doc.setFontSize(10);
        doc.text(
          `Page ${data.pageNumber} of ${data.pageCount}`,
          doc.internal.pageSize.getWidth() / 2,
          doc.internal.pageSize.getHeight() - 10,
          { align: "center" }
        );
      },
    });

    // Save with formatted filename including timestamp
    const filename = `staff-List${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
    doc.save(filename);
  };

  return (
    <>
      <Header />
      <Sidebar activeClassName="staff-list" />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Staff </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">Staff List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Staff List</h3>
                          <div className="doctor-search-blk">
                            <div className="add-group">
                              <Link
                                to="/admin/add_staff"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                onClick={refetchStaff}
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        {/* <Link id="download-pdf" to="#" className=" me-2"> */}
                        <img
                          className=" me-2"
                          onClick={() =>
                            downloadPdf(
                              getStaffListData?.payload?.backops_users
                            )
                          }
                          src={pdficon}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                        {/* <Link
                           
                            id="download-csv"
                            className=" me-2"
                          > */}
                        <img
                          onClick={() =>
                            generateCsv(
                              getStaffListData?.payload?.backops_users
                            )
                          }
                          className=" me-2"
                          src={pdficon3}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                        {/* <Link id="show-json" to="#"> */}
                        <img
                          onClick={() =>
                            handleShowJson(
                              getStaffListData?.payload?.backops_users
                            )
                          }
                          width={"25px"}
                          height={"26px"}
                          src={pdficon5}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      loading={staffLoader}
                      pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: getStaffListData?.meta?.total_count,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        onChange: handlePageChange,
                      }}
                      columns={columns}
                      dataSource={getStaffListData?.payload?.backops_users}
                      rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                      scroll={{ x: "max-content" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="notification-box">
          <div className="msg-sidebar notifications msg-noti">
            <div className="topnav-dropdown-header">
              <span>Messages</span>
            </div>
            <div className="drop-scroll msg-list-scroll" id="msg_list">
              <ul className="list-box">
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">R</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Richard Miles </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item new-message">
                      <div className="list-left">
                        <span className="avatar">J</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">John Doe</span>
                        <span className="message-time">1 Aug</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">T</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Tarah Shropshire{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">M</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Mike Litorus</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">C</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Catherine Manseau{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">D</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Domenic Houston{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">B</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Buster Wigton </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">R</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Rolland Webber </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">C</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Claire Mapes </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">M</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Melita Faucher</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">J</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Jeffery Lalor</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">L</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Loren Gatlin</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">T</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Tarah Shropshire</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="topnav-dropdown-footer">
              <Link to="#">See all messages</Link>
            </div>
          </div>
        </div>
      </div>
      <div
        id="delete_patient"
        className="modal fade delete-modal"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src={imagesend} alt="#" width={50} height={46} />
              <h3>Are you sure want to delete this ?</h3>
              <div className="m-t-20">
                {" "}
                <Link
                  to="#"
                  className="btn btn-white me-2"
                  data-bs-dismiss="modal"
                >
                  Close
                </Link>
                <button
                  onClick={handleDeleteStaff}
                  type="submit"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffList;
