import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { putOrgSettings } from "../../connections/putOrgSettings";
import { getOrg } from "../../connections/getOrg";
import useDebounce from "../../utils/hooks";
const UpdateOrgSettings = () => {
  const [formState, setFormState] = useState({});
  const [pageType, setPageType] = useState([
    {
      value: "prescription",
      label: "Prescription ",
    },
    {
      value: "billing ",
      label: "Billing  ",
    },
    {
      value: "pharmacy",
      label: "Pharmacy",
    },
    {
      value: "lab",
      label: "Lab",
    },
  ]);
  const [inputValue, setInputValue] = useState("");

  // const [consumableCategoryOption, setConsumableCategoryOption] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const OrgSettingsData = location.state || null;
  const {
    user: { user },
  } = useSelector((store) => store);
  const { data: OrgListData } = useQuery({
    queryKey: ["getOrg", "all"],
    queryFn: () => getOrg(user?.token, "all", null, null),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const handleChange = (name, value) => {
    setFormState((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const options = OrgListData?.payload
    ? [
        {
          value: OrgListData.payload.id,
          label: OrgListData.payload.name,
        },
      ]
    : [];
  const debouncedInput = useDebounce(inputValue, 700);
  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      organization_setting: formState,
    };
    if (!formState?.organization_id) {
      toast.error("Organization name is required");
      return;
    }
    if (!formState?.review_duration) {
      toast.error("Review Duration is required");
      return;
    }
    // if (!formState?.prescription_header) {
    //   toast.error("Review Duration is required");
    //   return;
    // }
    try {
      const result = await putOrgSettings(
        user?.token,
        payload,
        OrgSettingsData?.id
      );
      if (result?.status?.status === "SUCCESS") {
        toast.success("Organization Settings Updated Successfully");
        setFormState({});
        navigate("/admin/organization_settings");
      }
    } catch (error) {
      // toast.error(error?.message);
      console.log(error.message);
    }
  };
  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      organization_id: OrgSettingsData?.organization?.id,
      review_duration: OrgSettingsData?.review_duration,
      header_height: OrgSettingsData?.header_height,
      is_active: OrgSettingsData?.is_active,
      page_type: OrgSettingsData?.page_type,
      footer_height: OrgSettingsData?.footer_height,
    }));
  }, [OrgSettingsData]);
  useEffect(() => {
    if (debouncedInput) {
      const optionExists = pageType.some(
        (item) => item.value === debouncedInput.toLowerCase()
      );

      if (!optionExists) {
        const newOption = {
          value: debouncedInput.toLowerCase(),
          label: debouncedInput,
        };
        setPageType((prevPack) => [...prevPack, newOption]);
      }
    }
  }, [debouncedInput, pageType]);
  return (
    <div>
      <Header />
      <Sidebar activeClassName="origination-settings-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Organization Settings </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Update Organization Settings
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page form */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Update Organization Settings</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Organization
                              <span className="login-danger">*</span>
                            </label>
                            <Select
                              required
                              value={
                                options?.find(
                                  (option) =>
                                    option.value === formState?.organization_id
                                ) || null
                              }
                              onChange={(event) =>
                                handleChange("organization_id", event.value)
                              }
                              placeholder="Organization name"
                              options={options}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-6">
                          <div className="form-group local-forms">
                            <label>Page Type</label>
                            <Select
                              value={
                                pageType?.find(
                                  (option) =>
                                    option.value === formState?.page_type
                                ) || null
                              }
                              onChange={(e) => {
                                handleChange("page_type", e?.value);
                              }}
                              onInputChange={handleInputChange}
                              options={pageType}
                              placeholder="select pack"
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Header height
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.header_height}
                              name="header_height"
                              placeholder="Height in inch"
                              onChange={(e) =>
                                handleChange("header_height", e.target.value)
                              }
                              className="form-control"
                              type="number"
                              // required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Footer height
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.footer_height}
                              name="footer_height"
                              placeholder="Height in inch"
                              onChange={(e) =>
                                handleChange("footer_height", e.target.value)
                              }
                              className="form-control"
                              type="number"
                              // required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Review duration
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.review_duration}
                              name="review_duration"
                              onChange={(e) =>
                                handleChange("review_duration", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                            // className="form-group local-forms"
                          >
                            <label>
                              Is Active
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.is_active || false}
                              checked={formState?.is_active}
                              name="unit"
                              onChange={(e) =>
                                handleChange("is_active", e.target.checked)
                              }
                              //   className="form-control"
                              type="checkbox"
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleSubmit}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/organization_settings");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default UpdateOrgSettings;
