import React, { useState } from "react";
import { Table } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Header";
import Sidebar from "../../Sidebar";

// import Invoice_HeaderContent from "./Invoice_HeaderContent";
import {
  imagesend,
  pdficon,
  pdficon3,
  pdficon5,
  plusicon,
  refreshicon,
  // searchnormal,
} from "../../imagepath";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getAllInvoices } from "../../../connections/getAllInvoices";
import dayjs from "dayjs";
import {
  generateCsv,
  handlePrintInvoice,
  handleShowJson,
} from "../../../utils/constant/constant";
import { deleteInvoice } from "../../../connections/deleteInvoice";
import { toast } from "react-toastify";
// import useDebounce from "../../../utils/hooks";

const InvoiceList = () => {
  const [invoiceId, setInvoiceId] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  // const [searchTerm, setSearchTerm] = useState("");
  const {
    user: { user },
  } = useSelector((store) => store);
  // const debouncedInputInvoice = useDebounce(searchTerm, 1000);

  // const params = {
  //   q: {
  //     uid_eq: debouncedInputInvoice,
  //     // patient_uid_in: debouncedInputInvoice,
  //     // generated_at_gteq: "2024-12-01",
  //     // generated_at_lteq: "2024-12-31",
  //     // department_id_eq: debouncedInputInvoice,
  //     // invoice_type_eq: debouncedInputInvoice,
  //   },
  // };
  const {
    data: getAllInvoicesData,
    isLoading: invoiceLoader,
    refetch: refetchInvoice,
  } = useQuery({
    queryKey: ["getAllInvoices", page, pageSize],
    queryFn: () => getAllInvoices(user?.token, null, page, pageSize),

    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const navigate = useNavigate();

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // const handleSearchInput = (e) => {
  //   e.preventDefault();
  //   setSearchTerm(e?.target?.value);
  // };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Patient",
      dataIndex: "patient",
      render: (_, record) => (
        <Link
          to={`/admin/patient/${record?.patient?.id}`}
          style={{ textDecoration: "underline" }}
        >
          {record?.patient?.full_name}
        </Link>
      ),
    },
    {
      title: "Invoice ID",
      dataIndex: "id",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Invoice Type",
      dataIndex: "invoice_type",
    },
    {
      title: "Total",
      dataIndex: "total",
    },
    {
      title: "Grand Total",
      dataIndex: "grand_total",
    },
    {
      title: "Generated At",
      dataIndex: "generated_at",
      render: (date) => (
        <>
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },

    {
      title: "Generated By",
      dataIndex: "generated_by",
    },
    {
      title: "Department",
      dataIndex: "department",
      render: (_, record) => (
        <Link
          to={`/department_details/${record?.department?.id}`}
          style={{ textDecoration: "underline" }}
        >
          {record?.department?.name}
        </Link>
        //    <Link >
        //    {text}
        //  </Link>
      ),
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (_, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  className="dropdown-item"
                  to={`/admin/invoice-details/${record?.id}`}
                >
                  <i className="fa-regular fa-eye m-r-5" />
                  View
                </Link>
                <button
                  className="dropdown-item"
                  onClick={() =>
                    navigate("/admin/edit-invoice", { state: record })
                  }
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </button>
                <Link
                  className="dropdown-item"
                  onClick={() => setInvoiceId(record?.id)}
                  data-bs-toggle="modal"
                  data-bs-target="#delete_patient"
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];
  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
  };
  const handleDeleteInvoice = async (e) => {
    e.preventDefault();
    const data = await deleteInvoice(user?.token, invoiceId);
    if (data.status.status === "SUCCESS") {
      toast.success("Invoice Delete Successfully");
      refetchInvoice();
    }
  };

  return (
    <>
      <Header />
      <Sidebar activeClassName="invoice-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/admin/invoice-list">Invoices </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Invoice List</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* <Invoice_HeaderContent /> */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table">
                  <div className="card-body p-4">
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Invoice List</h3>
                            {/* <div className="top-nav-search table-search-blk">
                              <span
                                style={{ width: "100%", position: "relative" }}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                  value={searchTerm}
                                  onChange={handleSearchInput}
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </span>
                            </div> */}
                            <div className="doctor-search-blk">
                              <div className="add-group">
                                <Link
                                  to="/admin/add-invoice"
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link>
                                <Link
                                  onClick={refetchInvoice}
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={refreshicon} alt="#" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end float-end ms-auto download-grp">
                          <img
                            className=" me-2"
                            onClick={() =>
                              handlePrintInvoice(
                                getAllInvoicesData?.payload?.invoices
                              )
                            }
                            src={pdficon}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />

                          <img
                            onClick={() =>
                              generateCsv(getAllInvoicesData?.payload?.invoices)
                            }
                            className=" me-2"
                            src={pdficon3}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />

                          <img
                            onClick={() =>
                              handleShowJson(
                                getAllInvoicesData?.payload?.invoices
                              )
                            }
                            width={"25px"}
                            height={"26px"}
                            src={pdficon5}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <Table
                        loading={invoiceLoader}
                        pagination={{
                          current: page,
                          pageSize: pageSize,
                          total: getAllInvoicesData?.meta?.total_count,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onChange: handlePageChange,
                        }}
                        columns={columns}
                        dataSource={getAllInvoicesData?.payload?.invoices}
                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                        scroll={{ x: "max-content" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button
                      onClick={handleDeleteInvoice}
                      type="submit"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default InvoiceList;
