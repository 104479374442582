import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getAdmissionDetails } from "../../connections/getAdmissionDetails";
import dayjs from "dayjs";
import { Skeleton } from "@mui/material";
// import { Table } from "antd";

const AdmissionDetails = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();
  const { data: getAdmissionDetailsData, isLoading } = useQuery({
    queryKey: ["getAdmissionDetails", id],
    queryFn: () => getAdmissionDetails(user?.token, id),
    enabled: !!id,
    // staleTime: Infinity, // Keep the data fresh indefinitely
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  // const columns = [
  //   {
  //     title: "Id",
  //     dataIndex: "id",
  //     key: "id",
  //   },
  //   {
  //     title: "Room No",
  //     dataIndex: "room_no",
  //     key: "room_no",
  //   },
  //   {
  //     title: "Check-In",
  //     dataIndex: "checkin",
  //     key: "checkin",
  //     render: (text) =>
  //       dayjs(text, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm"),
  //   },
  //   {
  //     title: "Check-Out",
  //     dataIndex: "checkout",
  //     key: "checkout",
  //     render: (text) =>
  //       dayjs(text, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm"),
  //   },
  //   {
  //     title: "Ward No",
  //     dataIndex: "ward_no",
  //     key: "ward_no",
  //   },
  //   {
  //     title: "Bed No",
  //     dataIndex: "bed_no",
  //     key: "bed_no",
  //   },
  //   {
  //     title: "Created At",
  //     dataIndex: "created_at",
  //     key: "created_at",
  //     render: (text) =>
  //       dayjs(text, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm"),
  //   },
  //   {
  //     title: "Updated At",
  //     dataIndex: "updated_at",
  //     key: "updated_at",
  //     render: (text) =>
  //       dayjs(text, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm"),
  //   },
  //   {
  //     title: "Patient Name",
  //     dataIndex: "patient_name",
  //     key: "patient_name",
  //     render: (text, record) => record.patient?.full_name,
  //   },
  // ];

  // const data = [
  //   {
  //     key: "1",
  //     room_no: getAdmissionDetailsData?.room_no,
  //     checkin: getAdmissionDetailsData?.checkin,
  //     checkout: getAdmissionDetailsData?.checkout,
  //     ward_no: getAdmissionDetailsData?.ward_no,
  //     bed_no: getAdmissionDetailsData?.bed_no,
  //     created_at: getAdmissionDetailsData?.created_at,
  //     updated_at: getAdmissionDetailsData?.updated_at,
  //     patient_name: getAdmissionDetailsData?.patient?.full_name,
  //   },
  // ];
  const formatDate = (date) =>
    dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm");
  return (
    <>
      <Header />
      <Sidebar activeClassName="admission-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={-1}>Admission Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Admission Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">Id:</span>
                                  <span className="text">
                                    {getAdmissionDetailsData?.id}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Room No:</span>
                                  <span className="text">
                                    {getAdmissionDetailsData?.room_no}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Check-In:</span>
                                  <span className="text">
                                    {formatDate(
                                      getAdmissionDetailsData?.checkin
                                    )}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Check-Out:</span>
                                  <span className="text">
                                    {formatDate(
                                      getAdmissionDetailsData?.checkout
                                    )}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Ward No:</span>
                                  <span className="text">
                                    {getAdmissionDetailsData?.ward_no}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Bed No:</span>
                                  <span className="text">
                                    {getAdmissionDetailsData?.bed_no}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>
                                  <span className="text">
                                    {formatDate(
                                      getAdmissionDetailsData?.created_at
                                    )}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Updated At:</span>
                                  <span className="text">
                                    {formatDate(
                                      getAdmissionDetailsData?.updated_at
                                    )}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Patient Name:</span>
                                  <span className="text">
                                    {
                                      getAdmissionDetailsData?.patient
                                        ?.full_name
                                    }
                                  </span>
                                </li>
                              </ul>
                              {/* <div className="table-responsive">
                                <Table
                                  columns={columns}
                                  dataSource={data}
                                  pagination={false}
                                  scroll={{ x: "max-content" }}
                                />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default AdmissionDetails;
