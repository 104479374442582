/* eslint-disable no-undef */
import React from "react";
// import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";

import {
  imagesend,
  pdficon,
  pdficon3,
  //   plusicon,
  refreshicon,
  pdficon5,
} from "../imagepath";
import dayjs from "dayjs";

import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { generateCsv, handleShowJson } from "../../utils/constant/constant";
import { getOrg } from "../../connections/getOrg";
import jsPDF from "jspdf";
import { BASE_URL } from "../../connections/connectionUrl";
import { Skeleton } from "antd";

const Organization = () => {
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  //   const [deleteId] = useState(null);

  const navigate = useNavigate();
  const {
    user: { user },
  } = useSelector((store) => store);
  // const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);

  const {
    data: getOrgData,
    isLoading: getOrgLoader,
    refetch: getOrgRefetch,
  } = useQuery({
    queryKey: ["getOrg"],
    queryFn: () => getOrg(user?.token),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  // const onSelectChange = (newSelectedRowKeys) => {
  //   console.log("selectedRowKeys changed: ", selectedRowKeys);
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  // const columns = [
  //   {
  //     title: "Id",
  //     dataIndex: "id",
  //   },
  //   {
  //     title: "Name",
  //     dataIndex: "name",
  //     // sorter: (a, b) => a.title.localeCompare(b.title),
  //   },
  //   {
  //     title: "Code",
  //     dataIndex: "code",
  //     // sorter: (a, b) => a.value.localeCompare(b.value),
  //   },
  //   {
  //     title: "Address",
  //     dataIndex: "address",
  //   },
  //   {
  //     title: "Pincode",
  //     dataIndex: "pincode",
  //   },
  //   {
  //     title: "City",
  //     dataIndex: "city",
  //   },
  //   {
  //     title: "State",
  //     dataIndex: "state",
  //   },
  //   {
  //     title: "Footer",
  //     dataIndex: "footer",
  //   },
  //   {
  //     title: "Email",
  //     dataIndex: "email",
  //   },
  //   {
  //     title: "Website",
  //     dataIndex: "website",
  //     render: (website) => (
  //       <Link
  //         href={website}
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         style={{ textDecoration: "underline" }}
  //       >
  //         {website}
  //       </Link>
  //     ),
  //   },
  //   {
  //     title: "Contact Number",
  //     dataIndex: "contact_number",
  //   },
  //   {
  //     title: "Logo",
  //     dataIndex: "logo",
  //     render: (logo) => (
  //       <>
  //         <a
  //           href={`${BASE_URL}${logo}`}
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           style={{ textDecoration: "underline", marginRight: "10px" }}
  //         >
  //           <span style={{ marginRight: "10px" }}>Logo</span>
  //           <i className="fa fa-external-link" aria-hidden="true"></i>
  //         </a>
  //       </>
  //     ),
  //   },
  //   {
  //     title: "Accreditation",
  //     dataIndex: "accreditation",
  //     render: (accreditation) => (
  //       <>
  //         <>
  //           <a
  //             href={`${BASE_URL}${accreditation}`}
  //             target="_blank"
  //             rel="noopener noreferrer"
  //             style={{ textDecoration: "underline" }}
  //           >
  //             <span style={{ marginRight: "10px" }}>Accreditation</span>
  //             <i className="fa fa-external-link" aria-hidden="true"></i>
  //           </a>
  //         </>
  //       </>
  //     ),
  //   },
  //   {
  //     title: "Created At",
  //     dataIndex: "created_at",
  //     //   sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,
  //     render: (date) => (
  //       <>
  //         {" "}
  //         {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
  //       </>
  //     ),
  //   },
  //   {
  //     title: "Updated At",
  //     dataIndex: "updated_at",
  //     //   sorter: (a, b) => a.Treatment.length - b.Treatment.length,
  //     render: (date) => (
  //       <>
  //         {" "}
  //         {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
  //       </>
  //     ),
  //   },
  //   {
  //     title: "",
  //     dataIndex: "",
  //     render: (_, record) => (
  //       <>
  //         <div className="text-end">
  //           <div className="dropdown dropdown-action">
  //             <button
  //               className="dropdown-item"
  //               onClick={() =>
  //                 navigate("/admin/update-organization", { state: record })
  //               }
  //             >
  //               <i className="fa-solid fa-pen-to-square"></i>
  //             </button>
  //           </div>
  //         </div>
  //       </>
  //     ),
  //   },
  // ];
  // const handlePageChange = (newPage, newPageSize) => {
  //   setPage(newPage);
  //   setPageSize(newPageSize);
  //   // refetchSuppliersList();
  // };
  //   const handleDeleteOccupation = async (e) => {
  //     e.preventDefault();

  //     try {
  //       const data = await deleteOccupation(user?.token, deleteId);
  //       if (data.status.status === "SUCCESS") {
  //         toast.success("Occupation Deleted Successfully");
  //         getOrgRefetch();
  //       }
  //     } catch (error) {
  //       toast.error(error.message);
  //     }
  //   };
  const downloadPdf = (patients) => {
    // Return if no data
    if (!patients || patients.length === 0) return;

    // const formatDate = (dateString) => {
    //   try {
    //     return dayjs(dateString, "YYYY-MM-DD HH:mm:ss A").format(
    //       "YYYY-MM-DD HH:mm"
    //     );
    //   } catch (e) {
    //     return dateString;
    //   }
    // };

    // Initialize PDF document
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
    });

    // Define table headers with custom widths
    const headers = [
      { header: "ID", dataKey: "id", width: 10 },
      { header: "Name", dataKey: "name", width: 40 },
      { header: "Code", dataKey: "code", width: 20 },
      { header: "Address", dataKey: "address", width: 40 },
      { header: "Pincode", dataKey: "pincode", width: 28 },
      { header: "City", dataKey: "city", width: 25 },
      { header: "State", dataKey: "state", width: 20 },
      { header: "Website", dataKey: "website", width: 40 },
      { header: "Contact Number", dataKey: "contact_number", width: 40 },
      //   { header: "Logo", dataKey: "logo_name", width: 25 },
      //   {
      //     header: "Accreditation",
      //     dataKey: "accreditation_name",
      //     width: 25,
      //   },
    ];

    // Map data with formatting
    const data = patients?.map((item) => [
      item.id,
      item.name,
      item.code,
      item.address,
      item.pincode,
      item.city,
      item.state,
      item.website,
      item.contact_number,
      //   item.logo?.record?.name || "",
      //   item.accreditation?.record?.name || "",
    ]);

    // Add title
    doc.setFontSize(18);
    doc.setFont(undefined, "bold");
    doc.text("Organization List", doc.internal.pageSize.getWidth() / 2, 15, {
      align: "center",
    });

    // Add timestamp
    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

    // Configure table
    doc.autoTable({
      startY: 30,
      head: [headers.map((h) => h.header)],
      body: data,
      theme: "grid",
      headStyles: {
        fillColor: [240, 240, 240],
        textColor: [0, 0, 0],
        fontStyle: "bold",
        halign: "center",
        lineWidth: 0.5,
        lineColor: [200, 200, 200],
        fontSize: 10,
      },
      bodyStyles: {
        fontSize: 12,
        lineColor: [200, 200, 200],
        lineWidth: 0.5,
      },
      alternateRowStyles: {
        fillColor: [248, 248, 248],
      },
      columnStyles: headers.reduce((acc, h, i) => {
        acc[i] = { cellWidth: h.width };
        return acc;
      }, {}),
      margin: { top: 30, right: 14, bottom: 20, left: 14 },
      didDrawPage: (data) => {
        // Add page number at the bottom
        doc.setFontSize(10);
        doc.text(
          `Page ${data.pageNumber} of ${data.pageCount}`,
          doc.internal.pageSize.getWidth() / 2,
          doc.internal.pageSize.getHeight() - 10,
          { align: "center" }
        );
      },
    });

    // Save with formatted filename including timestamp
    const filename = `Organization${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
    doc.save(filename);
  };

  return (
    <>
      <Header />
      <Sidebar activeClassName="origination--list" />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Organization </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">
                    Organization Details
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Organization Details</h3>
                          <div className="doctor-search-blk">
                            <div className="add-group">
                              <button
                                className="btn btn- d-flex align-items-center ms-2"
                                onClick={() =>
                                  navigate("/admin/update-organization", {
                                    state: getOrgData?.payload,
                                  })
                                }
                                style={{
                                  // borderRadius: "50%",
                                  width: "40px",
                                  height: "40px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "0",
                                }}
                                title="Edit"
                              >
                                <i
                                  className="fa-solid fa-pen-to-square"
                                  style={{ fontSize: "1.2rem" }}
                                ></i>
                              </button>
                              <Link
                                onClick={getOrgRefetch}
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        {/* <Link id="download-pdf" to="#" className=" me-2"> */}
                        <img
                          className=" me-2"
                          onClick={() => downloadPdf([getOrgData?.payload])}
                          src={pdficon}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                        {/* <Link
                           
                            id="download-csv"
                            className=" me-2"
                          > */}
                        <img
                          onClick={() => generateCsv(getOrgData?.payload)}
                          className=" me-2"
                          src={pdficon3}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                        {/* <Link id="show-json" to="#"> */}
                        <img
                          onClick={() => handleShowJson(getOrgData?.payload)}
                          width={"25px"}
                          height={"26px"}
                          src={pdficon5}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  {getOrgLoader ? (
                    <div>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={430} // Same height as your chart
                      />
                    </div>
                  ) : (
                    <div className="content-details">
                      <div className="col-lg-12">
                        <div className="card-box">
                          <div className="card-block">
                            <ul className="personal-info">
                              <li className="pb-1">
                                <span className="title">Id:</span>
                                <span className="text">
                                  {getOrgData?.payload?.id || "N/A"}
                                </span>
                              </li>
                              <li className="pb-1">
                                <span className="title">Name:</span>
                                <span className="text">
                                  {getOrgData?.payload?.name || "N/A"}
                                </span>
                              </li>
                              <li className="pb-1">
                                <span className="title">Code:</span>
                                <span className="text">
                                  {getOrgData?.payload?.code || "N/A"}
                                </span>
                              </li>
                              <li className="pb-1">
                                <span className="title">Address:</span>
                                <span className="text">
                                  {getOrgData?.payload?.address || "N/A"}
                                </span>
                              </li>
                              <li className="pb-1">
                                <span className="title">Pincode:</span>
                                <span className="text">
                                  {getOrgData?.payload?.pincode || "N/A"}
                                </span>
                              </li>
                              <li className="pb-1">
                                <span className="title">City:</span>
                                <span className="text">
                                  {getOrgData?.payload?.city || "N/A"}
                                </span>
                              </li>
                              <li className="pb-1">
                                <span className="title">State:</span>
                                <span className="text">
                                  {getOrgData?.payload?.state || "N/A"}
                                </span>
                              </li>
                              {/* <li className="pb-1">
                                <span className="title">Footer:</span>
                                <span className="text">
                                  {getOrgData?.payload?.footer || "N/A"}
                                </span>
                              </li> */}
                              <li className="pb-1">
                                <span className="title">Email:</span>
                                <span className="text">
                                  {getOrgData?.payload?.email || "N/A"}
                                </span>
                              </li>
                              <li className="pb-1">
                                <span className="title">Website:</span>
                                <span className="text">
                                  {getOrgData?.payload?.website ? (
                                    <Link
                                      href={getOrgData?.payload.website}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ textDecoration: "underline" }}
                                    >
                                      {getOrgData?.payload.website}
                                    </Link>
                                  ) : (
                                    "N/A"
                                  )}
                                </span>
                              </li>
                              <li className="pb-1">
                                <span className="title">Contact Number:</span>
                                <span className="text">
                                  {getOrgData?.payload?.contact_number || "N/A"}
                                </span>
                              </li>
                              <li className="pb-1">
                                <span className="title">Logo:</span>
                                <span className="text">
                                  {getOrgData?.payload?.logo ? (
                                    <img
                                      src={`${BASE_URL}${getOrgData?.payload.logo}`}
                                      alt="logo"
                                      // rel="noopener noreferrer"
                                      style={{ width: "200px", height: "65px" }}
                                    />
                                  ) : (
                                    "N/A"
                                  )}
                                </span>
                              </li>
                              <li className="pb-1">
                                <span className="title">Accreditation:</span>
                                <span className="text">
                                  {getOrgData?.payload?.accreditation ? (
                                    <img
                                      src={`${BASE_URL}${getOrgData?.payload.accreditation}`}
                                      target="accreditation"
                                      // rel="noopener noreferrer"
                                      style={{ width: "200px", height: "65px" }}
                                    />
                                  ) : (
                                    "N/A"
                                  )}
                                </span>
                              </li>
                              {/* <li className="pb-1">
                                <span className="title">Created At:</span>
                                <span className="text">
                                  {getOrgData?.payload?.created_at
                                    ? dayjs(
                                        getOrgData?.payload.created_at
                                      ).format("MMMM DD, YYYY HH:mm")
                                    : "N/A"}
                                </span>
                              </li> */}
                              {/* <li className="pb-1">
                                <span className="title">Updated At:</span>
                                <span className="text">
                                  {getOrgData?.payload?.updated_at
                                    ? dayjs(
                                        getOrgData?.payload.updated_at
                                      ).format("MMMM DD, YYYY HH:mm")
                                    : "N/A"}
                                </span>
                              </li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                      {/* <div className="table-responsive doctor-list">
                    <Table
                      loading={getOrgLoader}
                      pagination={false}
                      // pagination={{
                      //   current: page,
                      //   pageSize: pageSize,
                      //   total: getOrgData?.meta?.total_count,
                      //   showTotal: (total, range) =>
                      //     `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      //   onChange: handlePageChange,
                      // }}
                      columns={columns}
                      dataSource={[getOrgData?.payload]}
                      rowSelection={rowSelection}
                      //   rowKey={(record) => record.id}
                      scroll={{ x: "max-content" }}
                    /> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="delete_patient"
        className="modal fade delete-modal"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src={imagesend} alt="#" width={50} height={46} />
              <h3>Are you sure want to delete this ?</h3>
              <div className="m-t-20">
                {" "}
                <Link
                  to="#"
                  className="btn btn-white me-2"
                  data-bs-dismiss="modal"
                >
                  Close
                </Link>
                <button
                  //   onClick={handleDeleteOccupation}
                  type="submit"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Organization;
