import React, { useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import {
  imagesend,
  pdficon,
  pdficon3,
  //   plusicon,
  refreshicon,
  pdficon5,
} from "../imagepath";
// import { onShowSizeChange, itemRender } from "../Pagination";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import { generateCsv, handleShowJson } from "../../utils/constant/constant";
import { getPermission } from "../../connections/getPermission";
import dayjs from "dayjs";
import jsPDF from "jspdf";

const Permission = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const {
    user: { user },
  } = useSelector((store) => store);
  const {
    data: permissionListData,
    isLoading: permissionListLoader,
    refetch: permissionListRefetch,
  } = useQuery({
    queryKey: ["getPermission", page, pageSize],
    queryFn: () => getPermission(user?.token, null, page, pageSize),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Value",
      dataIndex: "value",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      //   sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,
      render: (date) => (
        <>
          {" "}
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      //   sorter: (a, b) => a.Treatment.length - b.Treatment.length,
      render: (date) => (
        <>
          {" "}
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (_, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link to={`/admin/permission/${record?.id}`}>
                <i className="fa-solid fa-eye"></i>
              </Link>
            </div>
          </div>
        </>
      ),
    },
  ];

  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
    // permissionListRefetch();
  };
  const downloadPdf = (patients) => {
    // Return if no data
    if (!patients || patients.length === 0) return;

    // Initialize PDF document
    const doc = new jsPDF({
      unit: "mm",
      format: "a4",
    });

    // Define table headers with custom widths
    const headers = [
      { header: "Id", width: 20 },
      { header: "Code", width: 40 },
      { header: "Value", width: 40 },
      { header: "CreatedA At", width: 50 },
      { header: "Updated At", width: 50 },
    ];

    // Map data with formatting
    const data = patients?.map((item) => [
      item?.id,
      item?.code,
      item.value,
      item?.created_at,
      item?.updated_at,
    ]);

    // Add title
    doc.setFontSize(14);
    doc.setFont(undefined, "bold");
    doc.text("Permission List", doc.internal.pageSize.getWidth() / 2, 15, {
      align: "center",
    });

    // Add timestamp
    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

    // Configure table
    doc.autoTable({
      startY: 30,
      head: [headers.map((h) => h.header)],
      body: data,
      theme: "grid",
      headStyles: {
        fillColor: [240, 240, 240],
        textColor: [0, 0, 0],
        fontStyle: "bold",
        align: "center",
        lineWidth: 0.5,
        lineColor: [200, 200, 200],
        fontSize: 8,
      },
      bodyStyles: {
        fontSize: 6,
        lineColor: [200, 200, 200],
        lineWidth: 0.5,
      },
      alternateRowStyles: {
        fillColor: [248, 248, 248],
      },
      columnStyles: headers.reduce((acc, h, i) => {
        acc[i] = { cellWidth: h.width };
        return acc;
      }, {}),
      margin: { top: 30, right: 14, bottom: 20, left: 14 },
      didDrawPage: (data) => {
        // Add page number at the bottom
        doc.setFontSize(10);
        doc.text(
          `Page ${data.pageNumber} of ${data.pageCount}`,
          doc.internal.pageSize.getWidth() / 2,
          doc.internal.pageSize.getHeight() - 10,
          { align: "center" }
        );
      },
    });

    // Save with formatted filename including timestamp
    const filename = `permission${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
    doc.save(filename);
  };
  return (
    <>
      <Header />
      <Sidebar activeClassName="permission-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Permission</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Permission List</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Permission List</h3>
                            <div className="doctor-search-blk">
                              <div className="add-group">
                                {/* <Link
                                  to="/admin/add-holidays"
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link> */}
                                <Link
                                  onClick={permissionListRefetch}
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img
                                    // onClick={permissionListRefetch}
                                    src={refreshicon}
                                    alt="#"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end float-end ms-auto download-grp">
                          {/* <Link id="download-pdf" to="#" className=" me-2"> */}
                          <img
                            className=" me-2"
                            onClick={() =>
                              downloadPdf(
                                permissionListData?.payload?.permissions
                              )
                            }
                            src={pdficon}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                          {/* <Link
                           
                            id="download-csv"
                            className=" me-2"
                          > */}
                          <img
                            onClick={() =>
                              generateCsv(
                                permissionListData?.payload?.permissions
                              )
                            }
                            className=" me-2"
                            src={pdficon3}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />

                          <img
                            onClick={() =>
                              handleShowJson(
                                permissionListData?.payload?.permissions
                              )
                            }
                            width={"25px"}
                            height={"26px"}
                            src={pdficon5}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        loading={permissionListLoader}
                        pagination={{
                          current: page,
                          pageSize: pageSize,
                          total: permissionListData?.meta?.total_count,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onChange: handlePageChange,
                        }}
                        columns={columns}
                        dataSource={permissionListData?.payload?.permissions}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="delete_patient"
          className="modal fade delete-modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={imagesend} alt="#" width={50} height={46} />
                <h3>Are you sure want to delete this ?</h3>
                <div className="m-t-20">
                  {" "}
                  <Link
                    to="#"
                    className="btn btn-white me-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </Link>
                  <button
                    // onClick={deleteHolidaysFun}
                    type="submit"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button
                      data-bs-dismiss="modal"
                      type="submit"
                      className="btn btn-danger"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      ;
    </>
  );
};

export default Permission;
