import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";
import { DatePicker } from "antd";
import { useQuery } from "@tanstack/react-query";
import { getPack } from "../../connections/getPack";
import { useSelector } from "react-redux";
import { postMedicine } from "../../connections/postMedicine";
import { toast } from "react-toastify";
import useDebounce from "../../utils/hooks";
import { getAllFormType } from "../../connections/getAllFormType";
import { getMedicineSalt } from "../../connections/getMedicineSalt";

const AddMedicine = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const navigate = useNavigate();
  const [medDetails, setMedDetails] = useState({});
  const [allMedSalt, setAllMedSalt] = useState([]);
  const [batches, setBatches] = useState([]);
  const [formType, setFormType] = useState([]);
  const [pack, setPack] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [medSaltInput, setMedSaltInput] = useState("");
  const { data: getPackData } = useQuery({
    queryKey: ["getPack"],
    queryFn: () => getPack(user?.token),
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const { data: getAllFormTypeData } = useQuery({
    queryKey: ["getAllFormType"],
    queryFn: () => getAllFormType(user?.token),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const debouncedInputSalt = useDebounce(medSaltInput, 700);
  const { data: getMedSalt } = useQuery({
    queryKey: ["getMedicineSalt", debouncedInputSalt],
    queryFn: () =>
      getMedicineSalt(user?.token, null, 1, 10, debouncedInputSalt),
    enabled: !!debouncedInputSalt,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const handleChangeMed = (field, val) => {
    setMedDetails((prev) => ({
      ...prev,
      [field]: val,
    }));
  };
  const handleChangeBatch = (index, field, value) => {
    const newMedBatch = [...batches];
    newMedBatch[index] = { ...newMedBatch[index], [field]: value };
    setBatches(newMedBatch);
  };
  const handleSearchMedSalt = (inputValue) => {
    setMedSaltInput(inputValue);
  };

  const formTypeOptions = formType.map((item) => ({
    value: item.value,
    label: item.value,
  }));
  // const options = pack?.map((item) => ({
  //   value: item.value,
  //   label: item.value,
  // }));
  const debouncedInput = useDebounce(inputValue, 700);
  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };
  const handleAddRow = (e) => {
    e.preventDefault();
    setBatches((prev) => [
      ...prev,
      {
        batch_no: "",
        expiry_date: "",
        amount: "",
        purchase_cost: "",
        form_type: "",
        packing: "",
        stock_left: "",
      },
    ]);
  };
  const handleRemoveRow = (e, idx) => {
    e.preventDefault();

    const newRow = [...batches];
    const updatedRow = newRow?.filter((_, index) => index !== idx);
    setBatches(updatedRow);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!medDetails?.name) {
      toast.error("Name is required");
      return;
    }
    const hasEmptyAmount = batches?.some((batch) => !batch.amount);

    if (hasEmptyAmount) {
      toast.error("Amount is required in all batches");
      return;
    }

    const payload = {
      medicine: {
        ...medDetails,
        ...(batches.length > 0 && {
          medicine_batches_attributes: batches,
        }),
      },
    };
    try {
      const result = await postMedicine(user?.token, payload);
      if (result?.status?.status === "SUCCESS") {
        toast.success("Medicine Added Successfully");
        navigate("/admin/medicines");
        setBatches([]);
        setMedDetails({});
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error.message);
    }
  };
  useEffect(() => {
    const data = getMedSalt?.payload?.medicine_salts || [];
    const formattedMedSalt = data?.map((el) => ({
      value: el?.id,
      label: el?.name,
    }));
    setAllMedSalt(formattedMedSalt);

    const dataForm = getAllFormTypeData || [];
    const formattedFormType = dataForm?.map((el) => ({
      value: el,
      label: el,
    }));
    setFormType(formattedFormType);
    if (getPackData?.packing?.length > 0) {
      const newPack = getPackData?.packing
        ?.filter((el) => el !== null)
        ?.map((el) => ({
          label: el,
          value: el,
        }));
      setPack((prev) => [...prev, ...newPack]);
    }
  }, [getPackData, getAllFormTypeData, getMedSalt]);
  useEffect(() => {
    if (debouncedInput) {
      const optionExists = pack.some((item) => item.value === debouncedInput);

      if (!optionExists) {
        const newOption = {
          value: debouncedInput,
          label: debouncedInput,
        };
        setPack((prevPack) => [...prevPack, newOption]);
      }
    }
  }, [debouncedInput, pack]);
  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="medicine-list"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#"> Medicine </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Medicine</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Medicine</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-2">
                          <div className="form-group local-forms">
                            <label>
                              Name
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={medDetails?.name}
                              onChange={(e) =>
                                handleChangeMed("name", e?.target?.value)
                              }
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-2">
                          <div className="form-group local-forms">
                            <label>
                              Company
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={medDetails?.company}
                              onChange={(e) =>
                                handleChangeMed("company", e?.target?.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-2">
                          <div className="form-group local-forms">
                            <label>Srp</label>
                            <input
                              value={medDetails?.srp}
                              onChange={(e) =>
                                handleChangeMed("srp", e?.target?.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-2">
                          <div className="form-group local-forms">
                            <label>
                              Form type
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={medDetails?.form_type}
                              onChange={(e) =>
                                handleChangeMed("form_type", e?.target?.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-2">
                          <div className="form-group local-forms">
                            <label>
                              Flat price
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={medDetails?.flat_price}
                              onChange={(e) =>
                                handleChangeMed("flat_price", e?.target?.value)
                              }
                              className="form-control"
                              type="number"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-2">
                          <div className="form-group local-forms">
                            <label>
                              Auto Purchase
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={medDetails?.auto_purchase_threshold}
                              onChange={(e) =>
                                handleChangeMed(
                                  "auto_purchase_threshold",
                                  e?.target?.value
                                )
                              }
                              className="form-control"
                              type="number"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-2">
                          <div className="form-group local-forms">
                            <label>
                              Medicine salt
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              onInputChange={handleSearchMedSalt}
                              onChange={(e) =>
                                handleChangeMed("medicine_salt_id", e?.value)
                              }
                              placeholder="medicine salt"
                              options={allMedSalt}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-2">
                          <div className="form-group local-forms">
                            <label>
                              Gst
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={medDetails?.gst}
                              onChange={(e) =>
                                handleChangeMed("gst", e?.target?.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-2">
                          <div className="form-group local-forms">
                            <label>
                              Discount
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={medDetails?.discount}
                              onChange={(e) =>
                                handleChangeMed("discount", e?.target?.value)
                              }
                              className="form-control"
                              type="number"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-2">
                          <div className="form-group local-forms">
                            <label>
                              Hsn Name
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={medDetails?.hsn_name}
                              onChange={(e) =>
                                handleChangeMed("hsn_name", e?.target?.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-2">
                          <div className="form-group local-forms">
                            <label>
                              Cgst
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={medDetails?.cgst}
                              onChange={(e) =>
                                handleChangeMed("cgst", e?.target?.value)
                              }
                              className="form-control"
                              type="number"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-2">
                          <div className="form-group local-forms">
                            <label>
                              Sgst
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={medDetails?.sgst}
                              onChange={(e) =>
                                handleChangeMed("sgst", e?.target?.value)
                              }
                              className="form-control"
                              type="number"
                            />
                          </div>
                        </div>
                        {/* {part two} */}
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Batches</h4>
                          </div>
                        </div>
                        {batches?.length > 0 &&
                          batches?.map((el, index) => (
                            <React.Fragment key={index}>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>Batch No</label>
                                  <input
                                    value={el[index]?.batch_no}
                                    onChange={(e) =>
                                      handleChangeBatch(
                                        index,
                                        "batch_no",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms cal-icon">
                                  <label>Expiry date</label>
                                  <DatePicker
                                    value={el[index]?.expiry_date}
                                    className="form-control datetimepicker"
                                    onChange={(_, dateString) =>
                                      handleChangeBatch(
                                        index,
                                        "expiry_date",
                                        dateString
                                      )
                                    }
                                    suffixIcon={null}
                                    style={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor:
                                          "2px solid rgba(46, 55, 164, 0.1)",
                                        "&:hover": {
                                          borderColor: state.isFocused
                                            ? "none"
                                            : "none",
                                        },
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>
                                    Amount
                                    <span className="login-danger">*</span>
                                  </label>
                                  <input
                                    value={el[index]?.amount}
                                    onChange={(e) =>
                                      handleChangeBatch(
                                        index,
                                        "amount",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>Purchase cost</label>
                                  <input
                                    value={el[index]?.purchase_cost}
                                    onChange={(e) =>
                                      handleChangeBatch(
                                        index,
                                        "purchase_cost",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>Form Type </label>
                                  <Select
                                    value={el[index]?.form_type}
                                    onChange={(e) =>
                                      handleChangeBatch(
                                        index,
                                        "form_type",
                                        e?.value
                                      )
                                    }
                                    options={formTypeOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    id="search-commodity"
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>Packing</label>
                                  <Select
                                    value={el[index]?.packing}
                                    onChange={(e) => {
                                      handleChangeBatch(
                                        index,
                                        "packing",
                                        e?.value // Access the value directly from the selected option
                                      );
                                    }}
                                    onInputChange={handleInputChange}
                                    options={pack}
                                    placeholder="select pack"
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused
                                          ? "#2e37a4"
                                          : "rgba(46, 55, 164, 0.1)",
                                        boxShadow: state.isFocused
                                          ? "0 0 0 1px #2e37a4"
                                          : "none",
                                        "&:hover": {
                                          borderColor: "rgba(46, 55, 164, 0.1)",
                                        },
                                        borderRadius: "10px",
                                        fontSize: "14px",
                                        minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen
                                          ? "rotate(-180deg)"
                                          : "rotate(0)",
                                        transition: "250ms",
                                        width: "35px",
                                        height: "35px",
                                      }),
                                    }}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>Stock left</label>
                                  <input
                                    value={el[index]?.stock_left}
                                    onChange={(e) =>
                                      handleChangeBatch(
                                        index,
                                        "stock_left",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div
                                style={{ marginBottom: "20px" }}
                                className="col-12 col-md-6 col-xl-2"
                              >
                                <button
                                  style={{
                                    maxWidth: "200px",
                                  }}
                                  onClick={(e) => handleRemoveRow(e, index)}
                                  className="btn btn-primary submit-form me-2 form-control"
                                >
                                  Remove
                                </button>
                              </div>
                            </React.Fragment>
                          ))}
                        <div className="col-12 col-md-6 col-xl-2">
                          <button
                            className="btn btn-primary submit-form me-2 form-control mb-4"
                            onClick={handleAddRow}
                          >
                            Add New Item
                          </button>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleSubmit}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/medicines");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddMedicine;
