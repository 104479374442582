import React from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
// import { Table } from "antd";
import { getFollowUpListDetails } from "../../../connections/getFollowUpListDetails";
// const columns = [
//   {
//     title: "Id",
//     dataIndex: "id",
//   },
//   {
//     title: "Title",
//     dataIndex: "title",
//   },

//   {
//     title: "Created At",
//     dataIndex: "created_at",
//     render: (date) => (
//       <>{dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}</>
//     ),
//   },
//   {
//     title: "Updated At",
//     dataIndex: "updated_at",
//     render: (date) => (
//       <>{dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}</>
//     ),
//   },
// ];
const ShowFollowUpList = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();

  const { data: getFollowUpData, isLoading } = useQuery({
    queryKey: ["getFollowUpListDetails", id],
    queryFn: () => getFollowUpListDetails(user?.token, id),
    enabled: !!id,
    // staleTime: Infinity, // Keep the data fresh indefinitely
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false, // Don't refetch on component mount
  });
  // const specialInstList = getFollowUpData ? [getFollowUpData] : [];

  return (
    <>
      <Header />
      <Sidebar activeClassName="followup_lists-dashboard" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={-1}>Follow-Up Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Follow-Up Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">Id:</span>
                                  <span className="text">
                                    {getFollowUpData?.id || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Title:</span>
                                  <span className="text">
                                    {getFollowUpData?.title || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>
                                  <span className="text">
                                    {getFollowUpData?.created_at
                                      ? dayjs(
                                          getFollowUpData?.created_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Updated At:</span>
                                  <span className="text">
                                    {getFollowUpData?.updated_at
                                      ? dayjs(
                                          getFollowUpData?.updated_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "N/A"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      // <div className="table-responsive doctor-list">
                      //   <Table
                      //     pagination={false}
                      //     columns={columns}
                      //     dataSource={specialInstList}
                      //     rowKey={(record) => record.id}
                      //     scroll={{ x: "max-content" }}
                      //   />
                      // </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ShowFollowUpList;
