import React from "react";
// eslint-disable-next-line no-unused-vars

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/pages/login";
// import config from "config";
import Addblog from "./components/pages/Blog/Addblog";
import Editblog from "./components/pages/Blog/Editblog";
import BlogView from "./components/pages/Blog/BlogView";
import Blogdetails from "./components/pages/Blog/Blogdetails";
//For Settings...
// import Settings from "./components/settings/Settings";
import Localization from "./components/settings/Localization";
import Paymentsetting from "./components/settings/Paymentsetting";
import Settingsemail from "./components/settings/Settingsemail";
import Settingssocialmedia from "./components/settings/Settingssocialmedia";
import Settingssociallinks from "./components/settings/Settingssociallinks";
import Settingsseo from "./components/settings/Settingsseo";
import SettingsThem from "./components/settings/SettingsThem";
import SettingsChangePassword from "./components/settings/SettingsChangePassword";
import SettingsOthers from "./components/settings/SettingsOthers";
//Assest
import Assests from "./components/assests/Assests";
import AddAsset from "./components/assests/AddAsset";
//Doctor
import DoctorList from "./components/doctor/DoctorList";
import AddDoctor from "./components/doctor/AddDoctor";
import EditDoctor from "./components/doctor/EditDoctor";
import DoctorProfile from "./components/doctor/DoctorProfile";
//Patients...
import PatientsList from "./components/patients/PatientsList";
import AddPatients from "./components/patients/AddPatients";
import EditPatients from "./components/patients/EditPatients";
import PatientsProfile from "./components/patients/PatientsProfile";
import AppoinmentList from "./components/appoinments/AppoinmentList";
import AddAppoinments from "./components/appoinments/AddAppoinments";
import EditAppoinments from "./components/appoinments/EditAppoinments";
//DoctorSchedule
import ScheduleList from "./components/doctorschedule/ScheduleList";

import AddSchedule from "./components/doctorschedule/AddSchedule";
import EditSchedule from "./components/doctorschedule/EditSchedule";
//Departments
import DepartmentList from "./components/department/DepartmentList";
import AddDepartment from "./components/department/AddDepartment";
import EditDepartment from "./components/department/EditDepartment";
import StaffList from "./components/staff/StafList";
import StaffProfile from "./components/staff/StaffProfile";
// import ProvidentFund from "./components/accounts/ProvidentFund";
// import ForgotPassword from "./components/pages/login/ForgotPassword";
// import Signup from "./components/pages/login/Signup";
// import Invoice from "./components/accounts/Invoice";
// import Create_Invoice from "./components/accounts/Create_Invoice";
// import Payments from "./components/accounts/Payments";
// import Add_Payment from "./components/accounts/Add_Payment";
// import Expenses from "./components/accounts/Expenses";
// import Add_Expense from "./components/accounts/Add_Expense";
// import Taxes from "./components/accounts/Taxes";
// import Add_Tax from "./components/accounts/Add_Tax";
import EmployeeSalary from "./components/Payroll/EmployeeSalary/EmployeeSalary";

import Inbox from "./components/email/Inbox";
import AddLeave from "./components/staff/AddLeave";
import Attendence from "./components/staff/Attendence";
import Leave from "./components/staff/Leave";
import ComposeMail from "./components/email/ComposeMail";
import MailView from "./components/email/MailView";
import UserActivity from "./components/activity/UserActivity";
import AddEmployeeSalary from "./components/Payroll/EmployeeSalary/AddEmployeeSalary";
import Chat from "./components/Chat/Chat";
import VoiceCall from "./components/Call/VoiceCall";
import VideoCall from "./components/Call/VideoCall";
import EditStaff from "./components/staff/EditStaff";
import EditLeave from "./components/staff/EditLeave";
import Holiday from "./components/staff/Holiday";
// import Add_ProviderFund from "./components/accounts/Add_ProviderFund";
import ExpensesReport from "./components/ExpenseReport/Expenses/ExpensesReport";
import AddExpenses from "./components/ExpenseReport/Expenses/AddExpenses";
import Invoice_Report from "./components/ExpenseReport/Invoice-report/Invoice_Report";
import OverDue from "./components/Invoice/Invoice-List/Overdue-Invoice/OverDue";
import InvoiceList from "./components/Invoice/Invoice-List/InvoiceList";
import Paid_Invoice from "./components/Invoice/Invoice-List/Paid-Invoice/Paid_Invoice";
import Draft_Invoice from "./components/Invoice/Invoice-List/Draft_Invoice/Draft_Invoice";
import Recurring_Invoice from "./components/Invoice/Invoice-List/Recurring_Invoice/Recurring_Invoice";
import Cancelled_Invoice from "./components/Invoice/Invoice-List/Cancelled_Invoice/Cancelled_Invoice";
import Invoice_Grid from "./components/Invoice/Invoices_Grid/Invoice_Grid";
import Add_Invoices from "./components/Invoice/Add_Invoices/Add_Invoices";
import Edit_Invoices from "./components/Invoice/Edit_Invoices/Edit_Invoices";
import Invoice_Details from "./components/Invoice/Invoice_Details/Invoice_Details";
import Invoice_GeneralSettings from "./components/Invoice/Invoice_Settings/General_Settings/Invoice_GeneralSettings";
import Tax_Settings from "./components/Invoice/Invoice_Settings/Tax_Settings/Tax_Settings";
import Bank_Settings from "./components/Invoice/Invoice_Settings/Bank_Settings/Bank_Settings";
import IncomingCall from "./components/Chat/IncomingCall";
import BasicInput from "./components/Forms/BasicInput";
import InputGroups from "./components/Forms/InputGroups";
import HorizontalForm from "./components/Forms/HorizontalForm";
import VerticalForm from "./components/Forms/VerticalForm";
import BasicTable from "./components/Tables/BasicTable";
import DataTable from "./components/Tables/DataTable";
import UiKit from "./components/Ui_Elements/UiKit";
import Typography from "./components/Ui_Elements/Typography";
import Tab from "./components/Ui_Elements/Tab";
import Edit_Assets from "./components/assests/Edit_Assets";
import Register from "./components/pages/login/Register";
import LockScreen from "./components/pages/login/LockScreen";
import ChangePassword from "./components/pages/login/ChangePassword";
import Error from "./components/pages/login/Error";
import ServerError from "./components/pages/login/ServerError";
import EditEmployeeSalery from "./components/Payroll/EmployeeSalary/EditEmployeeSalery";
import Calender from "./components/calender/Calender";
import Profile from "./components/pages/login/Profile";
import EditProfile from "./components/pages/login/EditProfile";
import BlankPage from "./components/pages/login/BlankPage";
import Doctor_Dashboard from "./components/Dashboard/Doctor_Dashboard/Doctor_Dashboard";
import Admin_Dashboard from "./components/Dashboard/Admin_Dashboard/Admin_Dashboard";
import Patient_Dashboard from "./components/Dashboard/Patient_Dashboard/Patient_Dashboard";
import Doctor_Settings from "./components/Dashboard/Doctor_Dashboard/Doctor_Settings";
import Patient_Settings from "./components/patients/Patient_Settings";
import Staff_Settings from "./components/staff/Staff-Settings";
// import Edit_Provident from "./components/accounts/Edit_Provident";
// import Edit_Taxes from "./components/accounts/Edit_Taxes";
import Edit_Expenses from "./components/ExpenseReport/Expenses/Edit_Expenses";
// import Edit_Payment from "./components/accounts/Edit_Payment";
import Payslip from "./components/Payroll/Payslip";
import Setting from "./components/settings/Setting";
import GalleryImage from "./components/pages/Gallery/Gallery";
import PrivateRoute from "./PrivateRoute ";
import { useSelector } from "react-redux";
import PharmacyList from "./components/Pharmacy/PharmacyList";
import PharmacyPurchaseOrder from "./components/Pharmacy/PharmacyPurchaseOrder";
// import PharmacySuppliers from "./components/Pharmacy/PharmacySuppliers";
import ViewSinglePurchaseOrder from "./components/Pharmacy/ViewSinglePurchaseOrder";
import SuppliersDetail from "./components/suppliers/SuppliersDetail";
import SuppliersList from "./components/suppliers/SuppliersList";
import AddSuppliers from "./components/suppliers/AddSuppliers";
import UpdateSupplier from "./components/suppliers/UpdateSupplier";
import AddPurchaseOrder from "./components/Pharmacy/AddPurchaseOrder";
import EditPurchaseOrder from "./components/Pharmacy/EditPurchaseOrder";
import MedicineSalts from "./components/medicineSalts/MedicineSalts";
import AddMedicineSalts from "./components/medicineSalts/AddMedicineSalts";
import AddPurchaseOrderAdmin from "./components/Pharmacy/AddPurchaseOrderAdmin";
import EditPurchaseOrderAdmin from "./components/Pharmacy/EditPurchaseOrderAdmin";
import DepartmentDetails from "./components/department/DepartmentDetails";
import MedicineList from "./components/medicine/MedicineList";
import MedicineDetail from "./components/medicine/MedicineDetail";
import AddMedicine from "./components/medicine/AddMedicine";
import UpdateMedicine from "./components/medicine/UpdateMedicine";
import ConsumableList from "./components/consumable/ConsumableList";

import AddConsumable from "./components/consumable/AddConsumable";
import ShowConsumableCategory from "./components/consumableCategory/ShowConsumableCategory";
import ShowConsumable from "./components/consumable/ShowConsumable";
import EditConsumable from "./components/consumable/EditConsumable";
import UpdateMedicineSalts from "./components/medicineSalts/updateMedicineSalts";
import MedicineSaltDetail from "./components/medicineSalts/MedicineSaltDetail";
import AppointmentDetail from "./components/appoinments/AppointmentDetail";
import ConsumableCategoryList from "./components/consumableCategory/ConsumableCategoryList";
import AddConsumableCat from "./components/consumableCategory/AddConsumableCat";
import UpdateConsumableCat from "./components/consumableCategory/UpdateConsumableCat";
import PatientDetails from "./components/patients/PatientDetails";
import PharmacyDetail from "./components/Pharmacy/PharmacyDetail";
import UpdateBatch from "./components/Pharmacy/UpdateBatch";
import AddInventory from "./components/lab_Inventory/AddInventory";
import UpdateInventory from "./components/lab_Inventory/UpdateInventory";
import LabInventory from "./components/lab_Inventory/LabInventory";
import LabInventoryDetail from "./components/lab_Inventory/LabInventoryDetails";
import ViewDoctor from "./components/doctor/ViewDoctor";
import EducationList from "./components/Education/EducationList";
import AddEducation from "./components/Education/AddEducation";
import UpdateEducation from "./components/Education/UpdateEducation";
import EducationDetails from "./components/Education/EducationDetails";
import CollectionsList from "./components/Collections/CollectionsList";
import HolidaysList from "./components/holidays/HolidaysList";
import HolidaysDetails from "./components/holidays/HolidaysDetails";
import AddHoliday from "./components/holidays/AddHoliday";
import UpdateHolidays from "./components/holidays/UpdateHolidays";
import ActivityList from "./components/activity/ActivityList";
import ShowOccupation from "./components/occupation/ShowOccupation";
import OccupationList from "./components/occupation/OccupationList";
import AddOccupations from "./components/occupation/AddOccupations";
import UpdateOccupations from "./components/occupation/UpdateOccupations";
import Labs from "./components/Labs/Labs";
import AddLabs from "./components/Labs/AddLabs";
import UpdateLabs from "./components/Labs/UpdateLabs";
import ShowLab from "./components/Labs/ShowLab";
import AdmissionsList from "./components/Admission/AdmissionsList";
import AdmissionDetails from "./components/Admission/AdmissionDetails";
import AddAdmission from "./components/Admission/AddAdmission";
import UpdateAdmission from "./components/Admission/UpdateAdmission";
import MedicalShortCode from "./components/Prescription/MedicalTestShortCode/MedicalShortCode";
import AddShortCode from "./components/Prescription/MedicalTestShortCode/AddShortCode";
import UpdateShortCode from "./components/Prescription/MedicalTestShortCode/UpdateShortCode";
import ShowShortCode from "./components/Prescription/MedicalTestShortCode/ShowShortCode";
import MedicineDescription from "./components/Prescription/MedicineDescription/MedicineDescription";
import AddMedicineDescription from "./components/Prescription/MedicineDescription/AddMedicineDescription";
import UpdateMedicineDescription from "./components/Prescription/MedicineDescription/UpdateMedicineDescription";
import ShowMedicineDescription from "./components/Prescription/MedicineDescription/ShowMedicineDescription";
import SpecialInstruction from "./components/Prescription/SpecialIntructions/SpecialInstruction";
import AddSpecialInstruction from "./components/Prescription/SpecialIntructions/AddSpecialInstruction";
import UpdateSpecialInstruction from "./components/Prescription/SpecialIntructions/UpdateSpecialInstruction";
import ShowSpecialInstruction from "./components/Prescription/SpecialIntructions/ShowSpecialInstruction";
import VideoCategory from "./components/Prescription/VideoCategory/VideoCategory";
import AddVideoCategory from "./components/Prescription/VideoCategory/AddVideoCategory";
import UpdateVideoCategory from "./components/Prescription/VideoCategory/UpdateVideoCategory";
import ShowVideoCategory from "./components/Prescription/VideoCategory/ShowVideoCategory";
import Video from "./components/Prescription/Video/Video";
import AddVideo from "./components/Prescription/Video/AddVideo";
import UpdateVideo from "./components/Prescription/Video/UpdateVideo";
import ShowVideo from "./components/Prescription/Video/ShowVideo";
import FollowUpList from "./components/Prescription/FollowUpList/FollowUpList";
import AddFollowUpList from "./components/Prescription/FollowUpList/AddFollowUpList";
import UpdateFollowUpList from "./components/Prescription/FollowUpList/UpdateFollowUpList";
import ShowFollowUpList from "./components/Prescription/FollowUpList/ShowFollowUpList";
import MedicineRemark from "./components/Prescription/MedicineRemarks/MedicineRemark";
import AddMedicineRemark from "./components/Prescription/MedicineRemarks/AddMedicineRemark";
import UpdateMedicineRemarks from "./components/Prescription/MedicineRemarks/UpdateMedicineRemarks";
import ShowMedicineRemarks from "./components/Prescription/MedicineRemarks/ShowMedicineRemarks";
import Treatment from "./components/Prescription/Treatment/Treatment";
import AddTreatMent from "./components/Prescription/Treatment/AddTreatMent";
import UpdateTreatMent from "./components/Prescription/Treatment/UpdateTreatMent";
import ShowTreatment from "./components/Prescription/Treatment/ShowTreatment";
import MedicalTest from "./components/MedicalTest/MedicalTest";
import AddMedicalTestList from "./components/MedicalTest/AddMedicalTestList";
import UpdateMedicalTest from "./components/MedicalTest/UpdateMedicalTest";
import ShowMedicalTest from "./components/MedicalTest/ShowMedicalTest";
import MedicalTestCategory from "./components/medicalTestCategory/MedicalTestCategory";
import AddMedicalTestCategory from "./components/medicalTestCategory/AddMedicalTestCategory";
import UpdateMedicalTestCategory from "./components/medicalTestCategory/UpdateMedicalTestCategory";
import ShowMedicalTestCategory from "./components/medicalTestCategory/ShowMedicalTestCategory";
import AppointmentSlot from "./components/AppointmentSlot/AppointmentSlot";
import AddAppointmentSlot from "./components/AppointmentSlot/AddAppointmentSlot";
import UpdateAppointmentSlot from "./components/AppointmentSlot/UpdateAppointmentSlot";
import ShowAppointmentSlot from "./components/AppointmentSlot/ShowAppointmentSlot";
import EditInvoiceItem from "./components/Invoice/Invoice_Details/EditInvoiceItem";
import ShowInvoiceItem from "./components/Invoice/Invoice_Details/ShowInvoiceItem";
import Organization from "./components/Organizations/Organization";
import UpdateOrganization from "./components/Organizations/UpdateOrganization";
import RolesList from "./components/roles/RolesList";
import AddRoles from "./components/roles/AddRoles";
import UpdateRoles from "./components/roles/UpdateRoles";
import RolesDetails from "./components/roles/RolesDetails";
import AddStaff from "./components/staff/AddStaff";
import ShowStaff from "./components/staff/ShowStaff";
import RevenueShare from "./components/RevenueShare/RevenueShare";
import AddRevenueShare from "./components/RevenueShare/AddRevenueShare";
import UpdateRevenueShare from "./components/RevenueShare/UpdateRevenueShare";
import ShowRevenueShare from "./components/RevenueShare/ShowRevenueShare";
import Referrers from "./components/referrers/Referrers";
import AddReferrers from "./components/referrers/AddReferrers";
import UpdateReferrers from "./components/referrers/UpdateReferrers";
import ShowReferrers from "./components/referrers/ShowReferrers";
import AdminUsersList from "./components/adminUser/AdminUserList";
import AddAdminUser from "./components/adminUser/AddAdminUser";
import UpdateAdminUser from "./components/adminUser/UpdateAdminUser";
import AdminUserDetails from "./components/adminUser/AdminUserDetails";
import OrgSettings from "./components/OrgSettings/OrgSettings";
import AddOrgSettings from "./components/OrgSettings/AddOrgSettings";
import UpdateOrgSettings from "./components/OrgSettings/UpdateOrgSettings";
import Permission from "./components/Permission/Permission";
import ShowPermission from "./components/Permission/ShowPermission";
import ShowOrgSettings from "./components/OrgSettings/ShowOrgSettings";
import Diagnosis from "./components/Prescription/Diagnosis/Diagnosis";
import AddDiagnosis from "./components/Prescription/Diagnosis/AddDiagnosis";
import UpdateDiagnosis from "./components/Prescription/Diagnosis/UpdateDiagnosis";
import ShowDiagnosis from "./components/Prescription/Diagnosis/ShowDiagnosis";
// import Account from "./components/Account/Account";
// import ShowAccount from "./components/Account/ShowAccount";

//Accounts
const Approuter = () => {
  const { isAuthenticated, user } = useSelector((state) => state.user);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated === true ? (
                <Navigate to={user.redirect ? `/${user.redirect}` : "/"} />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/login"
            element={
              isAuthenticated === true ? (
                <Navigate to={user.redirect ? `/${user.redirect}` : "/login"} />
              ) : (
                <Login />
              )
            }
          />
          {/* <Route path="/" element={<Login />} /> */}
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/forgotpassword" element={<ForgotPassword />} /> */}
          {/* <Route path="/signup" element={<Signup />} /> */}
          {/* {private routes} */}
          <Route element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/register" element={<Register />} />
            <Route path="/lockscreen" element={<LockScreen />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/error" element={<Error />} />
            <Route path="/server-error" element={<ServerError />} />
            <Route path="/blankpage" element={<BlankPage />} />
            <Route path="/gallery" element={<GalleryImage />} />
            {/* Blog */}
            <Route path="/blog" element={<Blogdetails />} />
            <Route path="/addblog" element={<Addblog />} />
            <Route path="/editblog" element={<Editblog />} />
            <Route path="/blogview" element={<BlogView />} />
            {/* Settings */}
            <Route path="/settings" element={<Setting />} />
            <Route path="/localization" element={<Localization />} />
            <Route path="/paymentsetting" element={<Paymentsetting />} />
            <Route path="/settingsemail" element={<Settingsemail />} />
            <Route
              path="/settingssocialmedia"
              element={<Settingssocialmedia />}
            />
            <Route
              path="/settingssociallink"
              element={<Settingssociallinks />}
            />
            <Route path="/settingsseo" element={<Settingsseo />} />
            <Route path="/settingsthem" element={<SettingsThem />} />
            <Route
              path="/settingschangepassword"
              element={<SettingsChangePassword />}
            />
            <Route path="/settingsothers" element={<SettingsOthers />} />
            {/* Assests */}
            <Route path="/assests" element={<Assests />} />
            <Route path="/addasset" element={<AddAsset />} />
            <Route path="/edit-assets" element={<Edit_Assets />} />
            {/* Doctor  */}
            <Route path="/admin/doctor" element={<DoctorList />} />
            <Route path="/admin/add-doctor" element={<AddDoctor />} />
            <Route path="/admin/edit-doctor" element={<EditDoctor />} />
            <Route path="/admin/doctor/:id" element={<ViewDoctor />} />
            <Route path="/doctorprofile" element={<DoctorProfile />} />
            <Route path="/doctor-setting" element={<Doctor_Settings />} />
            {/* Patients */}
            <Route path="/patientslist" element={<PatientsList />} />
            <Route path="/addpatients" element={<AddPatients />} />
            <Route path="/editpatients" element={<EditPatients />} />
            <Route path="/patientsprofile" element={<PatientsProfile />} />
            <Route path="/patient-settings" element={<Patient_Settings />} />
            <Route path="/admin/patient/:id" element={<PatientDetails />} />
            {/* Appoinments */}
            <Route path="/appoinmentlist" element={<AppoinmentList />} />
            <Route path="/addappoinments" element={<AddAppoinments />} />
            <Route path="/editappoinments" element={<EditAppoinments />} />
            <Route
              path="/admin/appointment/:id"
              element={<AppointmentDetail />}
            />
            {/* DoctorSchedule */}
            <Route path="/schedulelist" element={<ScheduleList />} />
            <Route path="/addschedule" element={<AddSchedule />} />
            <Route path="/editschedule" element={<EditSchedule />} />
            {/* Department */}
            <Route path="/departmentlist" element={<DepartmentList />} />
            <Route path="/add-department" element={<AddDepartment />} />
            <Route path="/editdepartment" element={<EditDepartment />} />
            <Route
              path="/department_details/:id"
              element={<DepartmentDetails />}
            />
            {/* Staff */}
            <Route path="/admin/staff_list" element={<StaffList />} />
            <Route path="/admin/staff_list/:id" element={<ShowStaff />} />
            <Route path="/admin/add_staff" element={<AddStaff />} />
            <Route path="/admin/edit_staff" element={<EditStaff />} />
            <Route path="/staffprofile" element={<StaffProfile />} />
            <Route path="/leave" element={<Leave />} />
            <Route path="/add-leave" element={<AddLeave />} />
            <Route path="/editleave" element={<EditLeave />} />
            <Route path="/attendence" element={<Attendence />} />
            <Route path="/holiday" element={<Holiday />} />
            <Route path="/staff-settings" element={<Staff_Settings />} />
            {/* Accounts */}
            {/* <Route path="/providentfund" element={<ProvidentFund />} />
            <Route path="/add-providerfund" element={<Add_ProviderFund />} />
            <Route path="/invoicelist" element={<Invoice />} />
            <Route path="/createinvoice" element={<Create_Invoice />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/addpayment" element={<Add_Payment />} />
            <Route path="/expenses" element={<Expenses />} />
            <Route path="/addexpense" element={<Add_Expense />} />
            <Route path="/taxes" element={<Taxes />} />
            <Route path="/edit-taxes" element={<Edit_Taxes />} />
            <Route path="/addtax" element={<Add_Tax />} />
            <Route path="/edit-provident" element={<Edit_Provident />} />
            <Route path="/edit-payment" element={<Edit_Payment />} /> */}
            {/* <Route path="/admin/account" element={<Account />} /> */}
            {/* <Route path="/admin/account/:id" element={<ShowAccount />} /> */}
            {/* /* Payroll */}
            <Route path="/employeesalary" element={<EmployeeSalary />} />
            <Route path="/addsalary" element={<AddEmployeeSalary />} />
            <Route path="/editsalary" element={<EditEmployeeSalery />} />
            <Route path="/payslip" element={<Payslip />} />
            {/* Email */}
            <Route path="/inbox" element={<Inbox />} />
            <Route path="/compose-mail" element={<ComposeMail />} />
            <Route path="/mail-view" element={<MailView />} />
            {/* Activity */}
            <Route path="/user-activity" element={<UserActivity />} />
            <Route path="/admin/user-activity" element={<ActivityList />} />
            {/* ExpenseReport */}
            <Route path="/expense-Report" element={<ExpensesReport />} />
            <Route path="/add-expense" element={<AddExpenses />} />
            <Route path="/invoice-report" element={<Invoice_Report />} />
            <Route path="/edit-expenses" element={<Edit_Expenses />} />
            {/* Chat */}
            <Route path="/chat" element={<Chat />} />
            {/* Call */}
            <Route path="/voice-call" element={<VoiceCall />} />
            <Route path="/video-call" element={<VideoCall />} />
            <Route path="/incoming-call" element={<IncomingCall />} />
            {/* Invoice */}
            <Route path="/admin/invoice-list" element={<InvoiceList />} />
            <Route path="/paid-invoice" element={<Paid_Invoice />} />
            <Route path="/overdue-invoice" element={<OverDue />} />
            <Route path="/draft-invoice" element={<Draft_Invoice />} />
            <Route path="/recurring-invoice" element={<Recurring_Invoice />} />
            <Route path="/cancelled-invoice" element={<Cancelled_Invoice />} />
            <Route path="/invoice-grid" element={<Invoice_Grid />} />
            <Route path="/admin/add-invoice" element={<Add_Invoices />} />
            <Route path="/admin/edit-invoice" element={<Edit_Invoices />} />
            <Route
              path="/admin/invoice-details/:id"
              element={<Invoice_Details />}
            />
            <Route
              path="/admin/update-invoice-item-details"
              element={<EditInvoiceItem />}
            />
            <Route
              path="/admin/invoice-item-details/:id"
              element={<ShowInvoiceItem />}
            />
            <Route
              path="/invoice-settings"
              element={<Invoice_GeneralSettings />}
            />
            <Route path="/tax-settings" element={<Tax_Settings />} />
            <Route path="/bank-settings" element={<Bank_Settings />} />
            {/* ui-elements */}
            <Route path="/ui-kit" element={<UiKit />} />
            <Route path="/typography" element={<Typography />} />
            <Route path="/tab" element={<Tab />} />
            {/* Forms */}
            <Route path="/basic-input" element={<BasicInput />} />
            <Route path="/inputgroup" element={<InputGroups />} />
            <Route path="/horizontal-form" element={<HorizontalForm />} />
            <Route path="/vertical-form" element={<VerticalForm />} />
            {/* Tables */}
            <Route path="/basic-table" element={<BasicTable />} />
            <Route path="/data-table" element={<DataTable />} />
            {/* Calender */}
            <Route path="/calender" element={<Calender />} />
            {/* Dashboard */}
            <Route path="/admin/dashboard" element={<Admin_Dashboard />} />
            <Route
              path="admin/doctor-dashboard"
              element={<Doctor_Dashboard />}
            />
            <Route path="/patient-dashboard" element={<Patient_Dashboard />} />
            {/* {"Pharmacy"} */}
            <Route path="admin/pharmacy" element={<PharmacyList />} />
            <Route path="/admin/pharmacy/:id" element={<PharmacyDetail />} />
            <Route
              path="/admin/update-batch-pharmacy"
              element={<UpdateBatch />}
            />
            <Route
              path="/admin/purchase_orders"
              element={<PharmacyPurchaseOrder />}
            />
            <Route path="/admin/medicine-salts" element={<MedicineSalts />} />
            <Route
              path="/admin/add-medicine-salts"
              element={<AddMedicineSalts />}
            />
            <Route
              path="/admin/update-medicine-salts"
              element={<UpdateMedicineSalts />}
            />
            <Route
              path="/admin/medicine-salts/:id"
              element={<MedicineSaltDetail />}
            />
            <Route
              path="/admin/purchase_orders/:id"
              element={<ViewSinglePurchaseOrder />}
            />
            <Route
              path="/admin/add_purchase_orders"
              element={<AddPurchaseOrder />}
            />{" "}
            <Route
              path="/admin/purchase_orders/new"
              element={<AddPurchaseOrderAdmin />}
            />
            <Route
              path="/admin/update_purchase_orders"
              element={<EditPurchaseOrder />}
            />
            <Route
              path="/admin/edit_purchase_orders"
              element={<EditPurchaseOrderAdmin />}
            />
            {/* <Route path="/admin/suppliers" element={<PharmacySuppliers />} /> */}
            <Route path="/admin/collections" element={<CollectionsList />} />
            <Route
              path="/admin/collections/:id"
              element={<CollectionsList />}
            />
            {/* {suppliers} */}
            <Route path="/admin/suppliers/:id" element={<SuppliersDetail />} />
            <Route path="/admin/suppliers" element={<SuppliersList />} />
            <Route path="/admin/add-suppliers" element={<AddSuppliers />} />
            <Route
              path="/admin/update-suppliers"
              element={<UpdateSupplier />}
            />
            <Route path="/admin/patients" element={<PatientsList />} />
            {/* {------------------medicine---------------------} */}
            <Route path="/admin/medicines" element={<MedicineList />} />
            <Route path="/admin/medicines/:id" element={<MedicineDetail />} />
            <Route path="/admin/add_medicines" element={<AddMedicine />} />
            <Route
              path="/admin/update_medicines"
              element={<UpdateMedicine />}
            />
            {/* {---------consumable-------} */}
            <Route path="/admin/consumable" element={<ConsumableList />} />
            <Route path="/admin/consumable/:id" element={<ShowConsumable />} />
            <Route path="/admin/add_consumable" element={<AddConsumable />} />
            <Route
              path="/admin/update_consumable"
              element={<EditConsumable />}
            />
            {/* {---------consumable_categories-------} */}
            <Route
              path="/admin/consumable_categories"
              element={<ConsumableCategoryList />}
            />
            <Route
              path="/admin/consumable_categories/:id"
              element={<ShowConsumableCategory />}
            />
            <Route
              path="/admin/add_consumable_categories"
              element={<AddConsumableCat />}
            />
            <Route
              path="/admin/update_consumable_categories"
              element={<UpdateConsumableCat />}
            />
            {/* {lab inventory} */}
            <Route path="/admin/lab_inventory" element={<LabInventory />} />
            {/* <Route
            path="/admin/lab_inventory/:id"
            element={<ShowLabInventoryList />}
          /> */}
            <Route path="/admin/add_lab_inventory" element={<AddInventory />} />
            <Route
              path="/admin/lab_inventory/:id"
              element={<LabInventoryDetail />}
            />
            <Route
              path="/admin/update_lab_inventory"
              element={<UpdateInventory />}
            />
            {/* {educations routes } */}
            <Route path="/admin/education" element={<EducationList />} />
            <Route path="/admin/education/:id" element={<EducationDetails />} />
            <Route path="/admin/add-education" element={<AddEducation />} />
            <Route
              path="/admin/update-education"
              element={<UpdateEducation />}
            />
            {/* {Holidays routes} */}
            <Route path="/admin/admin-user" element={<AdminUsersList />} />
            <Route
              path="/admin/admin-user/:id"
              element={<AdminUserDetails />}
            />
            <Route path="/admin/add-admin-user" element={<AddAdminUser />} />
            <Route
              path="/admin/update_admin_user"
              element={<UpdateAdminUser />}
            />
            <Route path="/admin/holidays" element={<HolidaysList />} />
            <Route path="/admin/holidays/:id" element={<HolidaysDetails />} />
            <Route path="/admin/add-holidays" element={<AddHoliday />} />
            <Route path="/admin/update-holidays" element={<UpdateHolidays />} />
            <Route path="/admin/roles" element={<RolesList />} />
            <Route path="/admin/roles/:id" element={<RolesDetails />} />
            <Route path="/admin/add_roles" element={<AddRoles />} />
            <Route path="/admin/update_roles/" element={<UpdateRoles />} />
            {/* {occupations routes}  */}
            <Route path="/admin/occupation/:id" element={<ShowOccupation />} />
            <Route path="/admin/occupation" element={<OccupationList />} />
            <Route path="/add_occupations" element={<AddOccupations />} />
            <Route path="/edit_occupations" element={<UpdateOccupations />} />
            <Route path="/admin/labs/:id" element={<ShowLab />} />
            <Route path="/admin/labs" element={<Labs />} />
            <Route path="/add_labs" element={<AddLabs />} />
            <Route path="/edit_labs" element={<UpdateLabs />} />
            {/* {Admissions Routes}  */}
            <Route path="/admin/admission" element={<AdmissionsList />} />
            <Route path="/admin/admission/:id" element={<AdmissionDetails />} />
            <Route path="/admin/add_admission" element={<AddAdmission />} />
            <Route
              path="/admin/update_admission"
              element={<UpdateAdmission />}
            />
            {/* {priscription routes} */}
            {/* Medical Test Short Code */}
            <Route
              path="/admin/prescription/medical_test_shortcodes"
              element={<MedicalShortCode />}
            />
            <Route
              path="/admin/prescription/medical_test_shortcodes/:id"
              element={<ShowShortCode />}
            />
            <Route
              path="/admin/add-medical_test_shortcodes"
              element={<AddShortCode />}
            />
            <Route
              path="/admin/update-medical_test_shortcodes"
              element={<UpdateShortCode />}
            />
            {/* {medicine Description routes} */}
            <Route
              path="/admin/prescription/medicine_descriptions"
              element={<MedicineDescription />}
            />
            <Route
              path="/admin/prescription/medicine_descriptions/:id"
              element={<ShowMedicineDescription />}
            />
            <Route
              path="/admin/prescription/add-medicine_descriptions"
              element={<AddMedicineDescription />}
            />
            <Route
              path="/admin/prescription/update-medicine_descriptions"
              element={<UpdateMedicineDescription />}
            />
            {/* {special instructions routes} */}
            <Route
              path="/admin/prescription/special_instructions"
              element={<SpecialInstruction />}
            />
            <Route
              path="/admin/prescription/special_instructions/:id"
              element={<ShowSpecialInstruction />}
            />
            <Route
              path="/admin/prescription/add-special_instructions"
              element={<AddSpecialInstruction />}
            />
            <Route
              path="/admin/prescription/update-special_instructions"
              element={<UpdateSpecialInstruction />}
            />
            {/* {video Category Routes} */}
            <Route
              path="/admin/prescription/video_categories"
              element={<VideoCategory />}
            />
            <Route
              path="/admin/prescription/video_categories/:id"
              element={<ShowVideoCategory />}
            />
            <Route
              path="/admin/prescription/add-video_categories"
              element={<AddVideoCategory />}
            />
            <Route
              path="/admin/prescription/update-video_categories"
              element={<UpdateVideoCategory />}
            />
            {/* {Video Routes}  */}
            <Route path="/admin/prescription/videos" element={<Video />} />
            <Route
              path="/admin/prescription/videos/:id"
              element={<ShowVideo />}
            />
            <Route
              path="/admin/prescription/add-videos"
              element={<AddVideo />}
            />
            <Route
              path="/admin/prescription/update-videos"
              element={<UpdateVideo />}
            />
            {/* {follow up list} */}
            <Route
              path="/admin/prescription/followup_lists"
              element={<FollowUpList />}
            />
            <Route
              path="/admin/prescription/followup_lists/:id"
              element={<ShowFollowUpList />}
            />
            <Route
              path="/admin/prescription/add-followup_lists"
              element={<AddFollowUpList />}
            />
            <Route
              path="/admin/prescription/update-followup_lists"
              element={<UpdateFollowUpList />}
            />
            {/* {medicine Remarks Rutes}  */}
            <Route
              path="/admin/prescription/medicine_remarks"
              element={<MedicineRemark />}
            />
            <Route
              path="/admin/prescription/medicine_remarks/:id"
              element={<ShowMedicineRemarks />}
            />
            <Route
              path="/admin/prescription/add-medicine_remarks"
              element={<AddMedicineRemark />}
            />
            <Route
              path="/admin/prescription/update-medicine_remarks"
              element={<UpdateMedicineRemarks />}
            />
            {/* {treat ment routes}  */}
            <Route
              path="/admin/prescription/treatments"
              element={<Treatment />}
            />
            <Route
              path="/admin/prescription/treatments/:id"
              element={<ShowTreatment />}
            />
            <Route
              path="/admin/prescription/add-treatments"
              element={<AddTreatMent />}
            />
            <Route
              path="/admin/prescription/update-treatments"
              element={<UpdateTreatMent />}
            />
            {/* {medical test routes}  */}
            <Route path="/admin/medical_tests" element={<MedicalTest />} />
            <Route
              path="/admin/medical_tests/:id"
              element={<ShowMedicalTest />}
            />
            <Route
              path="/admin/add-medical_tests"
              element={<AddMedicalTestList />}
            />
            <Route
              path="/admin/update-medical_tests"
              element={<UpdateMedicalTest />}
            />
            {/* {medical test category routes} */}
            <Route
              path="/admin/medical_tests_category"
              element={<MedicalTestCategory />}
            />
            <Route
              path="/admin/medical_tests_category/:id"
              element={<ShowMedicalTestCategory />}
            />
            <Route
              path="/admin/add-medical_tests_category"
              element={<AddMedicalTestCategory />}
            />
            <Route
              path="/admin/update-medical_tests_category"
              element={<UpdateMedicalTestCategory />}
            />
            {/* {appointment slot routes} */}
            <Route
              path="/admin/appointment_slot"
              element={<AppointmentSlot />}
            />
            <Route
              path="/admin/appointment_slot/:id"
              element={<ShowAppointmentSlot />}
            />
            <Route
              path="/admin/add-appointment_slot"
              element={<AddAppointmentSlot />}
            />
            <Route
              path="/admin/update-appointment_slot"
              element={<UpdateAppointmentSlot />}
            />
            {/* {orgANATION ROUTES} */}
            <Route path="/admin/organization" element={<Organization />} />
            <Route
              path="/admin/update-organization"
              element={<UpdateOrganization />}
            />
            {/* {"Organization routes"} */}
            <Route
              path="/admin/organization_settings"
              element={<OrgSettings />}
            />
            <Route
              path="/admin/organization_settings/:id"
              element={<ShowOrgSettings />}
            />
            <Route
              path="/admin/add-organization_settings"
              element={<AddOrgSettings />}
            />
            <Route
              path="/admin/update-organization_settings"
              element={<UpdateOrgSettings />}
            />
            {/* {revenue shares routes} */}
            <Route path="/admin/revenue_shares" element={<RevenueShare />} />
            <Route
              path="/admin/revenue_shares/:id"
              element={<ShowRevenueShare />}
            />
            <Route
              path="/admin/add-revenue_shares"
              element={<AddRevenueShare />}
            />
            <Route
              path="/admin/update-revenue_shares"
              element={<UpdateRevenueShare />}
            />
            {/* {"referrers routes"} */}
            <Route path="/admin/referrers" element={<Referrers />} />
            <Route path="/admin/referrers/:id" element={<ShowReferrers />} />
            <Route path="/admin/add-referrers" element={<AddReferrers />} />
            <Route
              path="/admin/update-referrers"
              element={<UpdateReferrers />}
            />
            {/* {Permission routes} */}
            <Route path="/admin/permission" element={<Permission />} />
            <Route path="/admin/permission/:id" element={<ShowPermission />} />
            {/* {dianonsis routes} */}
            <Route
              path="/admin/prescription/diagnosis"
              element={<Diagnosis />}
            />
            <Route
              path="/admin/prescription/diagnosis/:id"
              element={<ShowDiagnosis />}
            />
            <Route
              path="/admin/prescription/add-diagnosis"
              element={<AddDiagnosis />}
            />
            <Route
              path="/admin/prescription/update-diagnosis"
              element={<UpdateDiagnosis />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
      <div className="sidebar-overlay"></div>
    </>
  );
};

export default Approuter;
