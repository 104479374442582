import React, { useState } from "react";
import { Dropdown, Menu, Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import {
  imagesend,
  pdficon,
  pdficon3,
  plusicon,
  refreshicon,
  pdficon5,
} from "../imagepath";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";
import { getAllAppointmentList } from "../../connections/getAllAppointmentList";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  generateCsv,
  handlePrintAppointment,
  handleShowJson,
} from "../../utils/constant/constant";
import { deleteAppointment } from "../../connections/deleteAppointment";
import { toast } from "react-toastify";

const AppoinmentList = () => {
  const [AppId, setAppId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const {
    user: { user },
  } = useSelector((store) => store);
  // const navigate = useNavigate();

  const {
    data: getAllAppointmentListData,
    isLoading: getAppointmentLoader,
    refetch: refetchAppointmentList,
  } = useQuery({
    queryKey: ["getAllAppointmentList", page, pageSize],
    queryFn: () => getAllAppointmentList(user?.token, null, page, pageSize),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Patient UID",
      dataIndex: "uid",
    },
    {
      title: "Patient",
      dataIndex: "patient",
      render: (_, record) => (
        <Link
          to={`/admin/patient/${record?.patient?.id}`}
          style={{ textDecoration: "underline" }}
        >
          {record?.patient?.full_name}
        </Link>
      ),
    },
    {
      title: "Visit Type",
      dataIndex: "visit_type",
    },
    {
      title: "Visit Date",
      dataIndex: "visit_date",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Appointment Slot",
      dataIndex: "appointment_slot",
      render: (_, record) => <>{record?.appointment_slot?.code || "waiting"}</>,
    },
    {
      title: "Consultant",
      dataIndex: "consultant",
      render: (_, record) => (
        <Link
          to={`/admin/doctor/${record?.consultant?.id}`}
          style={{ textDecoration: "underline" }}
        >
          {record?.consultant?.first_name} {record?.consultant?.last_name}
        </Link>
      ),
    },
    {
      title: "Doctor",
      dataIndex: "doctor",
      render: (_, record) => (
        <Link
          to={`/admin/doctor/${record?.doctor?.id}`}
          style={{ textDecoration: "underline" }}
        >
          {record?.doctor?.first_name} {record?.doctor?.last_name}
        </Link>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: (date) => (
        <>
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "FIELD8",
    //   render: (_, record) => (
    //     <>
    //       <div className="text-end">
    //         <div className="dropdown dropdown-action">
    //           <Link
    //             to="#"
    //             className="action-icon dropdown-toggle"
    //             data-bs-toggle="dropdown"
    //             aria-expanded="false"
    //           >
    //             <i className="fas fa-ellipsis-v" />
    //           </Link>
    //           <div className="dropdown-menu dropdown-menu-end">
    //             <Link
    //               className="dropdown-item"
    //               to={`/admin/appointment/${record?.id}`}
    //             >
    //               <i className="fa-regular fa-eye m-r-5" />
    //               View
    //             </Link>
    //             <Link
    //               className="dropdown-item"
    //               // to="/editappoinments"
    //               style={{ cursor: "not-allowed" }}
    //             >
    //               <i className="far fa-edit me-2" />
    //               Edit
    //             </Link>
    //             <Link
    //               className="dropdown-item"
    //               to="#"
    //               data-bs-toggle="modal"
    //               data-bs-target="#delete_patient"
    //               onClick={() => {
    //                 setAppId(record?.id);
    //               }}
    //             >
    //               <i className="fa fa-trash-alt m-r-5"></i> Delete
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="view">
                <Link to={`/admin/appointment/${record?.id}`}>
                  <i className="fa-regular fa-eye m-r-5" /> View
                </Link>
              </Menu.Item>
              <Menu.Item key="edit" style={{ cursor: "not-allowed" }}>
                <i className="far fa-edit me-2" /> Edit
              </Menu.Item>
              <Menu.Item
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete_patient"
                key="delete"
                onClick={() => {
                  setAppId(record?.id);
                }}
              >
                <i className="fa fa-trash-alt m-r-5" /> Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <Link
            to="#"
            style={{ marginRight: 5 }}
            className="action-icon"
            // data-bs-toggle="dropdown"
            // aria-expanded="false"
          >
            <i className="fas fa-ellipsis-v" />
          </Link>
        </Dropdown>
      ),
    },
  ];
  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
  };
  const handleDeleteAppointment = async (e) => {
    e.preventDefault();
    try {
      const data = await deleteAppointment(user?.token, AppId);
      if (data.status.status === "SUCCESS") {
        toast.success("Appointment Deleted Successfully");
        refetchAppointmentList();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="appoinment-list"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Appointment</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Appointment List</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Appointment List</h3>
                            <div className="doctor-search-blk">
                              <div className="add-group">
                                <Link
                                  style={{ cursor: "not-allowed" }}
                                  // to="/addappoinments"
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link>
                                <Link
                                  onClick={refetchAppointmentList}
                                  className="btn btn-primary doctor-refresh ms-2"
                                >
                                  <img src={refreshicon} alt="#" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end float-end ms-auto download-grp">
                          <img
                            className=" me-2"
                            onClick={() =>
                              handlePrintAppointment(
                                getAllAppointmentListData?.payload?.appointments
                              )
                            }
                            src={pdficon}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />

                          <img
                            onClick={() =>
                              generateCsv(
                                getAllAppointmentListData?.payload?.appointments
                              )
                            }
                            className=" me-2"
                            src={pdficon3}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />

                          <img
                            onClick={() =>
                              handleShowJson(
                                getAllAppointmentListData?.payload?.appointments
                              )
                            }
                            width={"25px"}
                            height={"26px"}
                            src={pdficon5}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        loading={getAppointmentLoader}
                        pagination={{
                          current: page,
                          pageSize: pageSize,
                          total: getAllAppointmentListData?.meta?.total_count,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onChange: handlePageChange,
                        }}
                        columns={columns}
                        dataSource={
                          getAllAppointmentListData?.payload?.appointments
                        }
                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                        scroll={{ x: "max-content" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="notification-box">
            <div className="msg-sidebar notifications msg-noti">
              <div className="topnav-dropdown-header">
                <span>Messages</span>
              </div>
              <div className="drop-scroll msg-list-scroll" id="msg_list">
                <ul className="list-box">
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Richard Miles </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item new-message">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">John Doe</span>
                          <span className="message-time">1 Aug</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Tarah Shropshire{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Mike Litorus</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Catherine Manseau{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">D</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Domenic Houston{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">B</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Buster Wigton{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Rolland Webber{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author"> Claire Mapes </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Melita Faucher</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Jeffery Lalor</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">L</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Loren Gatlin</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            Tarah Shropshire
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <Link to="#">See all messages</Link>
              </div>
            </div>
          </div>
        </div>
        <div
          id="delete_patient"
          className="modal fade delete-modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={imagesend} alt="#" width={50} height={46} />
                <h3>Are you sure want to delete this ?</h3>
                <div className="m-t-20">
                  {" "}
                  <Link
                    to="#"
                    className="btn btn-white me-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </Link>
                  <button
                    onClick={handleDeleteAppointment}
                    type="submit"
                    data-bs-dismiss="modal"
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button type="submit" className="btn btn-danger">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      <></>
    </>
  );
};

export default AppoinmentList;
