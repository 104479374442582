import React, { useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getActivity } from "../../connections/getActivity";
import { getCurrentMonthStartEndIST } from "../../utils/constant/constant";
import dayjs from "dayjs";
import Select from "react-select";
import { TextField } from "@mui/material";
import { searchnormal } from "../imagepath";
import "./Activity.css";
const ActivityList = () => {
  const { startDateOfMonth, endDateOfMonth } = getCurrentMonthStartEndIST();
  const [startDate, setStartDate] = useState(startDateOfMonth);
  const [endDate, setEndDate] = useState(endDateOfMonth);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [itemId, setItemId] = useState("");
  const [entityType, setEntityType] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [eventOption] = useState([
    {
      value: "create",
      label: "Create",
    },
    {
      value: "destroy",
      label: "Destroy",
    },
    {
      value: "update",
      label: "Update",
    },
  ]);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const {
    user: { user },
  } = useSelector((store) => store);

  const {
    data: getActivityData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["getActivity", page, pageSize],
    queryFn: () =>
      getActivity(
        user?.token,
        selectedEvent,
        page,
        pageSize,
        startDate,
        endDate,
        itemId,
        entityType
      ),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const formatValue = (value, isKey = false) => {
    if (value === null) return "NIL";

    if (typeof value === "string") {
      return value || "NIL";
    }
    if (Array.isArray(value)) {
      if (value.length === 0) return "NIL";

      if (typeof value[0] === "object") {
        const displayedItems = showMore ? value : value?.slice(0, 1);
        return (
          <div className="ps-3">
            {displayedItems?.map((obj, index) => (
              <div key={index} className="mb-2">
                {Object.entries(obj).map(([key, val]) => (
                  <div
                    key={key}
                    className="d-flex align-items-center justify-content-start mb-2"
                  >
                    {isKey && <strong className="me-2 text-dark">{key}</strong>}
                    <span className="me-2">
                      {!isKey ? (
                        <i className="fa-solid fa-arrow-right-long"></i>
                      ) : (
                        <i className="fa-solid fa-arrow-right-long"></i>
                      )}
                    </span>
                    <span
                      className="text-break text-truncate"
                      style={
                        {
                          // maxWidth: "350px",
                          // overflow: "hidden",
                        }
                      }
                    >
                      {val || "NIL"}
                    </span>
                  </div>
                ))}
              </div>
            ))}
            {value?.length > 1 && (
              <button
                onClick={() => setShowMore(!showMore)}
                className="btn btn-link p-0 mt-2"
              >
                {showMore ? "Show Less" : "Show More"}
              </button>
            )}
          </div>
        );
      } else {
        return value.join(", ");
      }
    }

    return String(value);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "id",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Item",
      dataIndex: "item_id",
    },
    {
      title: "Event",
      dataIndex: "event",
    },
    {
      title: "Type",
      dataIndex: "item_type",
    },
    {
      title: "Modified at",
      dataIndex: "created_at",
      render: (date) => (
        <>
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    {
      title: "User",
      dataIndex: "whodunnit",
    },
  ];
  const entityOptions = [
    {
      value: "appointment",
      label: "Appointment",
    },
    {
      value: "prescription",
      label: "Prescription",
    },
    {
      value: "patient",
      label: "Patient",
    },
    {
      value: "consumable",
      label: "Consumable",
    },
    {
      value: "invoice_item",
      label: "Invoice Item",
    },
    {
      value: "invoice",
      label: "Invoice",
    },
    {
      value: "medicine",
      label: "Medicine",
    },
    {
      value: "medical_test_report",
      label: "Medical Test Report",
    },
    {
      value: "medical_test",
      label: "Medical Test",
    },
  ];

  const handleExpand = (expanded, record) => {
    if (expanded) {
      // Add row to expandedRowKeys if expanded
      setExpandedRowKeys((prev) => [...prev, record.id]);
    } else {
      // Remove row from expandedRowKeys if collapsed
      setExpandedRowKeys((prev) => prev.filter((key) => key !== record.id));
    }
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };
  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
  };
  const handleSubmitFilter = () => {
    refetch(
      user?.token,
      selectedEvent,
      page,
      pageSize,
      startDate,
      endDate,
      itemId,
      entityType
    );
  };
  const handleInputChange = (e) => {
    setItemId(e.target.value);
  };

  return (
    <>
      <>
        <Header />
        <Sidebar activeClassName="activity-list" />
      </>
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Activity</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Activity List</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Filter</h3>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-3 col-lg-2 mb-3">
                              <Select
                                value={
                                  eventOption.find(
                                    (option) => option.value === selectedEvent
                                  ) || null
                                }
                                onChange={(e) => setSelectedEvent(e.value)}
                                placeholder="event"
                                options={eventOption}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? "#2e37a4"
                                      : "rgba(46, 55, 164, 0.1)",
                                    boxShadow: state.isFocused
                                      ? "0 0 0 1px #2e37a4"
                                      : "none",
                                    "&:hover": {
                                      borderColor: "rgba(46, 55, 164, 0.1)",
                                    },
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen
                                      ? "rotate(-180deg)"
                                      : "rotate(0)",
                                    transition: "250ms",
                                    width: "35px",
                                    height: "35px",
                                  }),
                                }}
                                id="search-commodity"
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                              />
                            </div>
                            <div className="col-12 col-md-3 col-lg-2 mb-3">
                              <div className="doctor-search-blk">
                                <div className="top-nav-search table-search-blk">
                                  <form style={{ width: "100%" }}>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search here"
                                      value={itemId}
                                      onChange={handleInputChange}
                                    />
                                    <Link className="btn">
                                      <img src={searchnormal} alt="#" />
                                    </Link>
                                  </form>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-3 col-lg-2 mb-3">
                              <Select
                                value={
                                  entityOptions.find(
                                    (option) => option.value === entityType
                                  ) || null
                                }
                                onChange={(e) => setEntityType(e.value)}
                                placeholder="entity type"
                                options={entityOptions}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? "#2e37a4"
                                      : "rgba(46, 55, 164, 0.1)",
                                    boxShadow: state.isFocused
                                      ? "0 0 0 1px #2e37a4"
                                      : "none",
                                    "&:hover": {
                                      borderColor: "rgba(46, 55, 164, 0.1)",
                                    },
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    // minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen
                                      ? "rotate(-180deg)"
                                      : "rotate(0)",
                                    transition: "250ms",
                                    width: "35px",
                                    height: "35px",
                                  }),
                                }}
                                id="search-commodity"
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                              />
                            </div>
                            <div className="col-12 col-md-3 col-lg-2 mb-3">
                              <TextField
                                label="Start Date"
                                type="date"
                                value={startDate}
                                onChange={handleStartDateChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                                size="small"
                                fullWidth
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    border: "1px solid rgba(46, 55, 164, 0.1)",
                                    outline: "none",
                                  },
                                }}
                              />
                            </div>
                            <div className="col-12 col-md-3 col-lg-2 mb-3">
                              <TextField
                                label="End Date"
                                type="date"
                                value={endDate}
                                onChange={handleEndDateChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                                size="small"
                                fullWidth
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    border: "1px solid rgba(46, 55, 164, 0.1)",
                                    outline: "none",
                                  },
                                }}
                              />
                            </div>
                            <div className="col-12 col-md-3 col-lg-2 mb-3 d-flex justify-content-center">
                              <button
                                onClick={handleSubmitFilter}
                                className="btn btn-primary w-100"
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Activity List</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        loading={isLoading}
                        pagination={{
                          current: page,
                          position: ["topRight", "bottomRight"],
                          pageSize: pageSize,
                          total: getActivityData?.meta?.total_count,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onChange: handlePageChange,
                        }}
                        columns={columns}
                        dataSource={
                          getActivityData?.payload["paper_trail/versions"]
                        }
                        // scroll={{ x: "max-content" }}
                        rowKey={(record) => record.id}
                        expandable={{
                          expandedRowRender: (record) => (
                            <div
                              className="border rounded"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap ",
                                maxWidth: "90% ",
                                width: "90%",
                                // backgroundColor: "red",
                              }}
                            >
                              {Object.entries(record.object_changes).map(
                                ([field, values]) => (
                                  <div
                                    key={field}
                                    style={
                                      {
                                        // maxWidth: "100% ",
                                        // width: "100%",
                                        // backgroundColor: "blue",
                                      }
                                    }
                                    className="d-flex align-items-start flex-wrap p-3 mb-3"
                                  >
                                    <span className="fw-bold me-2 text-break">
                                      {field}
                                    </span>
                                    <span
                                      style={{ color: "blue" }}
                                      className="me-2 text-break d-flex align-items-center"
                                    >
                                      {formatValue(values[0], true)}
                                      {(typeof values[0] !== "object" ||
                                        !Array.isArray(values[0])) &&
                                        (typeof values[1] !== "object" ||
                                          !Array.isArray(values[1])) && (
                                          <i className="fa-solid fa-arrow-right-long mx-2"></i>
                                        )}
                                    </span>
                                    <span className="text-success text-break">
                                      {formatValue(values[1], false)}
                                    </span>
                                  </div>
                                )
                              )}
                            </div>
                          ),
                          rowExpandable: (record) => !!record.object_changes,
                          expandedRowKeys: expandedRowKeys,
                          onExpand: handleExpand,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ActivityList;
