/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
  pdficon5,
} from "../imagepath";
import { onShowSizeChange, itemRender } from "../Pagination";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getPurchaseOrderList } from "../../connections/getPurchaseOrderList";
import dayjs from "dayjs";
import { Skeleton } from "@mui/material";
import { deletePurchaseOrderItem } from "../../connections/deletePurchaseOrderItem";

import {
  generateCSV,
  handleDownloadPdf,
  handlePrintPurchaseOrder,
  handleShowJson,
} from "../../utils/constant/constant";
import { toast } from "react-toastify";
// import { getAllMedicine } from "../../connections/getAllMedicine";
// import { getAllInventories } from "../../connections/getAllInventories";

const PharmacyPurchaseOrder = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteId, setDeleteId] = useState(null);

  const navigate = useNavigate();
  const {
    user: { user },
  } = useSelector((store) => store);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const {
    data: getPurchaseOrderListData,
    isLoading: PurchaseOrderListLoader,
    refetch: ListPurchaseRefetch,
  } = useQuery({
    queryKey: ["getPurchaseOrderList", page, pageSize],
    queryFn: () => getPurchaseOrderList(user?.token, null, page, pageSize),
    // enabled:
    // staleTime: Infinity, // Keep the data fresh indefinitely
    // cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const handleDelete = async (id) => {
    try {
      const deleteResponse = await deletePurchaseOrderItem(id, user?.token);
      if (deleteResponse) {
        toast.success("A Purchase Order has been deleted successfully.");
        ListPurchaseRefetch();
      } else {
        toast.error("Error deleting Purchase Order");
      }
    } catch (error) {
      console.error("Failed to delete Purchase Order:", error);
      toast.error("An error occurred while deleting the Purchase Order");
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      // sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,/
    },
    {
      title: "Supplier",
      dataIndex: ["supplier", "name"],
      render: (text, record) => (
        <>
          <h2 style={{ textDecoration: "underline" }} className="profile-image">
            <Link to={`/admin/suppliers/${record?.supplier?.id}`}>{text}</Link>
          </h2>
        </>
      ),
    },

    {
      title: "Created At",
      dataIndex: "created_at",
      render: (date) => (
        <>
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      render: (date) => (
        <>
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    {
      title: "Grand Total",
      dataIndex: "grand_total",
      //   sorter: (a, b) => a.Email.length - b.Email.length,
      //   render: (text, record) => (
      //     <>
      //       <Link to="#">{record.Mobile}</Link>
      //     </>
      //   ),
    },
    {
      title: "Bill Type",
      dataIndex: "bill_type",
      //   sorter: (a, b) => a.Date.length - b.Date.length,
    },
    {
      title: "Department",
      dataIndex: ["department", "name"],
      //   sorter: (a, b) => a.Time.length - b.Time.length,
      render: (text, record) =>
        user?.role === "admin" ? (
          <Link to={`/department_details/${record?.department?.id}`}>
            {text}
          </Link>
        ) : (
          <>{text}</>
        ),
    },
    {
      title: "Bill Number",
      dataIndex: "bill_number",
      //   sorter: (a, b) => a.Time.length - b.Time.length,
    },
    {
      title: "Paid",
      dataIndex: "paid",
      // sorter: (a, b) => a.Time.length - b.Time.length,
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (text, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  className="dropdown-item"
                  to={`/admin/purchase_orders/${record.id}`}
                >
                  <i className="fa-regular fa-eye m-r-5" />
                  View
                </Link>
                <button
                  className="dropdown-item"
                  onClick={() =>
                    navigate(
                      user?.role === "admin"
                        ? "/admin/edit_purchase_orders"
                        : "/admin/update_purchase_orders",
                      { state: record }
                    )
                  }
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </button>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={() => setDeleteId(record.id)}
                  data-bs-toggle="modal"
                  data-bs-target="#delete_patient"
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];
  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
    // ListPurchaseRefetch();
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Header />
      <Sidebar
        // id="menu-item4"
        // id1="menu-items4"
        activeClassName="purchase-order-list"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Pharmacy</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Purchase Order</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Purchase Order</h3>
                            <div className="doctor-search-blk">
                              {/* <div className="top-nav-search table-search-blk">
                                <form>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search here"
                                  />
                                  <Link className="btn">
                                    <img src={searchnormal} alt="#" />
                                  </Link>
                                </form>
                              </div> */}
                              <div className="add-group">
                                <Link
                                  to={
                                    user?.role === "admin"
                                      ? "/admin/purchase_orders/new"
                                      : "/admin/add_purchase_orders"
                                  }
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link>
                                <Link className="btn btn-primary add-pluss ms-2">
                                  <img
                                    onClick={ListPurchaseRefetch}
                                    src={refreshicon}
                                    alt="#"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end float-end ms-auto download-grp">
                          {/* <Link id="download-pdf" to="#" className=" me-2"> */}

                          <img
                            className=" me-2"
                            onClick={() =>
                              handlePrintPurchaseOrder(
                                getPurchaseOrderListData?.payload
                                  ?.purchase_orders
                              )
                            }
                            src={pdficon}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                          {/* <Link
                           
                            id="download-csv"
                            className=" me-2"
                          > */}
                          <img
                            onClick={() =>
                              generateCSV(
                                getPurchaseOrderListData?.payload
                                  ?.purchase_orders
                              )
                            }
                            className=" me-2"
                            src={pdficon3}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                          {/* <Link id="show-json" to="#"> */}
                          <img
                            onClick={() =>
                              handleShowJson(
                                getPurchaseOrderListData?.payload
                                  ?.purchase_orders
                              )
                            }
                            width={"25px"}
                            height={"26px"}
                            src={pdficon5}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        loading={PurchaseOrderListLoader}
                        id="table-to-print"
                        pagination={{
                          current: page,
                          pageSize: pageSize,
                          total:
                            getPurchaseOrderListData?.payload?.meta
                              ?.total_count,

                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onChange: handlePageChange,
                        }}
                        columns={columns}
                        dataSource={
                          getPurchaseOrderListData?.payload?.purchase_orders
                        }
                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                        scroll={{ x: "max-content" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="notification-box">
            <div className="msg-sidebar notifications msg-noti">
              <div className="topnav-dropdown-header">
                <span>Messages</span>
              </div>
              <div className="drop-scroll msg-list-scroll" id="msg_list">
                <ul className="list-box">
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Richard Miles </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item new-message">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">John Doe</span>
                          <span className="message-time">1 Aug</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Tarah Shropshire{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Mike Litorus</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Catherine Manseau{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">D</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Domenic Houston{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">B</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Buster Wigton{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Rolland Webber{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author"> Claire Mapes </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Melita Faucher</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Jeffery Lalor</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">L</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Loren Gatlin</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            Tarah Shropshire
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <Link to="#">See all messages</Link>
              </div>
            </div>
          </div>
        </div>
        <div
          id="delete_patient"
          className="modal fade delete-modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={imagesend} alt="#" width={50} height={46} />
                <h3>Are you sure want to delete this ?</h3>
                <div className="m-t-20">
                  {" "}
                  <Link
                    to="#"
                    className="btn btn-white me-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </Link>
                  <button
                    type="submit"
                    onClick={() => handleDelete(deleteId)}
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button type="submit" className="btn btn-danger">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default PharmacyPurchaseOrder;
